import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { PhoneInput } from 'react-international-phone';

import 'react-international-phone/style.css';

interface IProps {
	name: string;
	title: string;
	className?: string;
	errorMsg: string;
	control: any;
	placeHolder?: string;
	disabled?: boolean;
}

const PhoneNumberInput: FC<IProps> = ({ name, title, className, control, errorMsg, placeHolder, disabled }) => {
	return (
		<div className={`${className ?? ''} relative w-full`}>
			<label htmlFor={name} className='input-label rounded-r-lg'>
				{title}
			</label>
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, value } }) => (
					<PhoneInput
						disabled={disabled}
						defaultCountry='jp'
						value={value ?? ''}
						className='custom-phone-input'
						onChange={onChange}
						placeholder={placeHolder ?? 'Add phone number'}
					/>
				)}
			/>
			{errorMsg && <p className='text-left text-xs text-red-600 first-letter:uppercase'>{errorMsg}</p>}
		</div>
	);
};

export default PhoneNumberInput;
