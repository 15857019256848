import { useMemo } from 'react';
import useModulePermission from './userModulePermission';

const useComponentPermission = () => {
	const { hasScoutingRecruiting, hasContractManagement, hasAthleteManagement } = useModulePermission();

	const hasComponentPermission = useMemo(() => {
		return {
			isAgent: hasScoutingRecruiting || hasContractManagement,
			isGuardian: hasAthleteManagement,
			isContract: hasContractManagement,
			isAthleteVideo: hasScoutingRecruiting,
			isPerformanceMetrics: hasScoutingRecruiting,
			isMarketability: hasContractManagement,
			isEdit: hasAthleteManagement
		};
	}, [hasScoutingRecruiting, hasAthleteManagement, hasContractManagement]);

	return hasComponentPermission;
};

export default useComponentPermission;
