import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { isEmpty } from 'lodash';

import TooltipContainer from 'shared/components/tooltip';
import RadioButton from 'shared/form/radioButton';
import TooltipSlider from 'shared/form/slider';
import { InfoIcon, PlusIcon } from 'shared/icon';
import useModal from 'shared/hooks/useModal';
import AddNoteModal from 'shared/components/modal/addNoteModal';
import { IKpiDataNew, IKpiValueNew, IPlayerNote } from 'features/addPlayer/interface';
import { NoteModal } from 'features/playerProfile/constant';

import PlayerNote from '../playerNote';
import useComponentPermission from 'shared/components/permissions/userComponentPermission';

interface IProps {
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
	errors: FieldErrors<any>;
	kpi?: any;
	kpiData: IKpiDataNew[];
	isFormSubmit: boolean;
	title?: string;
	isSize?: boolean;
}

const PerformanceMatrix: React.FC<IProps> = ({
	register,
	setValue,
	kpiData,
	kpi,
	isFormSubmit,
	errors,
	title,
	isSize
}) => {
	const [kpiValues, setKpiValues] = useState<{ [key: number]: number }>({});
	const [selectedValue, setSelectedValue] = useState<{ [key: string]: string }>({
		marketability_current: '',
		marketability_potential: ''
	});
	const [noteTitle, setNoteTitle] = useState('');
	const [notes, setNotes] = useState<{ note: string; tournament: string; kpi_group_name: string }[]>([]);
	const [notesByTitle, setNotesByTitle] = useState<{ [key: string]: any }>({} as any);

	const { isMarketability, isPerformanceMetrics } = useComponentPermission();

	const { isOpen, type, closeModal, openModal } = useModal<NoteModal>();

	useEffect(() => {
		if (isFormSubmit) {
			setKpiValues({});
			setNotesByTitle({});
			setNoteTitle('');
			setSelectedValue({
				marketability_current: '',
				marketability_potential: ''
			});
		}
	}, [isFormSubmit]);

	useEffect(() => {
		if (kpi?.kpis && kpi?.kpis.length > 0) {
			// Format KPI values
			const formattedKpiData = kpi.kpis.reduce(
				(acc: { [key: number]: number }, { player_kpi_id, value }: any) => {
					acc[player_kpi_id] = value;
					return acc;
				},
				{}
			);

			setKpiValues(formattedKpiData);
		}
		setSelectedValue({
			marketability_current: kpi.marketability_current,
			marketability_potential: kpi.marketability_potential
		});
		// Handle KPI notes and grouping if kpi_groups exist
		if (kpi?.kpi_groups && kpi?.kpi_groups?.length > 0) {
			const newNotes = kpi.kpi_groups.map(({ note, tournament, kpi_group }: any) => ({
				note,
				tournament,
				kpi_group_name: kpi_group
			}));

			setNotes(newNotes);

			// Group notes by kpi_group_name
			const notesGroupedByTitle = newNotes.reduce((acc: any, note: IPlayerNote) => {
				(acc[note.kpi_group_name] = acc[note.kpi_group_name] || []).push(note);
				return acc;
			}, {});

			setNotesByTitle(notesGroupedByTitle);
		}
	}, [kpi]);

	// Calculate Group Average Score
	const calculateGroupAverage = (kpis: IKpiValueNew[] = [], selectedLength: number) => {
		if (!Array.isArray(kpis) || selectedLength === 0) {
			return '0.00';
		}

		const total = kpis.reduce((acc, kpi) => {
			const value = kpiValues[kpi.id] || 0; // Ensure it's a valid number
			return acc + value;
		}, 0);

		return (total / selectedLength).toFixed(2);
	};

	const calculateOverallAverage = () => {
		const allKpis = kpiData.flatMap((group: IKpiDataNew) => group.kpisData);
		const selectedKpis = allKpis.filter(
			(kpi: IKpiValueNew) => kpiValues[kpi.id] !== undefined && kpiValues[kpi.id] !== 0
		);

		const total = selectedKpis.reduce((acc, kpi) => acc + (kpiValues[kpi.id] || 0), 0); // Sum of selected KPI values

		if (selectedKpis.length === 0) {
			return '0.00';
		}

		return (total / selectedKpis.length).toFixed(2);
	};

	// Format the response for submission
	const formattedResponse = Object.keys(kpiValues).map((kpiId: string) => ({
		kpi_id: Number(kpiId),
		value: kpiValues[+kpiId]
	}));

	useEffect(() => {
		setValue('kpis', formattedResponse as any);
	}, [formattedResponse, setValue]);

	const handleChangeSlider = (value: number, kpiId: number) => {
		setKpiValues((prevState) => ({
			...prevState,
			[kpiId]: value // Update the value for the specific KPI
		}));
	};

	// Handle Radio Change
	const handleRadioChange = useCallback((value: string, key: string) => {
		setSelectedValue((prevState) => ({
			...prevState,
			[key]: value
		}));
	}, []);

	const handleUpdateNote = useCallback(
		(data: { note: string; tournament: string }, noteTitle: string, noteData: any) => {
			setNotes((prevNotes) => {
				const updateNotes = [...prevNotes];
				const existingNoteIndex = updateNotes?.findIndex(
					(note: { note: string; tournament: string }) => note.note === noteData.note
				);

				const updatedNote = {
					...updateNotes[existingNoteIndex],
					note: data.note,
					tournament: data.tournament,
					kpi_group_name: updateNotes[existingNoteIndex]?.kpi_group_name || noteTitle
				};
				updateNotes[existingNoteIndex] = updatedNote;

				return updateNotes;
			});

			setNotesByTitle((prevNotes) => {
				const updatedNotes = { ...prevNotes };
				if (!isEmpty(updatedNotes) && updatedNotes[noteTitle]) {
					const existingNoteIndex = updatedNotes[noteTitle]?.findIndex(
						(data: { note: string; tournament: string }) => data.note === noteData.note
					);
					updatedNotes[noteTitle][existingNoteIndex] = data;
				}
				return updatedNotes;
			});
		},
		[]
	);

	const handleNoteSubmit = (data: { note: string; tournament: string }, noteTitle: string) => {
		setNotes((prevNotes) => {
			const newNote = {
				kpi_group_name: noteTitle,
				note: data.note,
				tournament: data.tournament
			};

			return [...prevNotes, newNote];
		});
		setNotesByTitle((prevNotes) => {
			const updatedNotes = { ...prevNotes };
			const newNote = {
				note: data.note,
				tournament: data.tournament
			};

			if (updatedNotes[noteTitle]) {
				updatedNotes[noteTitle] = [...updatedNotes[noteTitle], newNote];
			} else {
				updatedNotes[noteTitle] = [newNote];
			}

			return updatedNotes;
		});
	};

	useEffect(() => {
		setValue('kpi_groups', notes as any);
	}, [notes, setValue]);

	const handleDeleteNote = (noteTitle: string, data: { note: string; tournament: string }) => {
		setNotes((prevNotes) => {
			const updateNotes = [...prevNotes];
			const existingNoteIndex = updateNotes?.findIndex(
				(note: { note: string; tournament: string }) => note.note === data.note
			);
			updateNotes.splice(existingNoteIndex, 1);
			return updateNotes;
		});

		// Update the `notesByTitle` state
		setNotesByTitle((prevNotes) => {
			const updatedNotes = { ...prevNotes };

			if (updatedNotes[noteTitle]) {
				const existingNoteIndex = updatedNotes[noteTitle]?.findIndex(
					(note: { note: string; tournament: string }) => note.note === data.note
				);
				updatedNotes[noteTitle].splice(existingNoteIndex, 1);
			}
			return updatedNotes;
		});
	};

	const selectLength = (data: IKpiValueNew[]) => {
		return data.filter((kpi: IKpiValueNew) => kpiValues[kpi.id]).length;
	};

	return (
		<>
			{isPerformanceMetrics && (
				<div>
					<div className='mb-4 mt-5 flex items-center justify-between'>
						<p className='title-text mb-0'>{title}</p>
						<div className='flex items-center'>
							<p className='text-textBlack text-lg-responsive font-semibold mr-2 text-base-responsive'>
								Overall Score
							</p>
							<div className='bg-[#1D9533] rounded-lg  text-white flex items-center justify-center px-2.5 py-1.5 font-bold'>
								{calculateOverallAverage()}
							</div>
						</div>
					</div>

					{!isEmpty(kpiData) &&
						kpiData.map((data: IKpiDataNew) => {
							return (
								<div className='bg-lightBlue p-8 mb-4' key={data.title}>
									<div className='flex justify-between items-center border-b pb-3 border-themeGray'>
										<div className='flex'>
											<p className='text-textBlack font-semibold mr-5 text-base-responsive'>
												{data.title}
											</p>
											<p className='text-primary font-semibold text-base-responsive'>
												(Average Score:{' '}
												{calculateGroupAverage(data.kpisData, selectLength(data.kpisData))})
											</p>
										</div>
										<div className='flex items-center'>
											<PlusIcon className='size-3 stroke-primary mr-2' />
											<p
												className='text-primary font-semibold cursor-pointer text-base-responsive'
												onClick={() => {
													openModal(NoteModal.addNote);
													setNoteTitle(data.title);
												}}
											>
												Add Note About the {data.title}
											</p>
										</div>
									</div>
									<div
										className={`flex flex-wrap mt-3 gap-3 ${
											!isEmpty(notesByTitle[noteTitle]) ? 'border-b pb-3 border-themeGray' : ''
										} `}
									>
										{data.kpisData.map((items: IKpiValueNew) => {
											return (
												<TooltipSlider
													key={items.id}
													value={kpiValues[items.id] || 0}
													title={items.name}
													className={`max-w-[280px] p-4 rounded-lg ${
														kpiValues[items.id] ? 'bg-[#F0F5FC]' : 'bg-white'
													}`}
													onChange={(value) => handleChangeSlider(value, items.id)}
													isSize={isSize as boolean}
												/>
											);
										})}
									</div>
									{!isEmpty(notesByTitle) &&
										Object.entries(notesByTitle).map(([noteTitle, notesArray]) => {
											return (
												<Fragment key={noteTitle}>
													{noteTitle === data.title && (
														<PlayerNote
															notesData={notesArray}
															tabName={noteTitle}
															handleDelete={(data) => handleDeleteNote(noteTitle, data)}
															handleUpdateNote={(data, note) =>
																handleUpdateNote(data, noteTitle, note)
															}
														/>
													)}
												</Fragment>
											);
										})}
								</div>
							);
						})}
				</div>
			)}

			{isMarketability && (
				<div>
					<div className='bg-lightBlue p-8 mb-4 mt-2'>
						<div className='flex justify-between items-center border-b pb-3 border-themeGray'>
							<label className='text-textBlack mb-[6px] flex text-sm font-bold mr-5'>
								Marketability (Current)
								{/*<span className='text-xs'>*</span>*/}
							</label>
						</div>
						<div className='grid grid-cols-6 gap-4 mt-3'>
							{RADIO_KPIS.map((data) => {
								return (
									<div
										className={`flex items-start ${
											isSize ? 'gap-1.5' : 'gap-4'
										} py-4 px-3 rounded-lg ${
											selectedValue?.['marketability_current'] ===
											`${data.value} ${data.globalValue}`
												? 'bg-[#F0F5FC]'
												: 'bg-white'
										}`}
										key={data.name}
									>
										<RadioButton
											label={data.value}
											name='marketability_current'
											register={register}
											value={`${data.value} ${data.globalValue}`}
											globalValue={data.globalValue}
											onChange={(value) => handleRadioChange(value, 'marketability_current')}
											isSize={isSize as boolean}
										/>
										<div className='flex items-center justify-center'>
											<TooltipContainer
												textClassName='w-[300px]'
												content={data?.currantInfo}
												style={'light'}
												color={'light'}
											>
												<InfoIcon
													className={`stroke-[#667085] cursor-pointer  `}
													height={`${isSize ? '14' : '22'}`}
												/>
											</TooltipContainer>
										</div>
									</div>
								);
							})}
							{errors['marketability_current'] && (
								<p className='text-left text-xs mt-1 text-red-600 first-letter:uppercase'>
									{errors['marketability_current']?.message as string}
								</p>
							)}
						</div>
					</div>
					<div className='bg-lightBlue p-8 mb-4'>
						<div className='flex justify-between items-center border-b pb-3 border-themeGray'>
							<label className='text-textBlack mb-[6px] flex text-sm font-bold mr-5'>
								Marketability (Potential)
								{/*<span className='text-xs'>*</span>*/}
							</label>
						</div>
						<div className='grid grid-cols-6 gap-4 mt-3'>
							{RADIO_KPIS.map((data) => {
								return (
									<div
										className={`flex items-start ${
											isSize ? 'gap-1.5' : 'gap-4'
										} lg:py-4 lg:px-3 rounded-lg ${
											selectedValue['marketability_potential'] ===
											`${data.value} ${data.globalValue}`
												? 'bg-[#F0F5FC]'
												: 'bg-white'
										}`}
										key={data.name}
									>
										<RadioButton
											label={data.value}
											name='marketability_potential'
											value={`${data.value} ${data.globalValue}`}
											register={register}
											globalValue={data.globalValue}
											onChange={(value) => handleRadioChange(value, 'marketability_potential')}
											isSize={isSize as boolean}
										/>
										<div className='flex items-center justify-center'>
											<TooltipContainer
												textClassName=' w-[300px]'
												content={data?.potentialInfo}
												style={'light'}
												color={'light'}
											>
												<InfoIcon
													className={`stroke-[#667085] cursor-pointer `}
													height={`${isSize ? '14' : '22'}`}
												/>
											</TooltipContainer>
										</div>
									</div>
								);
							})}
							{errors['marketability_potential'] && (
								<p className='text-left mt-1 text-xs text-red-600 first-letter:uppercase'>
									{errors['marketability_potential']?.message as string}
								</p>
							)}
						</div>
					</div>
				</div>
			)}
			{isOpen && type === NoteModal.addNote && (
				<AddNoteModal
					headerTile={noteTitle}
					show={isOpen}
					onClose={() => {
						closeModal();
					}}
					isQuickNote={false}
					initialData={{ note: '', tournament: '' }}
					formSubmit={(data: any) => handleNoteSubmit(data, noteTitle)}
					type={type}
				/>
			)}
		</>
	);
};

export default PerformanceMatrix;

const RADIO_KPIS = [
	{
		name: 'exceptional',
		value: 'Exceptional',
		globalValue: 'Global',
		currantInfo:
			'The Athlete is highly marketable with widespread global appeal and significant influence. They consistently attract international media attention and have a large, engaged following across various countries.',
		potentialInfo:
			'The Athlete has the potential to become highly marketable with widespread global appeal and significant influence. They could consistently attract international media attention and develop a large, engaged following across various countries.'
	},
	{
		name: 'high',
		value: 'High',
		globalValue: 'Continental',
		currantInfo:
			'The Athlete is very marketable within their continent, with strong appeal and influence. They are frequently featured in continental media and have a substantial, engaged following in multiple countries within their continent.',
		potentialInfo:
			'The Athlete has the potential to become very marketable within their continent, with strong appeal and influence. They could frequently be featured in continental media and develop a substantial, engaged following in multiple countries within their continent.'
	},
	{
		name: 'good',
		value: 'Good',
		globalValue: 'Domestic',
		currantInfo:
			'The Athlete is marketable within their own country, with decent appeal and influence. They receive regular national media attention and have a moderately engaged following within their domestic market.',
		potentialInfo:
			'The Athlete has the potential to become marketable within their own country, with decent appeal and influence. They could receive regular national media attention and develop a moderately engaged following within their domestic market.'
	},
	{
		name: 'average',
		value: 'Average',
		globalValue: 'Domestic',
		currantInfo:
			'The Athlete has some marketability within their own country, with limited appeal and influence. They receive occasional national media attention and have a smaller, less engaged following domestically.',
		potentialInfo:
			'The Athlete has some potential marketability within their own country, with limited appeal and influence. They could receive occasional national media attention and develop a smaller, less engaged following domestically.'
	},
	{
		name: 'low',
		value: 'Low',
		globalValue: 'Domestic',
		currantInfo:
			'The Athlete has minimal marketability within their own country, with little appeal and influence. They rarely attract national media attention and have a small, minimally engaged following domestically.',
		potentialInfo:
			'The Athlete has minimal potential marketability within their own country, with little appeal and influence. They might rarely attract national media attention and develop a small, minimally engaged following domestically.'
	},
	{
		name: 'poor',
		value: 'Poor',
		globalValue: 'Domestic',
		currantInfo:
			'The Athlete is not marketable within their own country, with no significant appeal or influence. They do not attract national media attention and have a negligible following domestically.',
		potentialInfo:
			'The Athlete has no significant potential marketability within their own country, with negligible appeal or influence. They are unlikely to attract national media attention or develop a significant following domestically.'
	}
];
