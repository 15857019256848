import { FC } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { IDropdownOptions } from 'shared/interface';

interface IProps {
	name: string;
	selectedArray: string[];
	option: IDropdownOptions[];
	title?: string;
	className?: string;
	setValue: UseFormSetValue<any>;
}

const MultiSelectButtons: FC<IProps> = ({ title, name, option, className, selectedArray = [], setValue }) => {
	const handleClick = (selected: string) => {
		if (selectedArray.includes(selected)) {
			setValue(
				name,
				selectedArray.filter((value: string) => value !== selected)
			);
		} else {
			setValue(name, [...selectedArray, selected]);
		}
	};
	return (
		<div className={`${className ?? ''} w-full`}>
			{title && <label className='input-label'>{title}</label>}
			<div className='flex gap-2'>
				{option.map((item) => (
					<button
						className={`px-1 2xl:px-2 py-1 2xl:py-2 w-full rounded-md border text-base-responsive ${
							selectedArray.includes(item.value)
								? 'border-primary bg-primary text-white'
								: 'border-primary bg-[#0057B70D] text-[#667085]'
						}`}
						key={item.value}
						onClick={() => handleClick(item.value)}
						type='button'
					>
						{item.label}
					</button>
				))}
			</div>
		</div>
	);
};

export default MultiSelectButtons;
