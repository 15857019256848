import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';

import PerformanceMatrix from 'features/addPlayer/component/form/performanceMatrix';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import ButtonPrimary from 'shared/components/buttons/primary';
import ButtonSecondary from 'shared/components/buttons/secondary';
import InformationModal from 'shared/components/modal/informationModal';
import useModal from 'shared/hooks/useModal';
import { cleanObject } from 'shared/util/utility';

import QuickAddVideoUpload from './videoUpload';
import QuickAddAthleteInfo from './athleteInfo';
import QuickAddLevelTable from './levelTable';

import { defaultValues, IQuickAddValues } from '../../interface';
import { PerformanceGradingModal, schema } from '../../constant';
import { useAthleteContext } from 'store/athleteStore';
import { StarIcon } from 'shared/icon';
import QuickAddPerformanceProfile from './performanceProfile';
import Loader from 'shared/components/spinner/spinner';
import Tournament from 'features/addPlayer/component/form/tournament';

interface IProps {
	handleClose: () => void;
	onDirtyChange: (isDirty: boolean) => void;
}

const QuickAddForm: React.FC<IProps> = ({ handleClose, onDirtyChange }) => {
	const { countryOptions, kpiData, countryLoading } = useAthleteContext();
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(1); // Track current step

	const { isOpen, type, closeModal, openModal } = useModal<PerformanceGradingModal>();

	const {
		register,
		control,
		setValue,
		handleSubmit,
		getValues,
		watch,
		trigger,
		reset,
		formState: { errors, isDirty }
	} = useForm<IQuickAddValues>({
		resolver: yupResolver<any>(schema),
		defaultValues: defaultValues,
		mode: 'onSubmit',
		reValidateMode: 'onChange'
	});

	const onSubmit: SubmitHandler<IQuickAddValues> = async (data) => {
		setLoading(true);

		let updatedPayload: any = { ...data.performance_profile };

		// If performance_toggle is true, include only senior level fields
		if (data.performance_profile.performance_toggle) {
			updatedPayload = {
				team_yonex_senior_level: data.performance_profile.team_yonex_senior_level,
				legends_vision: data.performance_profile.legends_vision
			};
		}
		// If performance_toggle is false, include junior future star level fields
		else {
			updatedPayload = {
				team_yonex_future_star_level: data.performance_profile.team_yonex_future_star_level,
				training_age: data.performance_profile.training_age,
				potential_grade: data.performance_profile.potential_grade
			};
		}

		const VideoData = data?.player_videos.map((video: any) => ({
			scout_id: video.scout_id,
			tournament_name: video.tournament_name,
			comment: video.comment,
			video_url: video.video_url,
			creation_date: new Date(video.creation_date).toISOString() // Convert to ISO format if necessary
		}));

		const { country, ...updatedPlayerInformation } = data.player_information;

		const transformedData = cleanObject({
			...data,
			performance_profile: updatedPayload,
			player_videos: VideoData,
			player_information: {
				...updatedPlayerInformation,
				country_id: +data.player_information.country
			}
		});

		try {
			const res = await HttpService.post(API_CONFIG.player, transformedData);
			if (res.data) {
				setLoading(false);
				reset(defaultValues);
				handleClose();
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const handleStepChange = (newStep: number) => {
		if (newStep >= 1 && newStep <= 3) {
			setStep(newStep);
		}
	};

	// Trigger onDirtyChange whenever isDirty changes
	useEffect(() => {
		onDirtyChange(isDirty);
	}, [isDirty, onDirtyChange]);

	return (
		<>
			{countryLoading && <Loader />}
			{!countryLoading && (
				<>
					<div className='bg-lightBlue p-4'>
						<ol className='flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base'>
							<li
								className={`flex text-base-responsive font-semibold items-center md:w-full cursor-pointer sm:after:content-[''] after:w-full after:h-1 after:border-b-2 after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-3 xl:after:mx-8 dark:after:border-gray-700 ${
									Object.keys(errors.player_information || {}).length > 0
										? 'text-red-500' // Red color for errors in Step 1
										: step === 1
										? 'text-primary dark:text-blue-500' // Blue color when Step 1 is active without errors
										: step > 1
										? 'text-primary' // Completed step with no errors
										: 'text-gray-400' // Inactive step
								}`}
								onClick={() => handleStepChange(1)}
							>
								<span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
									Athlete <span className='hidden sm:inline-flex sm:ms-2'>Information</span>
								</span>
							</li>

							<li
								className={`flex text-base-responsive font-semibold md:w-full cursor-pointer items-center after:content-[''] after:w-full after:h-1 after:border-b-2 after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-3 xl:after:mx-8 dark:after:border-gray-700 ${
									Object.keys(errors.performance_profile || {}).length > 0
										? 'text-red-500' // Red color for errors in Step 2
										: step === 2
										? 'text-primary dark:text-blue-500' // Blue color when active without errors
										: step > 2
										? 'text-primary' // Completed step (no errors, step > 2)
										: 'text-gray-400' // Inactive step
								}`}
								onClick={() => handleStepChange(2)}
							>
								<span className='flex items-center '>
									Performance <span className='hidden sm:inline-flex sm:ms-2'>Profile</span>
								</span>
							</li>

							<li
								className={`flex text-base-responsive font-semibold items-center cursor-pointer ${
									step === 3 ? 'text-primary dark:text-blue-500' : 'text-gray-400'
								}`}
								onClick={() => handleStepChange(3)}
							>
								<span className='flex items-center '>
									Performance <span className='hidden sm:inline-flex sm:ms-2'>Metrics</span>
								</span>
							</li>
						</ol>

						<div className='mt-4 border-b-2 border-themeGray-default' />
						<div className='flex items-center mb-6 mt-3'>
							<StarIcon className='mr-3' />
							<p className='text-sm font-normal text-textGray'>- means mandatory fields</p>
						</div>
						<div className='mt-4'>
							<form>
								{step === 1 && (
									<>
										<Tournament
											control={control}
											errors={errors}
											setValue={setValue}
											getValues={getValues}
										/>
										<QuickAddAthleteInfo
											getValues={getValues}
											register={register}
											control={control}
											setValue={setValue}
											errors={errors}
											countryOptions={countryOptions}
										/>
										<div className='mt-5 border-b-2 border-themeGray-default' />
										<QuickAddVideoUpload
											register={register}
											control={control}
											setValue={setValue}
										/>
									</>
								)}
								{step === 2 && (
									<QuickAddPerformanceProfile
										trigger={trigger}
										control={control}
										register={register}
										errors={errors}
										setValue={setValue}
										watch={watch}
										getValues={getValues}
									/>
								)}
							</form>
						</div>
					</div>

					{step === 2 && (
						<div className='bg-lightBlue p-8 mt-5'>
							<p className='text-sm text-textBlack font-semibold'>
								Rating process & grading guidance tip
							</p>
							<p className='text-textGray text-sm font-semibold mt-4'>
								You can check the guidelines prepared for Athlete ratings at any time{' '}
								<span
									onClick={() => openModal(PerformanceGradingModal.Performance_Grading_Guide)}
									className='text-primary cursor-pointer border-b-2 border-primary'
								>
									here
								</span>
							</p>
						</div>
					)}

					{step === 3 && (
						<PerformanceMatrix
							title=''
							register={register}
							setValue={setValue}
							errors={errors}
							kpi={[]}
							isFormSubmit={false}
							isSize={true}
							kpiData={kpiData}
						/>
					)}

					<div className='flex justify-end gap-4 mt-4'>
						<ButtonPrimary
							btnTxt={'Add Athlete'}
							onClick={handleSubmit(onSubmit)}
							disable={!isEmpty(errors)}
							isLoading={loading}
						/>
						<ButtonSecondary
							btnTxt={'Next'}
							onClick={() => handleStepChange(step + 1)}
							disabled={step === 3}
						/>
					</div>
				</>
			)}

			{isOpen && type === PerformanceGradingModal.Performance_Grading_Guide && (
				<InformationModal
					size={'5xl'}
					headerTile={'Performance Grading Guide'}
					show={isOpen}
					onClose={closeModal}
				>
					<div className='bg-lightBlue p-8 mt-5'>
						<div className='flex justify-around'>
							<p className='text-lg text-center font-semibold text-textBlack w-[275px]'>Level Bandings</p>
							<p className='text-lg text-center font-semibold text-textBlack w-[275px]'>
								Grading Guidance
							</p>
							<p className='text-lg text-center font-semibold text-textBlack w-[275px]'>Note</p>
						</div>

						<div>
							<QuickAddLevelTable />
						</div>
					</div>
				</InformationModal>
			)}
		</>
	);
};

export default QuickAddForm;
