import { useCallback } from 'react';
import { AnyObjectSchema } from 'yup';

interface IValidationResult {
	values: Record<string, any>;
	errors: Record<string, any>;
}

export const useYupValidationResolver = (validationSchema: AnyObjectSchema) =>
	useCallback(
		async (data: Record<string, any>): Promise<IValidationResult> => {
			try {
				const values = await validationSchema.validate(data, {
					abortEarly: false
				});

				return {
					values,
					errors: {}
				};
			} catch (error: any) {
				return {
					values: {},
					errors: error.inner.reduce(
						(allErrors: Record<string, any>, currentError: any) => ({
							...allErrors,
							[currentError.path]: {
								type: currentError.type ?? 'validation',
								message: currentError.message
							}
						}),
						{}
					)
				};
			}
		},
		[validationSchema]
	);
