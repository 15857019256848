import { FC, PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren {
	className?: string;
}
const TableBody: FC<IProps> = (props) => {
	return (
		<tbody
			className={`border-x dark:border-gray-700 [&>*:nth-child(even)]:bg-themeGray-bg ${props.className ?? ''}`}
		>
			{props.children}
		</tbody>
	);
};

export default TableBody;
