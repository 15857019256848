import { FC } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { InputErrorIcon } from 'shared/icon';

interface IProp {
	name: string;
	title: string;
	errorMsg: string;
	placeholder: string;
	required?: boolean;
	rows?: number;
	cols?: number;
	register: UseFormRegister<any>;
}

export const Textarea: FC<IProp> = ({ name, title, errorMsg, placeholder, required, register, rows, cols }) => {
	return (
		<div className='min-h-[84px]'>
			<label htmlFor={name} className='input-label'>
				{title} {required && <sub className='bottom-[-7px]'>*</sub>}
			</label>
			<div className='relative'>
				<textarea
					rows={rows}
					cols={cols}
					{...register(name)}
					id={name}
					className={`flex w-full rounded-lg border ${
						errorMsg ? 'border-red-600' : 'border-themeGray'
					} p-2.5 text-sm`}
					placeholder={placeholder}
				/>
				{errorMsg && (
					<div className='pointer-events-none absolute inset-y-0 end-0 flex items-center pe-3'>
						<InputErrorIcon />
					</div>
				)}
			</div>
			{errorMsg && <p className='text-left text-xs text-red-600 first-letter:uppercase'>{errorMsg}</p>}
		</div>
	);
};
