import React from 'react';
import { Avatar } from 'flowbite-react';

import { IShortlistEditor, IShortlists } from '../interface';
import PlaceHolder from 'assets/images/place-holder.png';
import useModal from 'shared/hooks/useModal';
import { ScoutingModal } from '../constant';
import ViewEditorModal from './modal/viewEditorModal';

interface IProps {
	data: IShortlistEditor[];
	onClick?: () => void;
	record: IShortlists;
}

const maxVisible = 2;

const ShortListEditor: React.FC<IProps> = ({ data, record }) => {
	// Render up to 2 profile pictures and then the count for the rest
	const visibleProfiles = data.slice(0, maxVisible);
	const remainingCount = data.length - maxVisible;

	const { isOpen, type, closeModal, openModal } = useModal<ScoutingModal>();

	return (
		<>
			<div className='flex items-center justify-center'>
				{data.length > 0 ? (
					<Avatar.Group
						theme={{
							base: 'flex -ml-2 cursor-pointer'
						}}
						onClick={(event) => {
							event.stopPropagation();
							openModal(ScoutingModal.VIEW_EDITOR);
						}}
					>
						{visibleProfiles.map((data: IShortlistEditor) => (
							<Avatar
								key={data.id}
								img={PlaceHolder}
								size='xs'
								className='relative w-[32px] h-[32px] rounded-full bg-lightBlue1 border-2 flex items-center justify-center border-white -ml-2'
								rounded
								stacked
								theme={{
									root: {
										stacked: '',
										size: {
											xs: 'h-4 w-4'
										}
									}
								}}
							/>
						))}
						{remainingCount > 0 && (
							<Avatar.Counter
								theme={{
									base: 'relative w-[32px] h-[32px] bg-gray-100 border-2 text-primary font-semibold rounded-full border-white flex items-center justify-center text-blue-600 -ml-2'
								}}
								total={remainingCount}
							/>
						)}
					</Avatar.Group>
				) : (
					<>-</>
				)}
			</div>
			{isOpen && type === ScoutingModal.VIEW_EDITOR && (
				<ViewEditorModal show={isOpen} closeModal={closeModal} data={record} />
			)}
		</>
	);
};

export default ShortListEditor;
