import React from 'react';
import * as Sentry from '@sentry/react';
import AuthService from 'shared/services/auth.service';

interface IProps {
	children?: any;
}
interface IState {
	error: string | null;
}
class ErrorBoundary extends React.Component<IProps, IState> {
	static getDerivedStateFromError(error: Error | null) {
		return { error };
	}

	state = {
		error: null
	};

	componentDidCatch(error: Error | null) {
		Sentry.withScope((scope) => {
			const userData = AuthService.getAuthData() || null;
			scope.setExtra('userInfo', {
				email: userData?.email || '',
				userId: userData?.id || '',
				sports: userData?.sports || '',
				firstName: userData?.name || '',
				lastName: userData?.last_name || ''
			});

			Sentry.captureException(error);
		});

		this.logError(error);
	}

	render() {
		if (this.state.error) {
			return 'Application has errors. Please check logs to fix this';
		}
		return this.props.children;
	}

	logError(args: Error | null) {
		console.log(args);
	}
}

export default ErrorBoundary;
