import React from 'react';

import ButtonSecondary from 'shared/components/buttons/secondary';
import { PlusIcon } from 'shared/icon';
import TableRow from './tableRow';
import TableCell from './tableCell';
import EmptyData from 'assets/images/empty-data.png';
import usePermission from '../permissions/usePermissions';

const EmptyTable: React.FC<{
	text?: string;
	colSpan: number;
	hideRegisterButton: boolean;
	btnTxt?: string;
	onClick?: (e: React.MouseEvent) => void | undefined;
}> = (props) => {
	const { canAdd } = usePermission();

	return (
		<TableRow className='text-center'>
			<TableCell colSpan={props.colSpan}>
				<div className='flex items-center flex-col w-[370px] mx-auto mt-2 2xl:mt-20'>
					<img src={EmptyData} alt='no-data' />
					<div className='text-center text-textGray mb-5'>
						<p className='text-lg font-semibold'>Ooops! Nothing Here Yet</p>
						<p className='text-textGray text-sm'>{props.text ?? ''}</p>
					</div>
					{canAdd && !props.hideRegisterButton && (
						<ButtonSecondary
							className='w-[230px]'
							btnTxt={props.btnTxt ?? '-'}
							onClick={props.onClick ?? (() => {})}
							icon={<PlusIcon className='size-5 stroke-primary mr-3' />}
						/>
					)}
				</div>
			</TableCell>
		</TableRow>
	);
};

export default EmptyTable;
