import { FC, useMemo, useContext } from 'react';
import { Button } from 'flowbite-react';

import { ButtonTheme } from 'shared/components/buttons/primary';
import { AuthContext } from 'store/userDetailStore';
import { buttonGroupTheme, SPORTS } from 'features/athleteDatabase/constant';
import TooltipContainer from 'shared/components/tooltip';

interface IProps {
	selectedValue: string;
	onChange: (value: string) => void;
	buttonTheme?: any;
}

const SportsFilter: FC<IProps> = (props) => {
	const { selectedValue, onChange, buttonTheme } = props;

	const { userData } = useContext(AuthContext);

	const filteredSports = useMemo(() => {
		if (userData.sports === 'Both') return SPORTS;
		return [];
	}, [userData.sports]);

	return (
		<Button.Group outline theme={buttonGroupTheme} className='mr-3 h-fit'>
			{filteredSports.map(({ label, value, toolTip }) => (
				<TooltipContainer content={toolTip} key={value}>
					<Button
						disabled={value === 'Tennis'}
						className={`ring-none bg-lightBlue1 ${
							selectedValue === value ? 'bg-lightBlue1' : 'text-gray-400'
						} `}
						color='gray'
						theme={buttonTheme ?? initTheme}
						onClick={() => selectedValue !== value && onChange(value)}
					>
						{label}
					</Button>
				</TooltipContainer>
			))}
		</Button.Group>
	);
};

const initTheme = {
	size: {
		...ButtonTheme.size
	}
};

export default SportsFilter;
