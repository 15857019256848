import React, { PropsWithChildren, createContext, useCallback, useMemo, useState } from 'react';
import AuthService from 'shared/services/auth.service';
import { IUserData } from 'shared/interface';
import { ROLES } from 'shared/components/permissions/constants';

const AuthStore: React.FC<PropsWithChildren> = ({ children }) => {
	const [isLogin, setIsLogin] = useState<boolean>(AuthService.isLogin());
	const [userData, setUserData] = useState<IUserData>(AuthService.getAuthData() || ({} as IUserData));
	const [tempToken, setTempToken] = useState<string>(AuthService.getTempToken() || '');

	const logout = useCallback(() => {
		AuthService.removeAuthData();
		setIsLogin(false);
		setUserData({} as IUserData);
	}, []);

	const login = useCallback(
		(data: IUserData) => {
			AuthService.setAuthData(data);
			setIsLogin(true);
			setUserData(data);
		},
		[setIsLogin, setUserData]
	);

	const setAuthToken = useCallback(
		(token: string) => {
			setTempToken(token);
			AuthService.setTempToken(token);
		},
		[tempToken]
	);

	const contextData = useMemo(
		() => ({
			logout,
			login,
			isLogin,
			setIsLogin,
			userData,
			setUserData,
			userEmail: userData.email,
			tempToken,
			setAuthToken,
			roles: userData.user_link_modules
		}),
		[isLogin, login, logout, userData, tempToken, setAuthToken]
	);

	return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};

interface IContextType {
	isLogin: boolean;
	userData: IUserData;
	userEmail: string;
	setUserData: React.Dispatch<React.SetStateAction<IUserData>>;
	setIsLogin: (isLogin: boolean) => void;
	logout: () => void;
	login: (data: IUserData) => void;
	tempToken: string;
	roles: ROLES[];
	setAuthToken: (data: string) => void;
}

export const AuthContext = createContext({} as IContextType);
export default AuthStore;
