import { Avatar } from 'flowbite-react';
import { FC, useContext, useMemo } from 'react';

import { DownArrowIcon } from 'shared/icon';
import { AuthContext } from 'store/userDetailStore';
import { avatarTheme } from '../constant';

const Profile: FC<{ openMenu: () => void }> = ({ openMenu }) => {
	const { userData } = useContext(AuthContext);
	const player = useMemo(() => ({ name: userData?.name || '', last_name: userData?.last_name || '' }), [userData]);
	return (
		<div className='flex cursor-pointer items-center' data-dropdown-toggle='user-dropdown-menu' onClick={openMenu}>
			<div className='flex flex-wrap gap-2'>
				<Avatar
					className='uppercase'
					placeholderInitials={`${player.name ? player.name.split('')[0] : 'Y'}${
						player.last_name ? player.last_name.split('')[0] : 'N'
					}`}
					theme={avatarTheme}
					img={userData.profile_picture}
					rounded
				/>
			</div>

			<div className='ml-3 flex flex-col'>
				<span className='block text-sm dark:text-white capitalize'>{player.name + ' ' + player.last_name}</span>
				<span className='block truncate text-sm font-medium dark:text-white'>{userData?.email || ''}</span>
			</div>

			<DownArrowIcon className='ml-4 size-2.5 dark:text-white stroke-[#344054]' />
		</div>
	);
};

export default Profile;
