import { useContext, useMemo } from 'react';
import { ROLES } from 'shared/components/permissions/constants';
import { AuthContext } from 'store/userDetailStore';

const useModulePermission = () => {
	const { roles } = useContext(AuthContext);

	const hasModulePermission = useMemo(() => {
		return {
			hasScoutingRecruiting: roles?.includes(ROLES.SCOUTING_RECRUITING) || false,
			hasContractManagement: roles?.includes(ROLES.CONTRACT_MANAGEMENT) || false,
			hasAthleteManagement: roles?.includes(ROLES.ATHLETE_MANAGEMENT_DATABASE) || false
		};
	}, [roles]);

	return hasModulePermission;
};

export default useModulePermission;
