import React from 'react';
import { isNull } from 'lodash';
import { Button, Modal } from 'flowbite-react';

import { ButtonTheme } from 'shared/components/buttons/primary';
import { InfoIcon } from 'shared/icon';
import { INotification } from '../interface/interface';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';

interface IProps {
	show: boolean;
	onClose: () => void;
	data: INotification;
	getNotification: () => void;
}

const ConfirmRequestModal: React.FC<IProps> = (props) => {
	const { show, onClose, data } = props;

	const handleChangeRequest = async (id: string, value: string) => {
		try {
			const params = {
				notification_id: id,
				status: value
			};
			await HttpService.put(`${API_CONFIG.changeStatus}`, params);
			props.getNotification();
			onClose();
		} catch (error) {
			console.log(error);
		}
	};

	const dataArray = data.content?.split('~');

	return (
		<Modal show={show} onClose={onClose} theme={Theme}>
			<Modal.Header className='rounded-t-lg bg-lightBlue'>
				<div>
					<div className='size-10 shadow-sm mb-1 flex border-themeGray items-center justify-center rounded-lg'>
						<InfoIcon className='stroke-[#0057B7]' />
					</div>
					<p className='header-txt'>{`Access Change Request`}</p>
					<p className='sub-header-txt'>
						Are you sure you want to Change
						<span className='font-bold mx-1'>{`${data.sender_name} ${data.sender_last_name}`}</span>
						Access?
					</p>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className='p-3'>
					{dataArray.map((htmlContent, index) => (
						<p
							className='text-sm-responsive text-[#111827]'
							key={index}
							dangerouslySetInnerHTML={{ __html: htmlContent }}
						/>
					))}
				</div>
				{isNull(data.is_accepted) && (
					<div className='flex gap-3 mt-3'>
						<Button
							type={'button'}
							className={`ring-none border-[#EC221F] font-semibold text-[#EC221F] w-full`}
							color='light'
							theme={ButtonTheme}
							onClick={() => handleChangeRequest(data.notification_id, 'Reject')}
						>
							Reject
						</Button>
						<Button
							type={'button'}
							className={`ring-none border-[#1D9533] font-semibold text-[#1D9533] w-full`}
							color='light'
							theme={ButtonTheme}
							onClick={() => handleChangeRequest(data.notification_id, 'Accept')}
						>
							Accept
						</Button>
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default ConfirmRequestModal;

const Theme = {
	root: {
		show: {
			on: 'flex bg-[#0C111D] bg-opacity-50 backdrop-blur-sm dark:bg-opacity-80'
		},
		sizes: {
			'2xl': 'none'
		}
	},
	header: {
		base: 'flex items-start justify-between px-4 py-5 border-b-2 border-primary',
		close: {
			base: 'none'
		}
	},
	content: {
		base: 'relative h-full p-4 md:h-auto w-full max-w-[450px]'
	},
	body: {
		base: 'flex-1 px-3 pb-3 overflow-auto custom-scrollbar'
	}
};
