import { get } from 'lodash';
import React, { useCallback } from 'react';
import { Control, FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import DateInput from 'shared/form/datepicker';
import { Input } from 'shared/form/input';
import Select from 'shared/form/select/select';

import SearchableSelect from 'shared/form/select/searchableSelect';
import { IDropdownOptions } from 'shared/interface';
import ProfileEditor from 'features/addPlayer/component/form/profileEditor';
import { IQuickAddValues } from 'features/athleteDatabase/interface';
import {
	BRAND_OPTIONS,
	CIRCUIT_LEVEL_OPTIONS,
	DISCIPLINE_OPTIONS,
	GENDER_OPTIONS,
	PLAYING_HAND,
	STATUS_OPTIONS
} from 'features/athleteDatabase/constant';

interface IProps {
	control: Control<IQuickAddValues>;
	register: UseFormRegister<IQuickAddValues>;
	setValue: UseFormSetValue<IQuickAddValues>;
	getValues: UseFormGetValues<any>;
	errors: FieldErrors<IQuickAddValues>;
	countryOptions: IDropdownOptions[];
}

const QuickAddAthleteInfo: React.FC<IProps> = ({ register, control, setValue, errors, countryOptions, getValues }) => {
	const getErrorMsg = useCallback(
		(key: string) => {
			return get(errors, `${key}.message`, '') as string;
		},
		[errors]
	);
	return (
		<>
			<div className='grid grid-cols-3 gap-x-4 gap-y-2 mb-5'>
				<Input
					register={register}
					title={'First Name'}
					name='player_information.first_name'
					errorMsg={getErrorMsg('player_information.first_name')}
					placeholder='Athlete Name'
					required
				/>
				<Input
					register={register}
					title={'Last Name'}
					name='player_information.last_name'
					errorMsg={getErrorMsg('player_information.last_name')}
					placeholder='Athlete Last Name'
					required
				/>

				<Input
					register={register}
					title={'Athlete ID'}
					name='player_information.athlete_id'
					placeholder='Insert Athlete ID'
				/>

				<Select
					placeHolder='Select Athlete Status'
					control={control}
					name='player_information.status'
					title='Status'
					options={STATUS_OPTIONS}
					errorMsg={getErrorMsg('player_information.status')}
					required
				/>

				<Select
					placeHolder='Select Level'
					control={control}
					name='player_information.circuit_level'
					title='Circuit Level'
					options={CIRCUIT_LEVEL_OPTIONS}
					errorMsg={getErrorMsg('player_information.circuit_level')}
					required
				/>
				<Select
					placeHolder='Athlete Gender'
					control={control}
					name='player_information.gender'
					title='Gender'
					options={GENDER_OPTIONS}
					errorMsg={getErrorMsg('player_information.gender')}
					required
				/>

				<DateInput
					name='player_information.date_of_birth'
					setValue={setValue}
					control={control}
					title='Date of Birth'
					placeHolder={'Select Date'}
				/>

				<SearchableSelect
					control={control}
					title='Country'
					name='player_information.country'
					placeHolder='Athlete Country'
					options={countryOptions ?? []}
					required
					errorMsg={getErrorMsg('player_information.country')}
				/>

				<Select
					placeHolder='Type Racquet Brand Name'
					control={control}
					name='player_information.racquet_brand'
					title='Racquet Brand'
					options={BRAND_OPTIONS}
					errorMsg={getErrorMsg('player_information.racquet_brand')}
					required
				/>
				<Select
					placeHolder='Type Category 1'
					control={control}
					name='player_information.category1'
					title='Category 1'
					options={DISCIPLINE_OPTIONS}
					errorMsg={getErrorMsg('player_information.category1')}
					required
				/>
				<Select
					placeHolder='Type Category 2'
					control={control}
					name='player_information.category2'
					title='Category 2'
					options={DISCIPLINE_OPTIONS}
				/>
				<Select
					placeHolder='Type Category 3'
					control={control}
					name='player_information.category3'
					title='Category 3'
					options={DISCIPLINE_OPTIONS}
				/>
				<Select
					placeHolder='Choose Athlete Hand'
					control={control}
					name='player_information.playing_hand'
					title='Playing Hand'
					options={PLAYING_HAND}
					errorMsg={getErrorMsg('player_information.playing_hand')}
				/>
			</div>
			<ProfileEditor control={control} errors={errors} setValue={setValue} getValues={getValues} />
		</>
	);
};

export default QuickAddAthleteInfo;
