import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'store/userDetailStore';
import { LEVELS, PERMISSIONS } from './constants';

const usePermission = () => {
	const { userData } = useContext(AuthContext);

	const [permissions, setPermissions] = useState({
		view: false,
		add: false,
		edit: false,
		delete: false,
		viewHistory: false,
		changeTeam: false,
		disableProfile: false,
		share: false,
		commenting: false
	});

	useEffect(() => {
		const permissionLevel: LEVELS = userData.access_type || LEVELS.Level4; // Default to 'Level 4' if not found
		const permissionData = PERMISSIONS[permissionLevel];

		if (permissionData) {
			setPermissions(permissionData);
		}
	}, [userData]);

	return {
		canView: permissions.view,
		canAdd: permissions.add,
		canEdit: permissions.edit,
		canDelete: permissions.delete,
		canViewHistory: permissions.viewHistory,
		canChangeTeam: permissions.changeTeam,
		canDisableProfile: permissions.disableProfile,
		canShare: permissions.share,
		canComment: permissions.commenting
	};
};

export default usePermission;
