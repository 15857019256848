import { useContext, useMemo } from 'react';
import { ROLES } from './constants';
import { AuthContext } from 'store/userDetailStore';

const useAccessPermission = (accessRoles: ROLES[]) => {
	const { roles } = useContext(AuthContext);

	const permissions = useMemo(() => {
		return roles?.filter((value) => accessRoles.includes(value)) ?? [];
	}, [accessRoles, roles]);

	return permissions.length > 0;
};

export default useAccessPermission;
