import { FC } from 'react';

import { ASCIcon, PlusIcon } from 'shared/icon';
import { ISortData, OrderType } from 'shared/interface';
import Head from './tableHead';
import TooltipContainer from '../tooltip';

export interface ITableCell {
	title: string;
	style?: IObj;
	key?: string;
	sortable?: boolean;
	shortKey?: string;
	tooltipText?: string | any;
	tooltip?: boolean;
}
export interface IObj {
	[key: string]: string | number;
}

export interface ITableHeadProps {
	tableCellList: ITableCell[];
	sortData?: ISortData;
	onSort?: (sortData: ISortData) => void;
	className?: string;
	viewMatrix?: () => void;
	headerTitleClassName?: string;
}

const TableHeader: FC<ITableHeadProps> = ({
	tableCellList,
	sortData,
	onSort,
	className,
	viewMatrix,
	headerTitleClassName
}) => {
	const onClick = (shortKey: string) => {
		if (onSort) {
			let sort_by: OrderType | string = OrderType.ASC;
			if (sortData && sortData.order_by === shortKey) {
				if (sortData.sort_by === OrderType.ASC) {
					sort_by = OrderType.DESC;
				} else if (sortData.sort_by === '') {
					sort_by = OrderType.ASC;
				} else {
					shortKey = '';
					sort_by = '';
				}
			}

			onSort({ sort_by: sort_by, order_by: shortKey });
		}
	};
	return (
		<Head className={className}>
			{tableCellList.map(({ title, style, sortable, key, shortKey, tooltipText, tooltip }, index) => {
				const isSelected = sortData?.order_by === shortKey;
				return (
					<th
						key={index}
						className={`bg-lightBlue py-2 px-1 border-b-2 border-b-primary font-medium capitalize text-textGray text-sm-responsive ${
							sortable && 'cursor-pointer'
						} select-none`}
						style={style}
						onClick={() => sortable && shortKey && onClick(shortKey)}
					>
						<div className={`${headerTitleClassName} flex items-center justify-center text-nowrap`}>
							{tooltip ? (
								<TooltipContainer placement='top' style={'light'} color={'light'} content={tooltipText}>
									{title}
								</TooltipContainer>
							) : (
								title
							)}
							{viewMatrix && key === 'perform' && (
								<div
									className='ml-1 flex cursor-pointer items-center justify-center'
									onClick={(e) => {
										e.stopPropagation();
										viewMatrix && viewMatrix();
									}}
								>
									<PlusIcon className='size-3 stroke-[#D0D5DD]' fill='#D0D5DD' />
								</div>
							)}
							{sortable && (
								<ASCIcon
									className={`ml-2 size-3 ${
										isSelected && sortData?.sort_by === OrderType.DESC && 'rotate-180'
									} transition-transform`}
									fill={`${isSelected && sortData?.sort_by ? '#1A56DB' : '#D0D5DD'}`}
								/>
							)}
						</div>
					</th>
				);
			})}
		</Head>
	);
};

export default TableHeader;
