import { useState } from 'react';

export default function useModal<T>() {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [type, setType] = useState<T | ''>('');

	const openModal = (type: T) => {
		setIsOpen(true);
		setType(type);
	};

	const closeModal = () => {
		setIsOpen(false);
		setType('');
	};

	return {
		isOpen,
		type,
		openModal,
		closeModal
	};
}
