import { FC, PropsWithChildren } from 'react';
import { Modal } from 'flowbite-react';

import ButtonPrimary from '../buttons/primary';
import ButtonSecondary from '../buttons/secondary';
import { useTranslation } from 'react-i18next';
import { SignalIcon } from 'shared/icon';

interface IProps extends PropsWithChildren {
	loading?: boolean;
	className?: string;
	show: boolean;
	secondaryBtnTxt?: string;
	primaryBtnTxt?: string;
	disable?: boolean;
	activeTab?: string;
	onClose: () => void;
	onSubmit: () => void;
}

const YModal: FC<IProps> = ({
	loading,
	show,
	className,
	children,
	primaryBtnTxt,
	secondaryBtnTxt,
	activeTab,
	disable = false,
	onClose,
	onSubmit
}) => {
	const { t } = useTranslation(['header']);

	return (
		<Modal className={`${className || ''}`} show={show} onClose={onClose} theme={Theme}>
			<Modal.Header className='rounded-t-lg border-b-2 border-primary bg-lightBlue'>
				<div>
					{activeTab === 'manageAccess' && (
						<div className='w-12 h-12 rounded-lg border-borderGray border-2 flex justify-center items-center mb-4 shadow-[0_1px_2px_0px_rgba(16,24,40,0.05)]'>
							<SignalIcon />
						</div>
					)}
					<p className='header-txt'>
						{t(`header:${activeTab === 'manageAccess' ? 'accessManagement' : 'inviteToThePlatform'}`)}
					</p>
					<p className='sub-header-txt '>Manage the access to the platform, invite coworkers or athletes</p>
				</div>
			</Modal.Header>
			<Modal.Body>{children}</Modal.Body>
			<Modal.Footer>
				{secondaryBtnTxt && <ButtonSecondary className='w-full' onClick={onClose} btnTxt={secondaryBtnTxt} />}
				{primaryBtnTxt && (
					<ButtonPrimary
						isLoading={loading ?? false}
						className='w-full'
						onClick={onSubmit}
						btnTxt={primaryBtnTxt}
						disable={disable}
					/>
				)}
			</Modal.Footer>
		</Modal>
	);
};

const Theme = {
	root: {
		show: {
			on: 'flex bg-[#0C111D] bg-opacity-50 backdrop-blur-sm dark:bg-opacity-80'
		}
	},
	body: {
		base: 'flex-1 p-6 overflow-auto custom-scrollbar'
	}
};

export default YModal;
