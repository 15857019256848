import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import useHavePermission from './userAccessPermissions';
import { ROLES } from './constants';

interface IProps {
	accessRoles: ROLES[];
	redirectPath?: string;
}

const ProtectedRoute: React.FC<PropsWithChildren<IProps>> = ({ accessRoles, children, redirectPath = '/' }) => {
	const havePermission = useHavePermission(accessRoles);

	if (!havePermission) {
		return <Navigate to={redirectPath} replace />;
	}

	return <>{children}</>;
};

export default ProtectedRoute;
