import { CONTRACT_TYPE_OPTIONS, RETAINER_CURRENCY } from '../component/form/contractInfo';

export const T = [];

export type AthleteFormValues = {
	department: string;
	editor: string;
	editor_note: string;
	player_information: {
		profile_picture: string;
		first_name: string;
		last_name: string;
		racquet_brand: string;
		gender: string;
		date_of_birth: string;
		status: string;
		athlete_id: string;
		circuit_level: string;
		playing_hand: string;
		category1: string;
		category2: string;
		category3: string;
		country_id: number;
		sports: string;
		country: string;
		tournament: string;
		tournament_note: string;
	};

	contract_information: {
		type: string;
		retainer_amount: number;
		retainer_currency: string;
		retainer_term_start: string | null;
		retainer_term_end: string | null;
	};
	guardian_information: {
		first_name: string;
		last_name: string;
		email: string;
		relation: string;
		telephone_number: string;
	};
	agent_information: {
		first_name: string;
		last_name: string;
		email: string;
		telephone_number: string;
	};
	performance_profile: {
		performance_toggle: boolean;
		team_yonex_senior_level: string;
		legends_vision: string;
		team_yonex_future_star_level: string;
		training_age: number;
		potential_grade: string;
		x_factor: string;
	};
	social_medias: ISocialMedia[];
	marketability_current: string;
	marketability_potential: string;
	kpis: [
		{
			kpi_id: number;
			value: number;
		}
	];
	kpi_groups: [
		{
			kpi_group_name: string;
			note: string;
			tournament: string;
		}
	];
	facebook: boolean;
	instagram: boolean;
	tikTok: boolean;
	x: boolean;
	weibo: boolean;
	youTube: boolean;
	xfactor: boolean;
	player_videos: IPlayerVideos[];
	notify_editor_info: IEditor;
};

export interface IEditor {
	content: string;
	user_ids: string[];
}

export const defaultValues: AthleteFormValues = {
	department: '',
	editor: '',
	editor_note: '',
	player_information: {
		profile_picture: '',
		first_name: '',
		last_name: '',
		racquet_brand: '',
		gender: '',
		date_of_birth: '',
		status: '',
		athlete_id: '',
		circuit_level: '',
		playing_hand: '',
		category1: '',
		category2: '',
		category3: '',
		country_id: 0,
		sports: '',
		// continent: '',
		country: '',
		tournament: '',
		tournament_note: ''
	},
	contract_information: {
		type: CONTRACT_TYPE_OPTIONS[0]?.value ?? '',
		retainer_amount: 0,
		retainer_currency: RETAINER_CURRENCY[0]?.value ?? '',
		retainer_term_start: null,
		retainer_term_end: null
	},
	guardian_information: {
		first_name: '',
		last_name: '',
		email: '',
		relation: '',
		telephone_number: ''
	},
	agent_information: {
		first_name: '',
		last_name: '',
		email: '',
		telephone_number: ''
	},
	performance_profile: {
		performance_toggle: false,
		team_yonex_senior_level: '',
		legends_vision: '',
		team_yonex_future_star_level: '',
		training_age: 0,
		potential_grade: '',
		x_factor: ''
	},
	marketability_current: '',
	marketability_potential: '',
	kpis: [
		{
			kpi_id: 0,
			value: 0
		}
	],
	kpi_groups: [
		{
			kpi_group_name: '',
			note: '',
			tournament: ''
		}
	],
	facebook: false,
	instagram: false,
	tikTok: false,
	x: false,
	weibo: false,
	youTube: false,
	xfactor: false,
	social_medias: [],
	player_videos: [],
	notify_editor_info: {} as IEditor
};

export const enum playerModal {
	ADDED_PLAYER = 'Added player',
	EDITOR_COMMENT = 'Editor Comment'
}

export interface IAthleteFormValues {
	player_information: IPlayerInformation;
	player_videos: IPlayerVideos;
	contract_information: IContractInformation;
	guardian_information: IGuardianInformation;
	agent_information: IAgentInformation;
	performance_profile: IPerformanceProfile;
	kpis: IKpis;
	kpi_groups: IKpiGroups;
	marketability_current: string;
	marketability_potential: string;
	player_quick_note: string;
}

export interface IPlayerInformation {
	profile_picture: string;
	first_name: string;
	last_name: string;
	racquet_brand: string;
	gender: string;
	date_of_birth: string;
	status: string;
	athlete_id: string;
	circuit_level: string;
	playing_hand: string;
	category1: string;
	category2: string;
	category3: string;
	country_id: number;
	sports: string;
	continent: string;
}

export interface IPlayerVideos {
	tournament_name: string;
	comment: string;
	video_url: string;
	creation_date: string;
	file?: File;
	scout_id?: string;
	id?: string;
}

export interface IContractInformation {
	type: string;
	retainer_amount: number;
	retainer_currency: string;
	start_date: string;
	end_date: string;
}

export interface IGuardianInformation {
	first_name: string;
	last_name: string;
	relation: string;
	email: string;
	telephone_number_country_code: string;
	telephone_number: string;
}

export interface IAgentInformation {
	first_name: string;
	last_name: string;
	email: string;
	telephone_number_country_code: string;
	telephone_number: string;
}

export interface IPerformanceProfile {
	senior_level: string;
	legends_vision: string;
	future_star_level: string;
	training_age: number;
	potential_grade: string;
}

export interface IKpis {
	reduce(arg0: (acc: any, current: any) => any, arg1: {}): unknown;
	length: number;
	kpi_id: number;
	value: number;
}

export interface IKpiGroups {
	map(arg0: (kpiNote: any) => { note: any; tournament: any; kpi_group_name: any }): unknown;
	length: number;
	kpi_group_name: string;
	note: string;
	tournament: string;
}

export interface ISocialMedia {
	name: string;
	profile_link: string;
	follower_count: string;
	last_updated_on: string;
}
export interface IAddedPlayer {
	created_at: string;
	updated_at: string;
	deleted_at: string;
	id: string;
	profile_picture: string;
	first_name: string;
	last_name: string;
	racquet_brand: string;
	gender: string;
	date_of_birth: string;
	status: string;
	athlete_id: string;
	circuit_level: string;
	playing_hand: string;
	category1: string;
	category2: string;
	category3: string;
	contract_type: string;
	contract_retainer_amount: number;
	contract_retainer_currency: string;
	contract_retainer_term_start: string | null;
	contract_retainer_term_end: string | null;
	guardian_first_name: string;
	guardian_last_name: string;
	guardian_relation: string;
	guardian_email: string;
	guardian_telephone_number: string;
	agent_first_name: string;
	agent_last_name: string;
	agent_email: string;
	agent_telephone_number: string;
	team_yonex_future_star_level: string;
	training_age: number;
	potential_grade: string;
	x_factor: string;
	team_yonex_senior_level: string;
	legends_vision: string;
	country: ICountry;
	sports: string;
	marketability_current: string;
	marketability_potential: string;
}

export interface ICountry {
	id: number;
}

export interface IPlayerNote {
	note: string;
	tournament: string;
	kpi_group_name: string;
}

export interface ICountryOption {
	label: string;
	value: number;
}

export interface IVideoInfo {
	id: string;
	video_url: string;
	creation_date: string;
	comment: string;
	tournament_name: string;
	player_id: string;
	user_id: string;
	user_name: string;
	user_last_name: string;
	scout_id: any;
	scout_name: any;
	scout_last_name: any;
	video_size: number;
	file?: File;
	athlete_name?: string;
}

export interface IKpiValueNew {
	id: number;
	name: string;
}

export interface IKpiDataNew {
	title: string;
	kpisData: IKpiValueNew[];
	isAddNote: boolean;
}

export interface IKpiResponse {
	kpi_id: number;
	value: number;
}

export interface IKpiDetails {
	created_at: string;
	updated_at: string;
	deleted_at: string;
	id: number;
	name: string;
	kpi_group: string;
}

export interface IUserScout {
	id: string;
	name: string;
	last_name: string;
	email: string;
	phone_number: any;
	coworker_title: string;
	department_group: string;
	department: any;
	internal_department: string;
	access_type: string;
	sports: string;
	user_link_modules: string[];
}

export interface IEditorResponse {
	id: string;
	name: string;
	last_name: string;
	email: string;
	phone_number: string;
	coworker_title: string;
	department_group: string;
	department: string;
	internal_department: string;
	access_type: string;
	sports: string;
	user_link_modules: IUserLinkModule[];
}

export interface IUserLinkModule {
	id: string;
	module: string;
}
