import React, { PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren {
	className?: string | undefined;
}

const TableHead: React.FC<IProps> = (props) => {
	return <thead className={`z-10 sticky top-0 ${props.className ?? ''}`}>{props.children}</thead>;
};

export default TableHead;
