import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import Root from 'root';

import { ENVIRONMENT, SENTRY_DSN } from 'shared/constants';

import reportWebVitals from './reportWebVitals';
import './index.css';

if (['prod', 'stage', 'dev'].includes(ENVIRONMENT)) {
	Sentry.init({
		dsn: SENTRY_DSN,
		integrations: [Sentry.browserTracingIntegration()],
		environment: ENVIRONMENT,
		tracesSampleRate: 1.0
	});
}

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
