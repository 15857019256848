import { FC, PropsWithChildren } from 'react';
import Header from 'shared/components/header/header';
import Footer from 'shared/footer/footer';

const Layout: FC<PropsWithChildren> = ({ children }) => {
	return (
		<>
			<Header />
			<main className='layout-container dark:bg-gray-800 min-h-[calc(100vh-100px)]'>{children}</main>
			<Footer />
		</>
	);
};

export default Layout;
