import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';

export const API_CONFIG = {
	generateOtp: 'registration/generate-otp',
	verifyOtp: 'registration/verify-otp',
	registerUser: 'registration/store-password',
	inviteUser: 'registration/invite-user',
	login: 'auth/login',
	logout: 'logout',
	forgotPassword: 'auth/forgot-password',
	resetPassword: 'auth/reset-password',
	userList: 'user',
	editorList: 'user/editors-list',
	kpiList: 'player/kpis-list',
	kpiListNew: 'player/kpis-list-new',
	player: 'player',
	quickNote: 'player/quick-note',
	presignedUrl: 'player/generate-presigned-url',
	countryList: 'player/countries-list',
	notification: 'notification',
	notificationRead: 'notification/read',
	notificationAllRead: 'notification/read-all',
	filters: 'filter',
	shortlists: 'scouting/shortlists',
	shortlist: 'scouting/shortlist',
	scouting: 'scouting',
	scoutingPlayer: 'scouting/get-players',
	tournamentContract: 'tournament-contract',
	teamContract: 'team-contract',
	individualContract: 'individual-contract',
	pinUnpin: 'scouting/pin-unpin',
	editShortlistPlayer: 'scouting/edit-shortlist-player-list',
	pinUnpinPlayer: 'scouting/pin-unpin-player',
	totalCounts: 'scouting/total-counts',
	recruiting: 'recruiting',
	recruitingDashboard: 'recruiting/recruiting-board',
	recruitingPinUnpin: 'recruiting/pin-unpin',
	editDashboardPlayer: 'recruiting/edit-recruiting-board-player-list',
	allShortlistPlayer: 'scouting/all-shortlists-players',
	accountSetting: 'user/account_settings',
	changeRequest: 'user/account_settings/access-change-request',
	changeStatus: 'user/account_settings/access-change-request-status',
	changePassword: 'user/change-password',
	editorsList: 'user/editors-list-scouting-recruiting',
	scoutingPlayers: 'scouting/players-grouped-by-circuit-level',
	scoutingPlayerIds: 'scouting/shortlist-players-id',
	boardPlayerIds: 'recruiting/recruiting-board-players-id'
};

export const getUrl = (url: string, params: any = {}): string => {
	Object.keys(params).forEach((key) => (params[key] == null || params[key] === '') && delete params[key]);
	let urlString = `${url}`;
	if (params && !isEmpty(params)) {
		urlString += `?${queryString.stringify(params)}`;
	}
	return urlString;
};
