import React, { useCallback, useState } from 'react';
import { Spinner } from 'flowbite-react';
import { isEmpty } from 'lodash';

import { IPlayers, ViewType } from '../interface';
import EmptyData from 'shared/components/table/emptyData';

import PlayerCard from './playerCard';
import ExpandRow from './expandRow';
import useCardsPerRow from 'shared/hooks/useCardPerView';

interface IProps {
	playerData: IPlayers[];
	loading: boolean;
}

const PlayerCardView: React.FC<IProps> = (props) => {
	const { playerData, loading } = props;
	const [expandRowId, setExpandRowId] = useState<string | null>(null);

	const cardsPerRow = useCardsPerRow();

	const handleExpand = (playerId: string) => {
		setExpandRowId((prev) => (prev === playerId ? null : playerId));
	};

	const checkExpandedRow = useCallback(
		(index: number) => {
			// Calculate which row this card belongs to
			const currentRowStartIndex = Math.floor(index / cardsPerRow) * cardsPerRow;
			const currentRowEndIndex = currentRowStartIndex + cardsPerRow - 1;
			return (
				expandRowId &&
				playerData.findIndex((player) => player.id === expandRowId) >= currentRowStartIndex &&
				playerData.findIndex((player) => player.id === expandRowId) <= currentRowEndIndex
			);
		},
		[playerData, expandRowId]
	);

	const getPlayer = useCallback(() => {
		// Calculate which row this card belongs to
		return playerData.find((player) => player.id === expandRowId);
	}, [expandRowId]);

	return (
		<div className='flex items-start justify-center border-x border-b p-3 h-[calc(100vh-260px)] 2xl:h-[calc(100vh-280px)] min-h-[340px] overflow-auto custom-scrollbar'>
			{!loading && playerData.length > 0 && (
				<div className='flex flex-wrap gap-2 w-full'>
					{playerData.map((data, index) => {
						// Determine if the expanded card belongs to this row
						const isInExpandedRow = checkExpandedRow(index);
						const isLastInRow = (index + 1) % cardsPerRow === 0 || index === playerData.length - 1;
						return (
							<React.Fragment key={data.id}>
								<PlayerCard
									player={data}
									onExpand={() => handleExpand(data.id)}
									isExpanded={expandRowId === data.id}
								/>
								{isInExpandedRow && isLastInRow && (
									<div className='w-full'>
										<ExpandRow
											key={expandRowId}
											record={getPlayer() as IPlayers}
											viewType={ViewType.Card_View}
										/>
									</div>
								)}
							</React.Fragment>
						);
					})}
				</div>
			)}
			{!loading && isEmpty(playerData) && <EmptyData className='h-full' />}
			{loading && (
				<div className='text-center flex items-center justify-center mt-3 '>
					<Spinner aria-label='Extra large Center-aligned spinner example' size='xl' />
				</div>
			)}
		</div>
	);
};
export default PlayerCardView;
