import React, { PropsWithChildren } from 'react';
import useHavePermission from './userAccessPermissions';
import { ROLES } from './constants';

interface IProps {
	accessRoles: ROLES[];
}

const PermissionWrapper: React.FC<PropsWithChildren<IProps>> = ({ accessRoles, children }) => {
	const havePermission = useHavePermission(accessRoles);

	return <>{havePermission && children}</>;
};

export default PermissionWrapper;
