import { FC, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import useFlowbite from 'shared/hooks/useFlowbite';
import { DownArrowIcon } from 'shared/icon';
import TooltipSlider from '../slider';
import { IKpiValueNew } from 'features/addPlayer/interface';

interface IProps {
	name: string;
	options: IKpiValueNew[];
	className?: string;
	control: any;
	placeHolder?: string;
	menuPosition?: 'top' | 'bottom';
	disabled?: boolean;
	kpiValues: { [key: number]: number };
	handleChangeSlider: (value: number, kpiId: number) => void;
}

const SliderSelect: FC<IProps> = ({
	name,
	options,
	className,
	control,
	placeHolder,
	disabled,
	kpiValues,
	handleChangeSlider
}) => {
	useFlowbite();
	const [dropdownVisible, setDropdownVisible] = useState(false);

	const dropdownRef = useRef<HTMLDivElement | null>(null);

	// Handle outside click
	const handleClickOutside = (event: any) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			closeDropdown();
		}
	};

	useEffect(() => {
		// Add event listener to handle outside clicks
		document.addEventListener('mousedown', handleClickOutside);

		// Cleanup event listener on component unmount
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const closeDropdown = () => {
		setDropdownVisible(false);
	};

	return (
		<div className={`${className || ''} relative w-full`} ref={dropdownRef}>
			<Controller
				control={control}
				name={name}
				render={() => (
					<>
						<button
							disabled={disabled}
							id='dropdownButton'
							className={`input-select ring-none focus:outline-none focus:ring-4 text-themeGray-text focus:ring-blue-300 `}
							type='button'
							color='white'
							onClick={() => setDropdownVisible(!dropdownVisible)}
						>
							{placeHolder || 'Select'}
							<DownArrowIcon className='size-2.5 stroke-[#344054]' />
						</button>

						<div
							id='dropdown'
							className={`absolute cursor-pointer z-10 w-full divide-y divide-gray-100 rounded-lg bg-white shadow  dark:bg-gray-700 ${
								dropdownVisible ? 'block' : 'hidden'
							}`}
						>
							<ul
								className={`text-sm text-gray-700 dark:text-gray-200 max-h-[300px] overflow-auto custom-scrollbar `}
								aria-labelledby='dropdownButton'
							>
								{options.map((option) => (
									<div className='mt-1 px-2' key={option.id}>
										<TooltipSlider
											isSize
											value={kpiValues[option.id] || 0}
											title={option.name}
											className={`max-w-[280px] p-2 rounded-lg ${
												kpiValues[option.id] ? 'bg-[#F0F5FC]' : 'bg-white'
											}`}
											onChange={(value) => handleChangeSlider(option.id, value)}
										/>
									</div>
								))}
							</ul>
						</div>
					</>
				)}
			/>
		</div>
	);
};

export default SliderSelect;
