export const API_BASE_URL = process.env['REACT_APP_BASE_URL'];
export const ENVIRONMENT = process.env['REACT_APP_ENVIRONMENT'] || '';
export const SENTRY_DSN = process.env['REACT_APP_SENTRY_DSN'] || '';
export const BUCKET_NAME = process.env['REACT_APP_BUCKET'];
export const FILE_NAME = process.env['REACT_APP_FILE_NAME'];

export const TOURNAMENT_VIDEO_OPTIONS = [
	{ label: 'Tournament 1', value: 'Tournament 1' },
	{ label: 'Tournament 2', value: 'Tournament 2' },
	{ label: 'Tournament 3', value: 'Tournament 3' },
	{ label: 'Tournament 4', value: 'Tournament 4' }
];
export const TOURNAMENT_OPTIONS = [
	{ label: 'European Junior Championships', value: 'European Junior Championships' },
	{ label: 'World Junior Championship', value: 'World Junior Championship' },
	{ label: 'Asia Junior Championships', value: 'Asia Junior Championships' },
	{ label: 'PanAm Junior Championships', value: 'PanAm Junior Championships' },
	{ label: 'German Junior', value: 'German Junior' },
	{ label: 'Dutch Junior', value: 'Dutch Junior' },
	{ label: 'Junior National Championships', value: 'Junior National Championships' },
	{ label: 'Other', value: 'Other' }
];

export const dropdownOptions = [
	{
		value: 'Headquarters',
		label: 'Headquarters',
		nested: false
	},
	{
		value: 'Subsidiary',
		label: 'Subsidiary',
		nested: true,
		nestedOptions: [
			{ value: 'YONEX Gmbh (GERMANY)', label: 'YONEX Gmbh (GERMANY)' },
			{ value: 'YONEX SPORTS (CHINA) CO., LTD.', label: 'YONEX SPORTS (CHINA) CO., LTD.' },
			{ value: 'YONEX JAPAN', label: 'YONEX JAPAN' },
			{ value: 'YONEX CANADA', label: 'YONEX CANADA' },
			{ value: 'YONEX U.K. Ltd.', label: 'YONEX U.K. Ltd.' },
			{ value: 'YONEX TAIWAN Co., Ltd.', label: 'YONEX TAIWAN Co., Ltd.' }
		]
	},
	{
		value: 'Distributor',
		label: 'Distributor',
		nested: true,
		nestedOptions: [
			{ value: `Mexico: La Loma Proshop`, label: `Mexico: La Loma Proshop` },
			{ value: `Argentina: SUPERBRANDS S.A.`, label: `Argentina: SUPERBRANDS S.A.` },
			{ value: `Brazil: Licensing Development`, label: `Brazil: Licensing Development` },
			{ value: `Chile: SPORTING BRANDS LTDA`, label: `Chile: SPORTING BRANDS LTDA` },
			{ value: `Panama: Supro Mundial, S.A.`, label: `Panama: Supro Mundial, S.A.` },
			{
				value: `Botswana,Namibia,SouthAfrica and Zambia: Aurora Sports (PTY)LTD`,
				label: `Botswana,Namibia,SouthAfrica and Zambia: Aurora Sports (PTY)LTD`
			},
			{ value: `Egypt: De Quanta`, label: `Egypt: De Quanta` },
			{ value: `Other Countries in Africa: YONEX Co. Ltd.`, label: `Other Countries in Africa: YONEX Co. Ltd.` },
			{
				value: `Denmark, Sweden, Norway, Finland, Iceland: Scansport A/S`,
				label: `Denmark, Sweden, Norway, Finland, Iceland: Scansport A/S`
			},
			{
				value: `Belgium, Netherlands, Luxembourg: Distri Sport International NV`,
				label: `Belgium, Netherlands, Luxembourg: Distri Sport International NV`
			},
			{
				value: `France, Spain, Portugal: BDE-Boyauderie de L'est`,
				label: `France, Spain, Portugal: BDE-Boyauderie de L'est`
			},
			{ value: `Switzerland: Y GmbH`, label: `Switzerland: Y GmbH` },
			{ value: `Italy: FASSA S.R.L.`, label: `Italy: FASSA S.R.L.` },
			{ value: `Russia: Preppy Consulting LLC`, label: `Russia: Preppy Consulting LLC` },
			{ value: `Thailand: Far East Speciality Co., Ltd.`, label: `Thailand: Far East Speciality Co., Ltd.` },
			{ value: `Korea: Dong Seung Trading Co., Ltd.`, label: `Korea: Dong Seung Trading Co., Ltd.` },
			{ value: `Singapore: Sunrise & Company (Pte) Ltd.`, label: `Singapore: Sunrise & Company (Pte) Ltd.` },
			{ value: `Malaysia: Sunrise-Sports Sdn. Bhd.`, label: `Malaysia: Sunrise-Sports Sdn. Bhd.` },
			{ value: `Indonesia: Sunrise & Company (Pte) Ltd.`, label: `Indonesia: Sunrise & Company (Pte) Ltd.` },
			{ value: `Hong Kong: Sunrise Sports Limited`, label: `Hong Kong: Sunrise Sports Limited` },
			{ value: `India: Sunrise Sport (India) Pvt Ltd.`, label: `India: Sunrise Sport (India) Pvt Ltd.` },
			{ value: `Philippines: Sunrise & Co. Pte Ltd`, label: `Philippines: Sunrise & Co. Pte Ltd` },
			{ value: `Vietnam: SUNRISE SPORTSGEAR VIETNAM LLC`, label: `Vietnam: SUNRISE SPORTSGEAR VIETNAM LLC` },
			{ value: `Myanmar: KAUNG MYAT CO., LTD.`, label: `Myanmar: KAUNG MYAT CO., LTD.` },
			{ value: `Laos: PRO CORPORATE SOLE CO., LTD.`, label: `Laos: PRO CORPORATE SOLE CO., LTD.` },
			{
				value: `Cambodia: Main Vision (Cambodia) Co., Ltd.`,
				label: `Cambodia: Main Vision (Cambodia) Co., Ltd.`
			},
			{ value: `UAE: Al Raed Pioneers`, label: `UAE: Al Raed Pioneers` },
			{ value: `Oman: Modern Business Enterprise LLC`, label: `Oman: Modern Business Enterprise LLC` },
			{
				value: `Kuwait: Professional Sports General Trading Co.`,
				label: `Kuwait: Professional Sports General Trading Co.`
			},
			{
				value: `Saudi Arabia: Nasser Hamdan Al Hamdan Est. (Raed Sport)`,
				label: `Saudi Arabia: Nasser Hamdan Al Hamdan Est. (Raed Sport)`
			},
			{
				value: `Qatar: UNITED COOPERATION GENERAL TRADING WLL`,
				label: `Qatar: UNITED COOPERATION GENERAL TRADING WLL`
			},
			{
				value: `Other Countries in Middle East: YONEX CO., Ltd.`,
				label: `Other Countries in Middle East: YONEX CO., Ltd.`
			},
			{ value: `Australia: Gray-Nicolls Sports Pty Ltd.`, label: `Australia: Gray-Nicolls Sports Pty Ltd.` },
			{ value: `New Zealand: Gray-Nicolls Sports (NZ) Ltd.`, label: `New Zealand: Gray-Nicolls Sports (NZ) Ltd.` }
		]
	},
	{
		value: 'Scouting team',
		label: 'Scouting team',
		nested: false
	}
];

export const SPORTS_OPTIONS = [
	{ label: 'Badminton', value: 'Badminton' }
	// { label: 'Tennis', value: 'Tennis' }
];

export const INTERNAL_DEPARTMENT_OPTIONS = [
	{ label: 'Top management', value: 'Top management' },
	{ label: 'Promotion', value: 'Promotion' },
	{ label: 'International sales', value: 'International sales' },
	{ label: 'Advertisement', value: 'Advertisement' },
	{ label: 'Marketing', value: 'Marketing' }
];

export const INTERNAL_DEPARTMENT_SCOUT_OPTIONS = [{ label: 'Scout', value: 'Scout' }];

export const ACCESS_TYPE_OPTIONS = [
	{ label: 'Level 1', value: 'Level 1' },
	{ label: 'Level 2', value: 'Level 2' },
	{ label: 'Level 3', value: 'Level 3' },
	{ label: 'Level 4', value: 'Level 4' }
];

export const MODULE_OPTIONS = [
	{ label: 'Athlete Management & Database', value: 'Athlete Management & Database' },
	{ label: 'Scouting & Recruiting', value: 'Scouting & Recruiting' },
	{ label: 'Contract Management', value: 'Contract Management' }
];
