const headerEN = {
	home: 'Dashboard',
	playerManagement: 'Athlete Management & Database',
	scoutingRecruiting: 'Scouting & Recruiting',
	contractManagement: 'Contract Management',
	events: 'Events',
	accountSetting: 'Account Setting',
	accountSettingText: 'Your Personal Account Settings',
	notificationsManagement: 'Notifications Management',
	notificationsManagementText: 'Manage Your Notifications',
	accessManagement: 'Access Management',
	inviteToThePlatform: 'Invite to the Platform',
	accessManagementText: 'Manage Access to the Platform',
	helpSupport: 'Help and Support',
	helpSupportText: 'Contact to Tech Support',
	logout: 'Logout',
	athleteRegistration: 'Athlete Registration',
	athleteRegistrationText: 'Add a New player and Monitor Career',
	athleteDatabase: 'Athlete Database',
	athleteDatabaseText: 'Check the Athlete List',
	allAthletes: 'All Athletes',
	allAthletesText: 'The List of All Athletes on Platform',
	scoutingShortlists: 'Scouting Shortlists',
	scoutingShortlistsText: 'Your Prospect Shortlists',
	recruitingDashboard: 'Recruiting Dashboard',
	recruitingDashboardText: 'Recruitment Management Tool'
};

export default headerEN;
