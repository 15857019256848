import { FC, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import useFlowbite from 'shared/hooks/useFlowbite';
import { CloseIcon, DownArrowIcon, StarIcon } from 'shared/icon';
import { IDropdownOptions } from 'shared/interface';
import { debounce } from 'shared/util/utility';

interface IProps {
	name: string;
	title: string;
	options: IDropdownOptions[];
	className?: string;
	errorMsg?: string;
	control: any;
	placeHolder?: string;
	menuPosition?: 'top' | 'bottom';
	disabled?: boolean;
	required?: boolean;
	isSearchable?: boolean;
}

const DropdownSelect: FC<IProps> = ({
	name,
	title,
	options,
	className,
	control,
	errorMsg,
	placeHolder,
	menuPosition,
	disabled,
	required,
	isSearchable
}) => {
	useFlowbite();
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');

	const dropdownRef = useRef<HTMLDivElement | null>(null);

	// Handle outside click
	const handleClickOutside = (event: any) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setDropdownVisible(false);
		}
	};

	useEffect(() => {
		debounce(searchTerm); // Call the debounced function
	}, [searchTerm]);

	useEffect(() => {
		// Add event listener to handle outside clicks
		document.addEventListener('mousedown', handleClickOutside);

		// Cleanup event listener on component unmount
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const toggleSelection = (selectedValue: string, value: string[], onChange: (value: string[]) => void) => {
		if (value.includes(selectedValue)) {
			onChange(value.filter((val) => val !== selectedValue));
		} else {
			onChange([...value, selectedValue]);
		}
	};

	const getLabel = (selectedValues: string[]) => {
		if (!selectedValues) {
			return null;
		}
		return (
			<div className='flex flex-wrap gap-1.5'>
				{selectedValues &&
					selectedValues.map((selected) => {
						const selectedOption = options.find((option) => selected === option.value);
						return (
							<div
								key={selectedOption?.label}
								className='truncate w-16 rounded-full bg-[#f1f3f5] px-1 text-sm-responsive'
							>
								{`${selectedOption?.label}`}
							</div>
						);
					})}
			</div>
		);
	};

	const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()));

	return (
		<div className={`${className || ''} relative w-full`} ref={dropdownRef}>
			<label htmlFor={name} className={`input-label ${disabled ? 'text-themeGray-text' : ''}`}>
				{title}
				{required && (
					<span className='text-xs'>
						<StarIcon height='7px' width='7px' />
					</span>
				)}
			</label>
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, value = [] } }) => {
					return (
						<>
							<button
								disabled={disabled}
								id='dropdownBgHoverButton'
								className={`input-select ${
									disabled && 'cursor-not-allowed'
								} ring-none focus:outline-none focus:ring-4 focus:ring-blue-300 ${
									(!value || value.length <= 0) && 'text-themeGray-text'
								}`}
								type='button'
								color='white'
								onClick={() => setDropdownVisible(!dropdownVisible)}
							>
								{value && value.length > 0 ? getLabel(value) : placeHolder || 'Select'}
								<DownArrowIcon className='size-2.5 stroke-[#344054]' />
							</button>

							<div
								id='dropdown'
								className={`absolute cursor-pointer z-10 w-full divide-y divide-gray-100 rounded-lg bg-white shadow  dark:bg-gray-700 ${
									dropdownVisible ? 'block' : 'hidden'
								}`}
								style={
									menuPosition === 'top'
										? {
												inset: 'auto auto 0px 0px',
												margin: '0px',
												transform: 'translate(0px,-54px)'
										  }
										: {}
								}
							>
								{isSearchable && (
									<div>
										<input
											type='text'
											placeholder='Search...'
											value={searchTerm}
											onChange={(e) => setSearchTerm(e.target.value)}
											className='w-full bg-white border-0 text-textGray text-xs rounded-lg relative'
										/>
										{searchTerm && (
											<button
												type='button'
												className=' z-30 absolute right-[10px] top-3 text-gray-500 hover:text-gray-700 cursor-pointer'
												onClick={(e) => {
													e.stopPropagation();
													setSearchTerm('');
												}}
											>
												<CloseIcon className='w-2 h-2' />
											</button>
										)}
									</div>
								)}
								<ul
									className={`py-2 text-sm-responsive text-gray-700  dark:text-gray-200 max-h-[300px] overflow-auto custom-scrollbar`}
									aria-labelledby='dropdownBgHoverButton'
								>
									{filteredOptions.map((option) => {
										const isSelected = value && value?.includes(option.value);
										return (
											<li key={option.value}>
												<div
													className={`flex items-center px-4 py-2 hover:bg-lightBlue1 dark:hover:bg-gray-600 dark:hover:text-white ${
														isSelected ? 'bg-lightBlue1' : ''
													}`}
												>
													<input
														id={`checkbox-item-${option.value}`}
														type='checkbox'
														checked={isSelected}
														onChange={() => toggleSelection(option.value, value, onChange)}
														className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500'
													/>
													<label
														defaultValue={isSelected}
														htmlFor={`checkbox-item-${option.value}`}
														className='w-full ms-2 text-sm-responsive font-medium text-gray-900 rounded dark:text-gray-300 cursor-pointer'
													>
														{option.label}
													</label>
												</div>
											</li>
										);
									})}
								</ul>
							</div>
						</>
					);
				}}
			/>
			{errorMsg && <p className='text-left text-xs text-red-600 first-letter:uppercase'>{errorMsg}</p>}
		</div>
	);
};

export default DropdownSelect;
