import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Spinner } from 'flowbite-react';
import { isEmpty } from 'lodash';

import TableComponent from 'shared/components/table/table';
import TableBody from 'shared/components/table/tableBody';
import TableCell from 'shared/components/table/tableCell';
import TableHeader from 'shared/components/table/tableHeader';
import TableRow from 'shared/components/table/tableRow';
import { DownArrowIcon } from 'shared/icon';
import ProfilePic from 'shared/components/profilePic';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import EmptyData from 'shared/components/table/emptyData';
import { USER_TABLE_CELL } from '../constant';
import { IUser } from '../interface/interface';

interface IProps {
	setActiveTab: (value: string) => void;
	onEditUser: (userData: IUser) => void;
}

const UserList: React.FC<IProps> = ({ setActiveTab, onEditUser }) => {
	const [userData, setUserData] = useState([]);
	const [loading, setLoading] = useState(true);

	const fetchUserList = async () => {
		try {
			const res = await HttpService.get(API_CONFIG.userList);
			setLoading(false);
			setUserData(res.data);
		} catch (err) {
			setLoading(false);
			console.error(err);
		}
	};

	const getCell = useCallback(
		(key: string, user: IUser) => {
			switch (key) {
				case 'userName':
					return <ProfilePic profileUrl={''} playerName={`${user.name} ${user.last_name}`} />;

				case 'userEmail':
					return (
						<p className='text-textGray truncate' title={user.email}>
							{user.email}
						</p>
					);

				case 'access':
					return (
						<div
							className='flex cursor-pointer items-center justify-center'
							onClick={() => onEditUser(user)}
						>
							<p className='font-medium text-textBlack'>Manage Profile</p>
							<DownArrowIcon className='ml-2 size-2.5 rotate-[270deg] stroke-[#344054]' />
						</div>
					);
				default:
					return <p>-</p>;
			}
		},
		[setActiveTab]
	);

	useEffect(() => {
		fetchUserList();
	}, []);

	return (
		<TableComponent className='rounded-xl border'>
			<TableHeader
				tableCellList={USER_TABLE_CELL}
				className='sticky top-0 z-[1] table w-full table-fixed bg-white first:text-left'
			/>
			<TableBody className='custom-scrollbar block h-[calc(100vh-750px)] min-h-[38vh] overflow-y-auto border-none'>
				{!loading &&
					userData.map((user: IUser) => {
						return (
							<Fragment key={user.id}>
								<TableRow className='table w-full table-fixed tr-left-border'>
									{USER_TABLE_CELL.map((cell) => (
										<TableCell key={cell.title}>{getCell(cell.key, user)}</TableCell>
									))}
								</TableRow>
							</Fragment>
						);
					})}
				{!loading && isEmpty(userData) && <EmptyData className='h-full' />}
				{loading && isEmpty(userData) && (
					<div className='flex justify-center h-full items-center'>
						<Spinner size={'xl'} />
					</div>
				)}
			</TableBody>
		</TableComponent>
	);
};
export default UserList;
