import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import ErrorHandler from 'hoc/withErrorHandler/errorHandler';
import ErrorBoundary from 'shared/components/errorBoundary/errorBoundary';
import AuthStore from 'store/userDetailStore';
// import AthleteStore from 'store/athleteStore';

import './shared/localization/localization';
import App from './app';
import store from './store';

const Root: React.FC = (props) => {
	return (
		<Provider store={store}>
			<AuthStore>
				<ErrorHandler />
				<ErrorBoundary>
					<div className='app-main'>
						<BrowserRouter>
							<App {...props} />
						</BrowserRouter>
					</div>
				</ErrorBoundary>
			</AuthStore>
		</Provider>
	);
};

export default Root;
