import React from 'react';

import { IShortlists } from 'features/shortlist/interface';
import InformationModal from 'shared/components/modal/informationModal';
import ProfilePic from 'shared/components/profilePic';

interface IProps {
	show: boolean;
	closeModal: () => void;
	data: IShortlists;
}

const ViewEditorModal: React.FC<IProps> = (props) => {
	const { show, closeModal, data } = props;
	return (
		<InformationModal headerTile={`${data.name} Editors`} show={show} onClose={closeModal} size={'sm'}>
			<div className='flex flex-col gap-3'>
				{data.shortlist_editors.map((editor) => {
					return (
						<div
							key={editor.id}
							className='pb-2 border-b flex items-start justify-start border-themeGray w-full last:border-none'
						>
							<ProfilePic
								profileUrl={editor.profile_picture}
								playerName={`${editor.name} ${editor.last_name}`}
							/>
						</div>
					);
				})}
			</div>
		</InformationModal>
	);
};
export default ViewEditorModal;
