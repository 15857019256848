import React from 'react';
import { isEmpty, isNull } from 'lodash';

import ProfilePic from 'shared/components/profilePic';
import { formatDate } from 'shared/util/utility';
import { ICompareAthlete } from 'features/athleteDatabase/interface';
import Gender from 'shared/components/gender';

interface IProps {
	player: ICompareAthlete;
}

const CompareCard: React.FC<IProps> = ({ player }) => {
	return (
		<>
			{!isEmpty(player) && (
				<div className='m-1 flex w-[428px] flex-col rounded-xl border bg-[#F5F9FD] px-5 py-2 shadow-md '>
					<div className='flex justify-between border-b border-themeGray pb-4'>
						<div className='flex'>
							<ProfilePic profileUrl={player.profile_picture} />
							<div className='flex flex-col ml-2'>
								<p className='text-sm font-medium text-[#101828] '>
									{player.first_name} {player.last_name}
								</p>
								<div className='flex items-center'>
									<p className='border-r border-themeGray pr-2 text-base-responsive'>
										{player.country.emoji} {player.country.iso3}
									</p>
									<p className='ml-2'>{player.sports}</p>
									<Gender data={player.gender} className='ml-2' />
								</div>
							</div>
						</div>
						<div className='flex flex-col'>
							<p className='text-sm font-medium text-[#101828]'>OPS</p>
							<p className='text-primary font-bold text-lg'>{Math.floor(+player.performance.current)}</p>
						</div>
					</div>
					<div className='mt-4 flex items-center justify-between border-b border-themeGray pb-4'>
						<div className='flex flex-col'>
							<p className='text-xs text-textGray'>Athlete ID</p>
							<p className='text-sm font-semibold text-textGray'>{player.athlete_id || '-'}</p>
						</div>
						<div className='flex flex-col'>
							<p className='text-xs text-textGray'>Circuit Lvl.</p>
							<p className='text-sm font-semibold text-textGray'>{player.circuit_level || '-'}</p>
						</div>
						<div className='flex flex-col'>
							<p className='text-xs text-textGray'>Status</p>
							<p className='text-sm font-semibold text-textGray'>{player.status || '-'}</p>
						</div>
						<div className='flex flex-col'>
							<p className='text-xs text-textGray'>Category</p>
							<p className='text-sm font-semibold text-textGray'>{player.category1 || '-'}</p>
						</div>
					</div>
					<div className='mt-4 flex items-center justify-between pb-2'>
						<div className='flex flex-col'>
							<p className='text-xs text-textGray'>Marketability C.</p>
							<p className='text-sm font-semibold text-textGray'>{player.marketability_current || '-'}</p>
						</div>
						<div className='flex flex-col'>
							<p className='text-xs text-textGray'>Performance Profile</p>
							<p className='text-sm font-semibold text-textGray'>
								{(!isNull(player.team_yonex_future_star_level) &&
									player.team_yonex_future_star_level) ||
									'-'}
							</p>
						</div>
						<div className='flex flex-col'>
							<p className='text-xs text-textGray'>Exp. date</p>
							<p className='text-sm font-semibold text-textGray'>
								{formatDate(player.contract_retainer_term_end)}
							</p>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default CompareCard;
