import { FC } from 'react';
import ProgressBar from './progressBar';

const ScoreCard: FC<{ title: string; score: number; isSmall: boolean }> = ({ title, score, isSmall }) => {
	return (
		<div className='relative xl:w-[47%] w-96'>
			<div className='flex items-start justify-between'>
				<p
					className={`mt-4 2xl:mt-2  text-left ${
						isSmall ? 'text-sm-responsive' : 'text-base-responsive'
					} font-semibold w-full max-w-[120px]`}
				>
					{title ?? '-'}
				</p>
				<ProgressBar score={score} />
			</div>
			<hr className='absolute top-16 w-full' />
		</div>
	);
};

export default ScoreCard;
