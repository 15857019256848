import React, { PropsWithChildren } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'flowbite-react';

interface IProps extends PropsWithChildren {
	fetchData: () => void;
	hasMore: boolean;
	endMessage?: boolean;
	totalData: number;
	height?: string;
	className?: string;
}

const InfiniteScrollComponent: React.FC<IProps> = (props) => {
	const { fetchData, hasMore, children, endMessage, totalData, height, className } = props;
	return (
		<InfiniteScroll
			dataLength={totalData}
			hasMore={hasMore}
			next={fetchData}
			loader={
				<div className='flex justify-center'>
					<Spinner />
				</div>
			}
			endMessage={<p className='mt--15 text--center font--bold font-size--lg'>{endMessage}</p>}
			height={height || '100%'}
			className={`${className}`}
			scrollThreshold={1}
			scrollableTarget='scrollableDiv'
		>
			{children}
		</InfiniteScroll>
	);
};

export default InfiniteScrollComponent;
