import React from 'react';
import { Label, Radio } from 'flowbite-react';
import { UseFormRegister } from 'react-hook-form';

interface IProps {
	label: string;
	name: string;
	value: string;
	globalValue: string;
	onChange: (value: string) => void;
	register: UseFormRegister<any>;
	errorMsg?: string;
	isSize?: boolean;
	labelClassName?: string;
}

const RadioButton: React.FC<IProps> = ({
	name,
	value,
	globalValue,
	onChange,
	register,
	label,
	errorMsg,
	isSize,
	labelClassName
}) => {
	// Generate a unique ID for each radio button based on name and value
	const radioId = `${name}-${value}`;

	return (
		<>
			<div className='flex items-start gap-2 cursor-pointer'>
				<Radio
					id={radioId}
					value={value}
					{...register(name)}
					theme={{
						root: {
							base: `${
								isSize ? 'h-3.5 w-3.5' : 'lg:h-5 lg:w-5 h-3.5 w-3.5 text-primary '
							} border border-gray-300 cursor-pointer`
						}
					}}
					onChange={(event) => {
						onChange(event.target.value);
					}}
				/>
				<Label htmlFor={radioId}>
					<div className='flex flex-col cursor-pointer'>
						<p
							className={`${isSize ? 'text-xs ' : 'text-base-responsive'} text-[#344054] ${
								labelClassName ? labelClassName : 'font-semibold'
							} `}
						>
							{label}
						</p>
						<p className={`${isSize ? 'text-xs' : 'text-base-responsive'} text-textGray`}>{globalValue}</p>
					</div>
				</Label>
			</div>
			{errorMsg && <p className='text-left text-xs text-red-600 first-letter:uppercase'>{errorMsg}</p>}
		</>
	);
};

export default RadioButton;
