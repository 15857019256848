import React from 'react';

interface IProps {}

const QuickAddLevelTable: React.FC<IProps> = () => {
	return (
		<div>
			<div className='flex justify-center my-8'>
				<div className='max-w-4xl w-full'>
					{levelsData.map((data, index) => {
						const { titleClass, title, range, description, levels } = data;
						return (
							<div className='flex border border-gray-300' key={index}>
								<div
									className={`p-4 text-center w-[252px] flex-1 flex flex-col justify-center ${titleClass}`}
								>
									<p>{title}</p>
									<p className='mt-2 font-bold text-lg'>{range}</p>
								</div>
								<div className='flex-1 text-left w-[252px] p-4 border-r border-gray-300'>
									{levels.map((level, index) => (
										<p key={index} className='text-base font-normal '>
											{level}
										</p>
									))}
								</div>
								<div className='text-center flex-1 flex flex-col justify-center w-[252px] p-4  text-base font-normal'>
									{description}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default QuickAddLevelTable;

const levelsData = [
	{
		title: 'World Class Level',
		range: '16-20',
		levels: ['20 - Extraordinary Level', '19', '18 - World Top Level', '17', '16'],
		description: 'Consistent grading globally e.g. 18 is the same for all Athletes',
		titleClass: 'bg-green-custom-gradient'
	},
	{
		title: 'Continental Level',
		range: '10-15',
		levels: ['15 - Continental Top Level', '14', '13', '12 - Continental Basic Level', '11', '10'],
		description: 'Relevant to the continent e.g. Asia 12 will be different to Europe and N.America’s 12',
		titleClass: 'bg-yellow-custom-gradient'
	},
	{
		title: 'National Level',
		range: '5-9',
		levels: ['9 - National Top Level', '8', '7', '6 - Basic National Level', '5'],
		description: 'Relevant to the market e.g. France’s Level 9 will be different to Spain’s Level 9',
		titleClass: 'bg-red-custom-gradient'
	}
];
