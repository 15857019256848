import { ToggleSwitch } from 'flowbite-react';
import React, { useCallback } from 'react';
import { get } from 'lodash';
import {
	Control,
	FieldErrors,
	UseFormGetValues,
	UseFormRegister,
	UseFormSetValue,
	UseFormTrigger
} from 'react-hook-form';

import Select from 'shared/form/select/select';
import { Input } from 'shared/form/input';
import { IQuickAddValues } from 'features/athleteDatabase/interface';
import {
	FUTURE_LEVEL_OPTIONS,
	LEGENDS_OPTIONS,
	POTENTIAL_GRADE_OPTIONS,
	SENIOR_STAR_OPTIONS
} from 'features/athleteDatabase/constant';

interface IProps {
	control: Control<IQuickAddValues>;
	register: UseFormRegister<IQuickAddValues>;
	errors: FieldErrors<IQuickAddValues>;
	setValue: UseFormSetValue<IQuickAddValues>;
	getValues: UseFormGetValues<any>;
	watch: any;
	trigger: UseFormTrigger<IQuickAddValues>;
}

const QuickAddPerformanceProfile: React.FC<IProps> = ({ control, register, errors, setValue, watch, trigger }) => {
	const getErrorMsg = useCallback(
		(key: string) => {
			return get(errors, `${key}.message`, '') as string;
		},
		[errors]
	);
	const isToggle = watch('performance_profile.performance_toggle');

	const handleToggleChange = (checked: boolean) => {
		setValue('performance_profile.performance_toggle', checked);
		trigger('performance_profile');
	};

	return (
		<div>
			<div className='flex gap-2'>
				<p className='text-sm font-medium text-[#344054]'>Junior</p>
				<ToggleSwitch
					theme={Theme}
					{...register('performance_profile.performance_toggle')}
					onChange={handleToggleChange}
					checked={watch('performance_profile.performance_toggle')}
					name='performance_toggle'
				/>
				<p className='text-sm font-medium text-[#344054]'>Senior</p>
			</div>
			<div className='grid grid-cols-3 gap-x-4 gap-y-2 mt-6'>
				{!isToggle && (
					<>
						<Select
							placeHolder='Select Type'
							control={control}
							name='performance_profile.team_yonex_future_star_level'
							title='Future Star Level'
							options={FUTURE_LEVEL_OPTIONS}
							errorMsg={getErrorMsg('performance_profile.team_yonex_future_star_level')}
							required
						/>
						<Input
							title={'Training Age'}
							type='number'
							name='performance_profile.training_age'
							errorMsg={errors?.performance_profile?.training_age?.message ?? ''}
							placeholder='Type Age'
							register={register}
							className='!min-h-[0px]'
						/>
						<Select
							placeHolder='Select Grade'
							control={control}
							name='performance_profile.potential_grade'
							title='Potential Grade'
							options={POTENTIAL_GRADE_OPTIONS}
							required
							errorMsg={getErrorMsg('performance_profile.potential_grade')}
						/>
					</>
				)}

				{isToggle && (
					<>
						<Select
							placeHolder='Select type'
							control={control}
							name='performance_profile.team_yonex_senior_level'
							title='Senior Level'
							options={SENIOR_STAR_OPTIONS}
							errorMsg={''}
						/>
						<Select
							placeHolder='Select type'
							control={control}
							name='performance_profile.legends_vision'
							title='Legend’s Vision'
							options={LEGENDS_OPTIONS}
							errorMsg={''}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default QuickAddPerformanceProfile;
const Theme = {
	root: {
		base: 'group flex rounded-xl focus:outline-none',
		active: {
			on: 'cursor-pointer',
			off: 'cursor-not-allowed opacity-50'
		},
		label: 'ms-3 mt-0.5 text-start text-sm font-medium text-gray-900 dark:text-gray-300'
	},
	toggle: {
		base: 'relative rounded-full border after:absolute after:rounded-full after:bg-white after:transition-all',
		checked: {
			on: 'after:translate-x-full after:border-white rtl:after:-translate-x-full',
			off: 'bg-[#1D9533] border border-[#1D9533]',
			color: {
				blue: 'border-[#0057B7] bg-[#0057B7]'
			}
		}
	}
};
