import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';

import YonexLogo from 'assets/images/logo.png';
import useFlowbite from 'shared/hooks/useFlowbite';

import { MenuIcon, NotificationIcon } from 'shared/icon';

import Menu from './component/menu';
import Profile from './component/profile';
import Navigation from './component/navigation';
import { useNavigate } from 'react-router-dom';
import useModal from 'shared/hooks/useModal';
import Notification from './component/notification';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import { INotification } from './interface/interface';

const Header = () => {
	useFlowbite();
	const { t } = useTranslation(['header', 'common']);

	const [notification, setNotification] = useState([]);
	const [loading, setLoading] = useState(false);
	const [notificationCount, setNotificationCount] = useState(0);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const { isOpen, type, closeModal, openModal } = useModal();
	const navigate = useNavigate();

	const getNotification = async () => {
		setLoading(true);
		try {
			const res = await HttpService.get(`${API_CONFIG.notification}/currently-logged-in-user`);
			setLoading(false);
			if (res?.data?.data) {
				const unreadCount = res.data.data.filter((notification: INotification) => !notification.is_read).length;
				setNotificationCount(unreadCount);
				setNotification(res.data.data);
			}
		} catch (err) {
			setLoading(false);

			console.error(err);
		}
	};

	useEffect(() => {
		getNotification();
		const interval = setInterval(() => {
			if (type === 'notification' && isOpen) {
				return;
			}

			getNotification();
		}, 30000); // 30,000 milliseconds = 30 seconds
		return () => clearInterval(interval);
	}, [type, isOpen]);

	const isNotification = useMemo(() => isOpen && type === 'notification', [isOpen, type]);

	return (
		<nav className='layout-container border-gray-200 bg-white  py-4 dark:bg-gray-900'>
			<div className='flex max-h-[40px] w-full flex-wrap items-center justify-between '>
				<img
					src={YonexLogo}
					className='h-8 cursor-pointer'
					alt='Yonex Logo'
					onClick={() => navigate('/dashboard')}
				/>

				<div className='flex flex-row-reverse items-center justify-between space-x-1 lg:order-2 lg:w-[unset] lg:flex-row lg:flex-wrap lg:space-x-2 rtl:space-x-reverse'>
					<div className='flex'>
						<div
							className={`mr-3 flex size-10 cursor-pointer items-center justify-center lg:mr-2 relative rounded-full  ${
								isNotification ? 'bg-gray-200' : ''
							}`}
							onClick={() => openModal('notification')}
						>
							<NotificationIcon />
							{Number(notificationCount) > 0 && (
								<p className='absolute text-[10px] text-white bg-red-800 rounded-full flex justify-center items-center w-[15px] h-[15px] top-[3px] right-[8px]'>
									<span>{notificationCount}</span>
								</p>
							)}
						</div>
						<button
							data-collapse-toggle='mega-menu-icons'
							type='button'
							className='inline-flex items-center justify-center rounded-lg p-2 text-sm text-gray-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 lg:hidden'
							aria-controls='mega-menu-icons'
							aria-expanded='false'
						>
							<p className='mr-2'>{t('common:menu')}</p>
							<MenuIcon />
						</button>
					</div>
					<Profile openMenu={() => setIsMenuOpen(true)} />
				</div>
				<Navigation />
				<Menu isMenuOpen={isMenuOpen} closeMenu={() => setIsMenuOpen(false)} />
			</div>

			{isNotification && (
				<Notification
					handleCloseModal={closeModal}
					loading={loading}
					notification={notification as INotification[]}
					getNotification={getNotification}
				/>
			)}
		</nav>
	);
};

export default Header;
