import React, { Fragment, useEffect, useState } from 'react';
import { Modal } from 'flowbite-react';
import { isEmpty } from 'lodash';

import ProfilePic from 'shared/components/profilePic';
import TableComponent from 'shared/components/table/table';
import TableBody from 'shared/components/table/tableBody';
import TableCell from 'shared/components/table/tableCell';
import TableHeader from 'shared/components/table/tableHeader';
import TableRow from 'shared/components/table/tableRow';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import TableSpinner from 'shared/components/table/tableSpinner';
import { IKpi, IPerformanceMatrix } from '../interface';
import EmptyData from 'shared/components/table/emptyData';
import { PLAYER_KPI } from '../constant';

interface IProps {
	className?: string;
	show: boolean;
	onClose: () => void;
}

const PlayerMatrix: React.FC<IProps> = (props) => {
	const { className, show, onClose } = props;

	const [matrixData, setMatrixData] = useState([]);
	const [loading, setLoading] = useState(false);

	const getCell = (key: string, record: IPerformanceMatrix) => {
		const findKpiValue = (group: string) => {
			const kpi = record.kpis.find((kpi: IKpi) => kpi.kpi_group === group);
			return kpi ? kpi.avg : '-';
		};
		switch (key) {
			case 'athleteName':
				return (
					<div className='flex items-center ml-2'>
						<ProfilePic
							profileUrl={record.profile_picture}
							playerName={`${record.first_name} ${record.last_name}`}
						/>
					</div>
				);
			case 'technical':
				return <p className='text-center'>{findKpiValue(PLAYER_KPI.TECHNICAL)}</p>;
			case 'footwork':
				return <p className='text-center'>{findKpiValue(PLAYER_KPI.FOOTWORK)}</p>;
			case 'tactical':
				return <p className='text-center'>{findKpiValue(PLAYER_KPI.TACTICAL)}</p>;
			case 'psychological':
				return <p className='text-center'>{findKpiValue(PLAYER_KPI.PSYCHOLOGICAL)}</p>;
			case 'physical':
				return <p className='text-center'>{findKpiValue(PLAYER_KPI.PHYSICAL)}</p>;
			case 'dailyLifecycle':
				return <p className='text-center'>{findKpiValue(PLAYER_KPI.DAILY_PRACTICE_OR_LIFESTYLE)}</p>;
			case 'perform':
				return <p className='text-center'>{findKpiValue(PLAYER_KPI.PERFORMANCE_ENVIRONMENT)}</p>;
			default:
				return <p>-</p>;
		}
	};

	const getPerformanceMatrix = async () => {
		setLoading(true);
		try {
			const res = await HttpService.get(`${API_CONFIG.player}/performance-matrix`);
			setLoading(false);
			setMatrixData(res.data);
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	useEffect(() => {
		getPerformanceMatrix();
	}, []);

	return (
		<Modal className={`${className || ''}`} show={show} onClose={onClose} theme={Theme}>
			<Modal.Header className='rounded-t-lg border-b-2 border-primary bg-lightBlue'>
				<div>
					<p className='header-txt'>Athlete Performance Matrix</p>
				</div>
			</Modal.Header>
			<Modal.Body>
				<TableComponent className='rounded-xl border'>
					<TableHeader
						tableCellList={PLAYER_TABLE_CELL}
						className='sticky top-0 z-[1] table w-full table-fixed bg-white first:text-left'
					/>
					<TableBody className='custom-scrollbar block h-[calc(100vh-750px)] min-h-[57vh] overflow-y-auto border-none'>
						{loading && (
							<div className='flex justify-center items-center h-full'>
								<TableSpinner colSpan={PLAYER_TABLE_CELL.length} />
							</div>
						)}
						{!loading && !isEmpty(matrixData) && matrixData.length > 0 && (
							<>
								{matrixData.map((data: IPerformanceMatrix) => {
									return (
										<Fragment key={data.player_id}>
											<TableRow className='table w-full table-fixed tr-left-border'>
												{PLAYER_TABLE_CELL.map((cell) => (
													<TableCell key={cell.title} className='border-b'>
														{getCell(cell.key, data)}
													</TableCell>
												))}
											</TableRow>
										</Fragment>
									);
								})}
							</>
						)}
						{!loading && isEmpty(matrixData) && matrixData.length <= 0 && <EmptyData className='h-full' />}
					</TableBody>
				</TableComponent>
			</Modal.Body>
		</Modal>
	);
};
export default PlayerMatrix;

const PLAYER_TABLE_CELL = [
	{ title: 'Athlete name', key: 'athleteName' },
	{ title: 'Technical', key: 'technical' },
	{ title: 'Footwork', key: 'footwork' },
	{ title: 'Tactical', key: 'tactical' },
	{ title: 'Psychological', key: 'psychological' },
	{ title: 'Physical', key: 'physical' },
	{ title: 'Daily / Lifecycle', key: 'dailyLifecycle' },
	{ title: 'perform. envi.', key: 'perform' }
];

const Theme = {
	root: {
		show: {
			on: 'flex bg-[#0C111D] bg-opacity-50 backdrop-blur-sm dark:bg-opacity-80'
		},
		sizes: {
			'2xl': 'none'
		}
	},
	content: {
		base: 'relative h-full p-4 md:h-auto w-full max-w-[1320px]'
	},
	body: {
		base: 'flex-1 p-6 overflow-auto custom-scrollbar'
	}
};
