import React, { useCallback } from 'react';
import { Control, FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { AthleteFormValues } from 'features/addPlayer/interface';
import Select from 'shared/form/select/select';
import { Input } from 'shared/form/input';
import { DateRange } from 'shared/form/datepicker/dateRange';
import moment from 'moment';
import { get } from 'lodash';

interface IProps {
	register: UseFormRegister<AthleteFormValues>;
	control: Control<AthleteFormValues, any>;
	errors: FieldErrors<any>;
	setValue: UseFormSetValue<AthleteFormValues>;
	isFormSubmit: boolean;
	getValues: any;
}

const ContractInfo: React.FC<IProps> = (props) => {
	const { control, register, setValue, isFormSubmit, errors, getValues } = props;

	const getErrorMsg = useCallback(
		(key: string) => {
			return get(errors, `${key}.message`, '') as string;
		},
		[errors]
	);

	return (
		<>
			<p className='title-text mt-5'>Contract Information</p>
			<div className='grid grid-cols-3 gap-x-4 gap-y-2'>
				<Select
					placeHolder='Select Contract Type'
					control={control}
					name='contract_information.type'
					title='Contract Type'
					options={CONTRACT_TYPE_OPTIONS}
				/>
				<div className='flex items-center justify-center'>
					<Input
						className='w-full'
						register={register}
						title={'Retainer'}
						name='contract_information.retainer_amount'
						placeholder='Enter Amount'
						type='number'
					/>
					<Select
						className='w-32'
						placeHolder='Select Currency'
						control={control}
						name='contract_information.retainer_currency'
						title=''
						options={RETAINER_CURRENCY}
					/>
				</div>

				<DateRange
					onCancel={() => {
						setValue('contract_information.retainer_term_start', null);
						setValue('contract_information.retainer_term_end', null);
					}}
					title='Start and End Date'
					onChange={(Range) => {
						setValue('contract_information.retainer_term_start', moment(Range.startDate).format());
						setValue('contract_information.retainer_term_end', moment(Range.endDate).format());
					}}
					isFormSubmit={isFormSubmit}
					dateRange={{
						startDate: getValues('contract_information.retainer_term_start') || '',
						endDate: getValues('contract_information.retainer_term_end') || ''
					}}
					errorMsg={getErrorMsg('contract_information.retainer_term_start')}
				/>
			</div>
		</>
	);
};

export default ContractInfo;

export const CONTRACT_TYPE_OPTIONS = [
	{ label: 'No Contract', value: 'No Contract' },
	{ label: 'Head to Toe', value: 'Head to Toe' },
	{ label: 'Racquet', value: 'Racquet' },
	{ label: 'Expired', value: 'Expired' }
];

export const RETAINER_CURRENCY = [
	{ label: 'JPY', value: 'JPY' },
	{ label: 'USD', value: 'USD' },
	{ label: 'EUR', value: 'EUR' }
];
