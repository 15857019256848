import { LEVELS, ROLES } from 'shared/components/permissions/constants';

export interface IResponseObject<T> {
	is_error: boolean;
	message: string;
	data: T;
}

export interface IListResponse<T> {
	count: number;
	pageCount: number;
	data: T;
}

export interface ISortData {
	order_by?: string;
	sort_by?: OrderType | string;
}

export enum OrderType {
	ASC = 'ASC',
	DESC = 'DESC'
}

export interface IPage {
	pageNumber: number;
	pageSize: number;
}

export interface IDropdownOptions {
	label: string;
	value: string;
	nested?: boolean;
	nestedOptions?: IDropdownOptions[];
	id?: string | number;
	disabled?: boolean;
}

export interface IUserData {
	id: string;
	name: string;
	last_name: string;
	email: string;
	phone_number: string;
	country_code: string;
	token: string;
	department: IDepartment;
	coworker_title: string;
	invitation_jwt: any;
	password_jwt: any;
	forgot_password_jwt: any;
	access_type: LEVELS;
	internal_department: string;
	department_group: string;
	sports: string;
	user_link_modules: ROLES[];
	profile_picture: string;
}

export interface IDepartment {
	id: number;
	name: string;
	department_group: string;
}

export type AnyObject = {
	[key: string]: any;
};
