import React from 'react';
import { NextEdgeLogo } from 'shared/icon';

const Footer: React.FC = () => {
	return (
		<div className='layout-container border-gray-200 bg-white py-1 flex items-center justify-between'>
			<p className='text-[10px] font-normal text-textGray'>© 2012-2024 YONEX Co., Ltd.</p>
			<div className='flex items-center gap-3'>
				<p className='text-[10px] font-normal text-textGray'>Powered By</p>
				<NextEdgeLogo />
			</div>
		</div>
	);
};

export default Footer;
