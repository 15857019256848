import { ColorOptions, ThemesOptions } from 'features/shortlist/constant';
import { ButtonTheme } from 'shared/components/buttons/primary';

export interface IFormValues {
	name: string;
	editor_ids: string[];
	theme: string;
	color_code: string;
}

export const DashboardDefaultValues: IFormValues = {
	name: '',
	editor_ids: [],
	theme: ThemesOptions[0]?.themeName || '',
	color_code: ColorOptions[0]?.label || ''
};

export enum ScreenType {
	SELECTION_ATHLETE = 'Athlete Selection',
	RECRUITING_DASHBOARD = 'Recruiting Dashboard',
	DASHBOARD_DETAILS = 'Dashboard Details'
}

export enum ScoutingModal {
	CREATE_RECRUITING = 'Create',
	EDIT_RECRUITING = 'Edit',
	VIEW_CREATED_RECRUITING = 'View created recruiting',
	DELETE_RECRUITING = 'Delete',
	VIEW_EDITOR = 'View Editor',
	ADD_TO_DASHBOARD_CONFIRM = 'Add To Dashboard'
}

export const buttonTheme = {
	size: {
		...ButtonTheme.size
	}
};

export const Select_Athlete_Table_Cells = [
	{ title: 'Athlete name', key: 'playerName', style: { width: '250px' } },
	{ title: 'Country', key: 'country', shortKey: 'Country', sortable: true, style: { width: '50px' } },
	{ title: 'Gender', key: 'gender', style: { width: '50px' } },
	{ title: 'Circuit lvl.', key: 'circuitLvl', style: { width: '190px' } },
	{ title: 'Potential Grade', key: 'potential_grade', style: { width: '100px' } },
	{
		title: 'OPS',
		key: 'perform',
		shortKey: 'Performance',
		sortable: true,
		style: { width: '150px' },
		tooltip: true,
		tooltipText: 'Overall Performance Score'
	},
	{ title: 'Status', key: 'status', style: { width: '150px' } },
	{ title: 'Profile Creator', key: 'profile_creator', style: { width: '150px' } },
	{ title: 'Editor', key: 'editors', style: { width: '190px' } },
	{ title: '', key: 'action', style: { width: '50px' } }
];

export const Dashboard_Table_Cells = [
	{ title: 'Dashboard Name', key: 'dashboard_name', style: { width: '150px' } },
	{ title: 'Average OPS', key: 'avg_ops', shortKey: 'Performance', sortable: true, style: { width: '150px' } },
	{ title: 'No. of Athletes ', key: 'no_of_athletes', style: { width: '150px' } },
	{ title: 'Created by', key: 'created_by', style: { width: '150px' } },
	{ title: 'Creation date', key: 'creation_date', style: { width: '150px' } },
	{
		title: 'Editor',
		key: 'editor',
		style: { width: '150px' }
	},
	{ title: '', key: 'action', style: { width: '50px' } }
];

export const DASHBOARD_FILTER = [
	{ label: 'My Board', value: 'My Recruiting Board' },
	{ label: 'Yonex Board', value: 'Yonex Recruiting Board' }
];

export const enum DragKey {
	RECRUITING_DRAGGING = 'RECRUITING_DRAGGING'
}

export const RECRUITING_SHORTLIST_BUTTON = [
	{ label: 'Shortlist', value: 'Shortlist' },
	{ label: 'All Athletes', value: 'All Athletes' }
];

export enum RECRUITING_SHORTLIST_TAB_NAME {
	SHORTLIST = 'Shortlist',
	ALL_ATHLETES = 'All Athletes'
}
