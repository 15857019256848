import React from 'react';

import { IPlayerDetails } from 'features/playerProfile/interface';
import {
	ExpandInstagramIcon,
	FacebookIcon,
	TikTokIcon,
	WeiboIcon,
	XIcon,
	YonexLogoWithSvg,
	YouTubeIcon
} from 'shared/icon';
import { ViewType } from '../interface';

interface IProps {
	playerDetails: IPlayerDetails;
	viewType: string;
}

const SocialMediaExpand: React.FC<IProps> = ({ playerDetails, viewType }) => {
	return (
		<div
			className={`ml-5 ${
				viewType === ViewType.Table_View ? 'w-[500px]' : 'lg:w-[300px] xl:w-[400px] 2xl:w-[470px]'
			} max-h-[320px] rounded-xl border border-themeGray bg-lightBlue p-2 lg:p-5 custom-scrollbar overflow-auto`}
		>
			<p className='text-textBlack font-semibold text-sm text-left mb-3'>Social Media Data</p>
			<div className='grid grid-cols-2 gap-3 mb-4'>
				{playerDetails && playerDetails.social_medias.length > 0 ? (
					playerDetails.social_medias.map((data) => {
						const socialIcon = SOCIAL_ICONS.find((icon) => icon.name === data.name);
						return (
							<div className='flex bg-white border-themeGray rounded-lg border shadow-xs' key={data.name}>
								<div className={`${socialIcon ? socialIcon.className : ''} rounded-l-lg mr-3`}>
									{socialIcon ? (
										socialIcon.icon
									) : (
										<YonexLogoWithSvg className='rounded-l-lg' width='46' height='46' />
									)}
								</div>
								<div className='flex flex-col items-start justify-center'>
									<p className='text-textBlack text-sm font-semibold'>{data.follower_count ?? '-'}</p>
									<p className='text-textGray font-medium text-xs'>Followers</p>
								</div>
							</div>
						);
					})
				) : (
					<p className='text-sm-responsive text-themeGray-text'>No Social Media data available</p>
				)}
			</div>
			<div className='w-full flex justify-between pb-4'>
				<div className='rounded-lg bg-white  w-[49%] px-1 lg:px-4 py-2.5'>
					<p className=' text-sm-responsive font-semibold  text-primary text-center '>
						Marketability (Current)
					</p>
					<div className='bg-white rounded-lg '>
						<p className='text-sm-responsive font-semibold  text-[#344054] text-center'>
							{playerDetails?.marketability_current?.split(' ')[0] ?? '-'}
						</p>
						<p className='text-sm-responsive  text-center font-bold text-textGray'>
							{playerDetails?.marketability_current?.split(' ')[1]}
						</p>
					</div>
				</div>
				<div className='rounded-lg bg-white  w-[49%] px-1 lg:px-4 py-2.5'>
					<p className=' text-sm-responsive font-semibold  text-primary text-center '>
						Marketability (Potential)
					</p>
					<div className='bg-white rounded-lg '>
						<p className='text-sm-responsive font-semibold  text-[#344054] text-center'>
							{playerDetails?.marketability_potential?.split(' ')[0] ?? '-'}
						</p>
						<p className='text-sm-responsive text-center font-bold text-textGray'>
							{playerDetails?.marketability_potential?.split(' ')[1]}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SocialMediaExpand;

export const SOCIAL_ICONS = [
	{
		title: 'Facebook',
		name: 'facebook',
		icon: <FacebookIcon height='46' width='46' />,
		className: 'bg-[#1877F2]'
	},
	{
		title: 'Instagram',
		name: 'instagram',
		icon: <ExpandInstagramIcon />
	},
	{
		title: 'TikTok',
		name: 'tikTok',
		icon: <TikTokIcon height='46' width='46' fill='white' />,
		className: 'bg-black'
	},
	{
		title: 'X',
		name: 'x',
		icon: <XIcon height='46' width='46' />,
		className: 'bg-black'
	},
	{
		title: 'Weibo',
		name: 'weibo',
		icon: <WeiboIcon height='46' width='46' />,
		className: 'bg-[#e6162d]'
	},
	{
		title: 'YouTube',
		name: 'youTube',
		icon: <YouTubeIcon height='46' width='46' />,
		className: 'bg-[#FF0000]'
	}
];
