import { Datepicker } from 'flowbite-react';
import moment from 'moment';
import React from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { CloseIcon, StarIcon } from 'shared/icon';

interface IProps {
	setValue: UseFormSetValue<any>;
	name: string;
	className?: string;
	title?: string;
	placeHolder?: string;
	control: Control<any>;
	errorMsg?: string;
	required?: boolean;
	isYear?: boolean;
	isBottomPosition?: boolean;
}

const DateInput: React.FC<IProps> = (props) => {
	const { name, setValue, className, title, placeHolder, control, errorMsg, required, isYear, isBottomPosition } =
		props;

	const currentYear = new Date().getFullYear();

	const DateTheme = {
		popup: {
			root: {
				base: `${isBottomPosition ? 'bottom-12' : 'top-10'} absolute z-50 block pt-2`,
				inner: 'inline-block rounded-lg bg-white p-1 shadow-lg dark:bg-gray-700'
			},
			base: 'absolute bottom-full mb-2',
			header: {
				selectors: {
					base: 'mb-2 flex justify-between',
					button: {
						base: 'rounded-lg bg-white px-5 text-xs lg:text-xs 2xl:text-sm font-semibold text-gray-900'
					}
				}
			}
		},
		views: {
			days: {
				items: {
					item: {
						selected: 'bg-primary text-white hover:bg-primary-light'
					}
				}
			},
			months: {
				items: {
					item: {
						selected: 'bg-primary text-white hover:bg-primary-light'
					}
				}
			},
			years: {
				items: {
					item: {
						selected: 'bg-primary text-white hover:bg-primary-light'
					}
				}
			},
			decades: {
				items: {
					item: {
						selected: 'bg-primary text-white hover:bg-primary-light'
					}
				}
			}
		}
	};

	return (
		<div className='w-full'>
			<label htmlFor={name} className='input-label'>
				{title}
				{required && (
					<span className='text-xs'>
						<StarIcon height='7px' width='7px' />
					</span>
				)}
			</label>

			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, value } }) => {
					return (
						<>
							{isYear ? (
								<select
									key={value}
									value={value ?? '-'}
									onChange={(e) => {
										const selectedYear = e.target.value;
										onChange(selectedYear);
										setValue(name, selectedYear);
									}}
									className='relative text-sm-responsive py-2 px-[6px] cursor-pointer border rounded-lg border-themeGray w-full'
								>
									{[...Array(100)].map((_, index) => {
										const year = currentYear - index;
										return (
											<option key={year} value={year} className='px-4 py-2'>
												{year}
											</option>
										);
									})}
								</select>
							) : (
								<div className='relative'>
									<Datepicker
										key={value}
										theme={DateTheme}
										value={value ? moment(value).format('YYYY-MM-DD') : undefined}
										onSelectedDateChanged={(date: any) => {
											const formattedDate = moment(date).format('YYYY-MM-DD');
											onChange(formattedDate);
											setValue(name, formattedDate);
										}}
										size={50}
										className={className || 'date-input'}
										placeholder={placeHolder}
										showClearButton={false}
										showTodayButton={false}
									/>
									{value && (
										<button
											type='button'
											className='absolute top-[15px] right-3 text-gray-500 hover:text-gray-800'
											onClick={() => {
												onChange(null);
												setValue(name, null);
											}}
										>
											<CloseIcon />
										</button>
									)}
								</div>
							)}
							{false}
							{errorMsg && (
								<p className='text-left text-xs text-red-600 first-letter:uppercase'>{errorMsg}</p>
							)}
						</>
					);
				}}
			/>
		</div>
	);
};

export default DateInput;
