import React from 'react';
import { DownArrowIcon } from 'shared/icon';

interface IProps {
	onClick: () => void;
	isSelected: boolean;
	className?: string;
}

const ExpandButton: React.FC<IProps> = ({ onClick, isSelected, className }) => {
	return (
		<div
			className={`${className || ''} mr-3 flex size-5 cursor-pointer items-center justify-center`}
			onClick={(event) => {
				event.stopPropagation();
				onClick();
			}}
		>
			{isSelected ? (
				<DownArrowIcon className='size-3 rotate-180 stroke-[#344054]' />
			) : (
				<DownArrowIcon className='size-3 stroke-[#344054]' />
			)}
		</div>
	);
};

export default ExpandButton;
