import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import authEN from 'auth/localization/en';
import commonEN from './commonEN';
import headerEN from 'shared/components/header/localization/en';

const resources = {
	en: {
		auth: authEN,
		common: commonEN,
		header: headerEN
	}
};

i18next.use(initReactI18next).use(LanguageDetector).init({
	debug: true,
	fallbackLng: 'en',
	resources: resources
});
