export type TournamentsValues = {
	contract: {
		contract_url: string;
		contract_file_name: string;
		sport: '';
		contract_title: '';
		tournament_name: string;
		country: string;
		city: string;
		start_date: string | null;
		end_date: string | null;
		tournament_level: string;
		prize_money: number;
		starting_year_of_tournament: number;
		number_of_times_held: number;
		starting_year_of_sponsorship: number;
		number_of_times_sponsored: number;
	};
	sponsor: {
		type_of_sponsor: string;
		sponsorship_fee: number;
		yy_hq: number;
		subsidiary: number;
		distributor: number;
	};
};
export const defaultValues: TournamentsValues = {
	contract: {
		contract_url: '',
		contract_file_name: '',
		city: '',
		sport: '',
		contract_title: '',
		tournament_name: '',
		country: '',
		start_date: null,
		end_date: null,
		tournament_level: '',
		prize_money: 0,
		starting_year_of_tournament: 0,
		number_of_times_held: 0,
		starting_year_of_sponsorship: 0,
		number_of_times_sponsored: 0
	},
	sponsor: {
		type_of_sponsor: '',
		sponsorship_fee: 0,
		yy_hq: 0,
		subsidiary: 0,
		distributor: 0
	}
};

export type TeamValues = {
	contract: {
		contract_url: string;
		contract_file_name: string;
	};
	quick_add: {
		team_name: string;
		country: string;
		agent_name: string;
		sport: string;
		city: string;
	};
	retainers: IRetainer[];
	type: string;
	bonus: {
		ranking: string;
		ranking_bonus: number;
		signing_bonus: number;
		tournament_bonus: {
			tournament: string;
			tournament_type: string;
			bonus: number;
		};
		payment_record_of_bonus: {
			tournament_name: string;
			payment_record_of_bonus_amount: number;
			payment_date: string;
		};
	};
	equipment: {
		equipment_name: [];
	};
};
export const teamDefaultValues: TeamValues = {
	contract: {
		contract_url: '',
		contract_file_name: ''
	},
	quick_add: {
		team_name: '',
		country: '',
		agent_name: '',
		sport: '',
		city: ''
	},
	retainers: [
		{
			retainer: '',
			yy_hq: '',
			yy_usa: '',
			due_date: '',
			payment_date: '',
			contract_start_date: null,
			contract_end_date: null,
			//reduction: 0,
			rank: ''
			//ranking: 0
		}
	],
	bonus: {
		ranking: '',
		ranking_bonus: 0,
		signing_bonus: 0,
		tournament_bonus: {
			tournament: '',
			tournament_type: '',
			bonus: 0
		},
		payment_record_of_bonus: {
			tournament_name: '',
			payment_record_of_bonus_amount: 0,
			payment_date: ''
		}
	},
	type: 'Type X',
	equipment: {
		equipment_name: []
	}
};

export type individualValues = {
	contract: {
		contract_url: string;
		contract_file_name: string;
	};
	quick_add: {
		player_id: string;
		city: string;
		agent_name: string;
		sport: string;
		start_date: string | null;
		end_date: string | null;
	};
	type: string;
	equipment: {
		equipment_name: [];
	};
	retainers: IRetainer[];
	bonus: {
		rank: string;
		ranking_bonus: number;
		signing_bonus: number;
		tournament_bonus: {
			tournament: string;
			tournament_type: string;
			bonus: number;
		};
		payment_record_of_bonus: {
			tournament_name: string;
			amount: number;
			payment_date: string;
		};
	};
};

export const individualDefaultValues: individualValues = {
	contract: {
		contract_url: '',
		contract_file_name: ''
	},
	quick_add: {
		player_id: '',
		agent_name: '',
		sport: '',
		city: '',
		start_date: null,
		end_date: null
	},
	type: '',
	equipment: {
		equipment_name: []
	},
	bonus: {
		rank: '',
		ranking_bonus: 0,
		signing_bonus: 0,
		tournament_bonus: {
			tournament: '',
			tournament_type: '',
			bonus: 0
		},
		payment_record_of_bonus: {
			tournament_name: '',
			amount: 0,
			payment_date: ''
		}
	},
	retainers: [
		{
			retainer: '',
			yy_hq: '',
			yy_usa: '',
			due_date: '',
			payment_date: '',
			contract_start_date: null,
			contract_end_date: null,
			//reduction: 0,
			rank: ''
			//ranking: 0
		}
	]
};

export interface IRetainer {
	retainer: string;
	contract_start_date: string | null;
	contract_end_date: string | null;
	yy_hq: string;
	yy_usa: string;
	due_date: string;
	payment_date: string;
	//reduction: number;
	rank: string;
	//ranking: number;
}

export interface IFilters {
	sport: string;
	country_id: string;
	sponsorship_type: string;
	tournament_level: string;
	starting_year_of_tournament: string;
	status: string;
	payment_date: string;
	equipment_name: string;
	tournament: string;
}

export interface ISelectedFilterValues {
	[key: string]: any; // Allows dynamic properties
}
export const initFilterValues = {
	sport: '',
	country_id: '',
	sponsorship_type: '',
	tournament_level: '',
	starting_year_of_tournament: '',
	equipment_name: '',
	tournament: ''
};

export const KPI_VALUES = {
	prize_money: 1300000,
	sponsorship_fee: 1300000,
	yy_hq: 1300000,
	subsidiary: 1300000,
	distributor: 1300000,
	retainer: 1300000,
	yy_usa: 1300000,
	ranking_bonus: 1300000,
	signing_bonus: 1300000,
	tournament_bonus: 1300000,
	payment_record_of_bonus: 1300000
};

export interface ITournamentsTable {
	id: string;
	tournament_name: string;
	start_date: string;
	end_date: string;
	tournament_level: string;
	type_of_sponsor: string;
	country_id: number;
	country_name: string;
	country_emoji: string;
	country_iso3: string;
	sponsorship_fee: number;
	prize_money: number;
}

export interface ITeamTable {
	id: string;
	type: string;
	team_name: string;
	country: ICountry;
	agent_name: string;
	sport: string;
	retainer: IRetainerData;
	equipment_count: number;
}

export interface IIndividualTable {
	id: string;
	type: string;
	agent_name: string;
	start_date: string;
	end_date: string;
	player_id: string;
	player_first_name: string;
	player_last_name: string;
	player_sport: string;
	country_id: number;
	country_name: string;
	country_flag: string;
	country_iso3: string;
}

export interface ICountry {
	id: number;
	name: string;
	flag: string;
	iso3: string;
}

export interface IRetainerData {
	retainer: any;
	contract_start_date: any;
	contract_end_date: any;
}

export type IOption = {
	label: string;
	value: string;
};

export interface IKpiValues {
	prize_money: number;
	sponsorship_fee: number;
	yy_hq: number;
	subsidiary: number;
	distributor: number;
	retainer: number;
	yy_usa: number;
	ranking_bonus: number;
	signing_bonus: number;
	tournament_bonus: number;
	payment_record_of_bonus: number;
}

export interface ITournamentViewDetails {
	contract: IContractView;
	sponsor: ISponsorView;
}

export interface IContractView {
	id: string;
	contract_url: string;
	contract_file_name: any;
	sport: string;
	contract_title: string;
	tournament_name: string;
	country_name: string;
	country_id: number;
	country_continent: string;
	country_emoji: string;
	country_iso3: string;
	city: string;
	start_date: string;
	end_date: string;
	tournament_level: string;
	prize_money: number;
	starting_year_of_tournament: number;
	number_of_times_held: number;
	starting_year_of_sponsorship: number;
	number_of_times_sponsored: number;
	created_at: string;
}

export interface ISponsorView {
	yy_hq: number;
	type_of_sponsor: string;
	sponsorship_fee: number;
	subsidiary: number;
	distributor: number;
}

export interface ITeamViewDetails {
	id: string;
	type: string;
	contract: ITeamContract;
	quick_add: IQuickAdd;
	bonus: IBonus;
	equipments: any[];
	retainers: ITeamRetainer[];
}

export interface ITeamContract {
	contract_url: any;
	contract_file_name: any;
}

export interface IQuickAdd {
	team_name: string;
	city: string;
	country_id: number;
	country_name: string;
	country_emoji: string;
	country_continent: string;
	country_iso3: string;
	agent_name: string;
	sport: string;
	created_at: string;
	updated_at: string;
	deleted_at: any;
}

export interface IBonus {
	ranking: string;
	ranking_bonus: number;
	signing_bonus: number;
	tournament_bonus: ITournamentBonus;
	payment_record_of_bonus: IPaymentRecordOfBonus;
}

export interface ITournamentBonus {
	tournament_category: string;
	tournament_sub_category: string;
	tournament_type: string;
	tournament_bonus: number;
}

export interface IPaymentRecordOfBonus {
	tournament_name: string;
	payment_record_of_bonus_amount: number;
	payment_date: string;
}

export interface ITeamRetainer {
	retainer: number;
	yy_hq: number;
	yy_usa: number;
	due_date: string;
	payment_date: string;
	contract_start_date: string;
	contract_end_date: string;
	reduction: number;
	created_at: string;
}

export enum NoteModal {
	ADD_NOTE = 'Add Note'
}

export interface IPagination {
	total: number;
	record_per_page: number;
	current_page: number;
	total_pages: number;
	next_page: number;
	remaining_count: number;
}

export interface IAthleteData {
	data: IAthlete[];
}

export interface IAthlete {
	id: string;
	first_name: string;
	last_name: string;
	sport: string;
	country: ICountry;
}

export interface IAthleteDropdown {
	label: string;
	value: string;
	sport: string; // Assuming the API response includes the sport
	country: ICountry; // A
}

export enum IActiveType {
	TOURNAMENT = 'Tournament',
	TEAM = 'Team',
	INDIVIDUAL = 'Individual'
}

export enum CONTRACT_FILTERS {
	STATUS = 'status',
	START_DATE = 'start_date',
	END_DATE = 'end_date',
	TYPE = 'type',
	SORT_BY = 'sort_by',
	ORDER_BY = 'order_by',
	CURRENT_PAGE = 'current_page',
	RECORD_PER_PAGE = 'record_per_page'
}

export const initContractFilters = {
	[CONTRACT_FILTERS.STATUS]: '',
	[CONTRACT_FILTERS.START_DATE]: '',
	[CONTRACT_FILTERS.END_DATE]: '',
	[CONTRACT_FILTERS.TYPE]: '',
	[CONTRACT_FILTERS.ORDER_BY]: '',
	[CONTRACT_FILTERS.SORT_BY]: '',
	[CONTRACT_FILTERS.CURRENT_PAGE]: 1,
	[CONTRACT_FILTERS.RECORD_PER_PAGE]: 12
};

export type IContractFilters = {
	[key in CONTRACT_FILTERS]: string | number;
};
