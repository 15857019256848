import { FC } from 'react';
import ScoreCard from './scoreCard';
import { IMarketability, IScore } from 'features/playerProfile/interface';
import useComponentPermission from '../permissions/userComponentPermission';

interface IProps {
	marketability?: IMarketability;
	data?: IScore[];
	className?: string;
	isSmall?: boolean;
}
const PerformanceProgressBar: FC<IProps> = ({ marketability, data, className, isSmall }) => {
	const { isMarketability, isPerformanceMetrics } = useComponentPermission();

	return (
		<div
			className={`flex ${isMarketability || isPerformanceMetrics ? '' : 'hidden'} ${
				className ? className : 'w-[35%]'
			}  flex-wrap gap-x-2 2xl:gap-x-4 rounded-xl border border-themeGray bg-lightBlue p-3 pb-0`}
		>
			{isPerformanceMetrics &&
				data &&
				data.map((item) => (
					<ScoreCard title={item.title} score={item.score} key={item.title} isSmall={isSmall ?? false} />
				))}
			{isMarketability && marketability && (
				<div className='w-full flex justify-between pb-4'>
					<div className='rounded-lg bg-white  w-[49%] px-4 py-2.5'>
						<p className=' text-sm-responsive font-semibold  text-primary text-center '>
							Marketability (Current)
						</p>
						<div className='bg-white rounded-lg '>
							<p className='text-sm-responsive font-semibold  text-[#344054] text-center'>
								{marketability?.player_marketability_current?.split(' ')[0] ?? '-'}
							</p>
							<p className='text-sm-responsive  text-center font-bold text-textGray'>
								{marketability?.player_marketability_current?.split(' ')[1]}
							</p>
						</div>
					</div>
					<div className='rounded-lg bg-white  w-[49%] px-4 py-2.5'>
						<p className=' text-sm-responsive font-semibold  text-primary text-center '>
							Marketability (Potential)
						</p>
						<div className='bg-white rounded-lg '>
							<p className='text-sm-responsive font-semibold  text-[#344054] text-center'>
								{marketability?.player_marketability_potential?.split(' ')[0] ?? '-'}
							</p>
							<p className='text-sm-responsive text-center font-bold text-textGray'>
								{marketability?.player_marketability_potential?.split(' ')[1]}
							</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default PerformanceProgressBar;
