import React from 'react';
import { UseFieldArrayRemove } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Textarea } from 'flowbite-react';

import { getVideoName, noteFormatDate } from 'shared/util/utility';
import { DeleteIcon, EditIcon, Mp4Icon } from 'shared/icon';
import { VideoModal } from 'features/addPlayer/constant';
import { IPlayerVideos } from 'features/addPlayer/interface';

interface IProps {
	playerVideos: IPlayerVideos[];
	remove: UseFieldArrayRemove;
	uploadProgress: number;
	setVideoIndex: (videoIndex: number) => void;
	openModal: (value: string) => void;
	setVideoInfo: (videoInfo: IPlayerVideos) => void;
}
const QuickAddVideoCard: React.FC<IProps> = ({
	playerVideos,
	remove,
	uploadProgress,
	setVideoIndex,
	setVideoInfo,
	openModal
}) => {
	return (
		<>
			{playerVideos &&
				playerVideos.map((details: IPlayerVideos, index: number) => {
					const videoName = getVideoName(details.video_url);
					return (
						<div
							className='bg-white h-full w-full max-w-[415px] p-4 rounded-lg border border-themeGray'
							key={index}
						>
							<div className='flex'>
								<div>
									<Mp4Icon />
								</div>
								<div className='ml-2.5 w-full'>
									<div className='flex justify-between w-full'>
										<Link to={details.video_url} target='_blank'>
											<p
												title={details?.file?.name ?? videoName}
												className='truncate w-full max-w-[300px] text-sm font-medium text-[#344054]'
											>
												{details?.file?.name ?? videoName}
											</p>
										</Link>
										<div className='flex gap-1'>
											<DeleteIcon
												className='stroke-textGray cursor-pointer'
												onClick={() => remove(index)}
											/>
											<EditIcon
												className='stroke-textGray cursor-pointer'
												onClick={() => {
													openModal(VideoModal.editVideo);
													setVideoIndex(index);
													const info =
														playerVideos &&
														playerVideos.find((video) => video.id === details.id);

													setVideoInfo(info as IPlayerVideos);
												}}
											/>
										</div>
									</div>
									{details?.file?.size && (
										<p className='text-sm font-medium text-textGray'>
											{(details?.file?.size / (1024 * 1024)).toFixed(2)} MB
										</p>
									)}

									<div className='flex items-center mt-2.5'>
										<div className='w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-600 mr-2 '>
											<div
												className='bg-[#19B454] h-2.5 rounded-full w-2/4'
												style={{ width: `${uploadProgress}%` }}
											></div>
										</div>
										<p className='text-sm font-medium text-[#344054]'>{`${uploadProgress}%`}</p>
									</div>
								</div>
							</div>
							<hr className='mt-3' />
							<div className='flex justify-between mt-3'>
								<p className='text-sm font-medium text-[#344054]'>{details.tournament_name || '--'}</p>
								<p className='text-sm font-medium text-[#344054]'>
									Creation date:&nbsp;
									<span>{noteFormatDate(details.creation_date) || '--'}</span>
								</p>
							</div>
							<p className='text-sm font-medium text-[#344054] mt-3 mb-1.5'>Comment</p>
							<Textarea
								placeholder='Some example od added comment..'
								value={details.comment || '--'}
								disabled
							/>
						</div>
					);
				})}
		</>
	);
};

export default QuickAddVideoCard;
