import { FC } from 'react';
import { Button } from 'flowbite-react';

import { ButtonTheme } from 'shared/components/buttons/primary';
import { buttonGroupTheme } from 'features/athleteDatabase/constant';
import { PINED_FILTER } from 'features/allAthlates/constant';
import { getButtonClass } from 'shared/util/utility';

interface IProps {
	selectedValue: boolean;
	onChange: (value: boolean) => void;
}

const PinFilter: FC<IProps> = ({ selectedValue, onChange }) => {
	return (
		<Button.Group outline theme={buttonGroupTheme} className='mr-3 h-fit'>
			{PINED_FILTER.map(({ label, value }) => {
				const selected = value === 'only_pinned';
				return (
					<Button
						className={`ring-none ${getButtonClass(value, selectedValue)}`}
						color='gray'
						key={value}
						theme={buttonTheme}
						onClick={() => selected !== selectedValue && onChange(value === 'only_pinned')}
					>
						{label}
					</Button>
				);
			})}
		</Button.Group>
	);
};

const buttonTheme = {
	size: {
		...ButtonTheme.size
	}
};

export default PinFilter;
