import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'flowbite-react';
import { isEmpty } from 'lodash';

import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import { ICompareAthlete, IKpiAvg, IKpiValue } from 'features/athleteDatabase/interface';
import { KpiGroup } from 'features/athleteDatabase/constant';

import CompareKpiScore from './compareKpiScore';
import CompareCard from './compareCard';
import Perform from '../perform';

interface IProps {
	className?: string;
	show: boolean;
	onClose: () => void;
	comparisonIds: { currentId: string; selectedId: string };
}

const CompareAthlete: React.FC<IProps> = (props) => {
	const { className, show, onClose, comparisonIds } = props;

	const [loading, setLoading] = useState(false);

	const [comparisonDetails, setComparisonDetails] = useState({
		playerOne: {} as ICompareAthlete,
		playerTwo: {} as ICompareAthlete
	});
	const [kpiAvgData, setKpiAvgData] = useState<IKpiAvg>({} as IKpiAvg);
	let playerOneData, playerTwoData;

	const { playerOne, playerTwo } = comparisonDetails;

	const fetchPlayers = async () => {
		try {
			const params = {
				player1: comparisonIds.currentId,
				player2: comparisonIds.selectedId
			};
			setLoading(true);
			const res = await HttpService.get(`${API_CONFIG.player}/compare`, params);

			if (res.data[0]?.id === comparisonIds.currentId) {
				playerOneData = res.data[0];
				playerTwoData = res.data[1];
			} else {
				playerOneData = res.data[1];
				playerTwoData = res.data[0];
			}
			setComparisonDetails({
				playerOne: playerOneData,
				playerTwo: playerTwoData
			});
			const avgData = transformResponseToData(playerOneData, playerTwoData);
			setKpiAvgData(avgData);
			setLoading(false);
		} catch (err) {
			console.error(err);
			setLoading(false);
		}
	};

	useEffect(() => {
		show && fetchPlayers();
	}, [show]);

	const transformResponseToData = (playerOne: ICompareAthlete, playerTwo: ICompareAthlete) => {
		const calculateAvgScore = (kpiArray: IKpiValue[]) => {
			const validKpis = kpiArray.filter((kpi: IKpiValue) => kpi.value !== null);

			// Extracting the valid scores
			const scoreArray = validKpis.map((kpi: IKpiValue) => kpi.value as number);
			let totalScore = 0;
			// Summing the scores
			scoreArray.forEach((score: number) => {
				totalScore += score;
			});

			// Calculating the average score and rounding down
			return scoreArray.length > 0 ? Math.round(totalScore / scoreArray.length) : 0;
		};

		const transformCategory = (category: string) => {
			const playerOneKPIs = playerOne?.kpis[category] as IKpiValue[];
			const playerTwoKPIs = playerTwo?.kpis[category] as IKpiValue[];

			const kpiData = playerOneKPIs?.map((kpi: IKpiValue, index: number) => {
				const playerTwoKpi = playerTwoKPIs[index];
				return {
					player_one_value: Math.round(kpi.value),
					player_one_fill: Math.round(kpi.value * 100) / 20,
					player_two_value: playerTwoKpi ? Math.round(playerTwoKpi.value) : 0,
					player_two_fill: playerTwoKpi ? Math.round(playerTwoKpi.value * 100) / 20 : 0,
					kpi_name: kpi.kpi_name
				};
			});

			return {
				player_one_avg_score: calculateAvgScore(playerOneKPIs),
				player_two_avg_score: calculateAvgScore(playerTwoKPIs),
				kpiData: kpiData
			};
		};

		const transformedData: IKpiAvg = {};
		KpiGroup.forEach((category) => {
			transformedData[category] = transformCategory(category);
		});

		return transformedData;
	};

	return (
		<Modal className={`${className ?? ''}`} show={show} onClose={onClose} theme={Theme}>
			<Modal.Header className='rounded-t-lg border-b-2 border-primary bg-lightBlue'>
				<p className='header-txt'>Athlete comparison</p>
			</Modal.Header>
			<Modal.Body>
				{!loading && playerOne && playerTwo && (
					<div className='p-3'>
						<div className='flex items-center justify-between pb-2'>
							<CompareCard player={playerOne} />
							<CompareCard player={playerTwo} />
						</div>
						<div className='custom-scrollbar block h-[calc(100vh-750px)] min-h-[38vh] overflow-y-auto border border-themeGray rounded-lg'>
							<div className='border-b border-themeGray p-2'>
								<div className='flex items-center justify-around'>
									<Perform
										performData={playerOne.performance}
										className={
											playerOne.performance?.current > playerTwo.performance?.current
												? 'text-[#34C759]'
												: 'text-[#FF3B30]'
										}
									/>
									<p className='text-textBlack font-semibold'>Overall Performance Score</p>
									<Perform
										performData={playerTwo.performance}
										className={
											playerTwo.performance?.current > playerOne.performance?.current
												? 'text-[#34C759]'
												: 'text-[#FF3B30]'
										}
									/>
								</div>
							</div>
							{!isEmpty(kpiAvgData) && <CompareKpiScore kpiAvgData={kpiAvgData} />}
						</div>
					</div>
				)}
				{loading && (
					<div className='text-center flex items-center justify-center mt-3'>
						<Spinner aria-label='Extra large Center-aligned spinner example' size='xl' />
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
};
export default CompareAthlete;

const Theme = {
	root: {
		show: {
			on: 'flex bg-[#0C111D] bg-opacity-50 backdrop-blur-sm dark:bg-opacity-80'
		},
		sizes: {
			'2xl': 'none'
		}
	},
	content: {
		base: 'relative h-full p-4 md:h-auto w-full max-w-[1212px]'
	},
	body: {
		base: 'flex-1 p-3 overflow-auto custom-scrollbar'
	}
};
