import { FC } from 'react';
import { Button } from 'flowbite-react';
import { isEmpty } from 'lodash';

import Dropdowns from 'shared/components/dropdown/flowboteDropdown';
import { BackIcon, FilterIcon, PlusIcon } from 'shared/icon';
import ButtonPrimary, { ButtonTheme } from 'shared/components/buttons/primary';
import { ATHLETES_OPTIONS, buttonGroupTheme } from 'features/athleteDatabase/constant';
import { AllAthleteModal, ScreenType } from 'features/allAthlates/constant';
import useModal from 'shared/hooks/useModal';
import { useAthleteContext } from 'store/athleteStore';

import DataFilterModal from './dataFilter';
import SearchInput from 'shared/components/search';
import SportsFilter from 'shared/components/filter/sportsFilter';
import PinFilter from 'shared/components/filter/pinFilter';
import TableView from 'shared/components/filter/tableView';
import { TOURNAMENT_OPTIONS } from 'shared/constants';

interface IProps {
	viewType: string;
	isQuickFilterApplied: boolean;
	onChangeFilter: (key: FILTERS, value: string | boolean) => void;
	openQuickFilter: () => void;
	screenType: string;
	modalOpen: (value: AllAthleteModal) => void;
	selectedId: string[];
	handleBackNavigation: () => void;
	playerTotalCount: number;
	dataFilters: { kpi_id: number; value: number }[];
	onChangeDataFilter: (filter: { kpi_id: number; value: number }[]) => void;
}

const enum FilterModal {
	quickFilterModal = 'quickFilterModal'
}

const Filters: FC<IProps> = (props) => {
	const { allAthleteFilters: filters, setViewType } = useAthleteContext();

	const {
		viewType,
		isQuickFilterApplied,
		onChangeFilter,
		openQuickFilter,
		screenType,
		modalOpen,
		selectedId,
		handleBackNavigation,
		dataFilters,
		onChangeDataFilter
	} = props;
	const { isOpen, type, closeModal, openModal } = useModal<FilterModal>();

	return (
		<>
			<div className='bg-lightBlue'>
				<div className='flex max-h-[77px] w-full items-center justify-between rounded-t-lg border p-2 lg:p-2 2xl:p-4'>
					<div className='flex items-center justify-center gap-2'>
						{screenType === ScreenType.SELECTION_ATHLETE && (
							<div
								className='flex items-center justify-center border-themeGray bg-white size-10 rounded-md shadow-sm cursor-pointer'
								onClick={handleBackNavigation}
							>
								<BackIcon width='15px' />
							</div>
						)}
						<div>
							<div className='flex items-center gap-2'>
								<p className='header-txt'>All Athletes</p>
								{props?.playerTotalCount > 0 ? (
									<p className='bg-[#1d953317] p-2 rounded-lg h-8 text-[#1E9634] text-xs'>
										{props?.playerTotalCount}{' '}
										{`${props.playerTotalCount === 1 ? 'Athlete' : 'Athletes'}`}
									</p>
								) : (
									<></>
								)}
							</div>
							<p className='sub-header-txt '>The List of Followed Athletes</p>
						</div>
					</div>

					<div className='flex items-center justify-center gap-2'>
						<SearchInput
							onChangeFilter={onChangeFilter as any}
							FilterKey={FILTERS.SEARCH}
							searchValue={filters[FILTERS.SEARCH] as string}
						/>
						{screenType === ScreenType.SELECTION_ATHLETE && (
							<div className='flex items-center justify-center gap-2'>
								<ButtonPrimary
									btnTxt='Create Shortlist'
									className={`capitalize ${selectedId.length <= 0 && '!bg-primary'}`}
									icon={
										<PlusIcon
											width='15px'
											className={`mr-2 ${
												selectedId.length <= 0 ? 'stroke-themeGray-text' : 'stroke-white'
											}`}
										/>
									}
									onClick={() => modalOpen(AllAthleteModal.ADD_TO_SHORTLIST)}
									disable={selectedId.length <= 0}
								/>
								<ButtonPrimary
									btnTxt='Create New Board'
									className={`capitalize ${selectedId.length <= 0 && '!bg-primary'}`}
									icon={
										<PlusIcon
											width='15px'
											className={`mr-2 ${
												selectedId.length <= 0 ? 'stroke-themeGray-text' : 'stroke-white'
											}`}
										/>
									}
									disable={selectedId.length <= 0}
									onClick={() => modalOpen(AllAthleteModal.ADD_TO_DASHBOARD)}
								/>
							</div>
						)}
					</div>
				</div>
				<div className='flex max-h-[77px] w-full items-center justify-between border-x border-b px-4 py-2'>
					<div className='flex items-center'>
						<PinFilter
							selectedValue={filters[FILTERS.IS_PINNED] as boolean}
							onChange={(isPinned) => onChangeFilter(FILTERS.IS_PINNED, isPinned)}
						/>
						<SportsFilter
							selectedValue={filters[FILTERS.SPORTS] as string}
							onChange={(value: string) => {
								onChangeFilter(FILTERS.SPORTS, value);
							}}
							buttonTheme={buttonGroupTheme}
						/>

						<Dropdowns
							options={ATHLETES_OPTIONS}
							defaultValue={(filters[FILTERS.ATHLETE] as string) || ''}
							onChange={(selected) => onChangeFilter(FILTERS.ATHLETE, selected)}
						/>
						<Dropdowns
							className='ml-3'
							options={[{ label: 'All Tournament', value: '' }, ...TOURNAMENT_OPTIONS]}
							defaultValue={(filters[FILTERS.TOURNAMENT] as string) || ''}
							onChange={(selected) => onChangeFilter(FILTERS.TOURNAMENT, selected)}
						/>
					</div>
					<div className='flex '>
						<div className='mr-3 flex h-6 items-center justify-center'>
							<Button
								color='light'
								className={`ring-none border-none ${!isEmpty(dataFilters) ? 'bg-gray-300' : ''}`}
								onClick={() => openModal(FilterModal.quickFilterModal)}
								theme={ButtonTheme}
							>
								<FilterIcon className='mr-2 size-5' />
								Data Filters
							</Button>
						</div>
						<div className='mr-3 flex h-6 items-center justify-center border-r border-[#EAECF0]'>
							<Button
								color='light'
								className={`flex items-center justify-center ring-none border-none ${
									isQuickFilterApplied ? 'bg-gray-300' : ''
								}`}
								onClick={openQuickFilter}
								theme={ButtonTheme}
							>
								<FilterIcon className='mr-2 size-5' />
								Quick Filters
							</Button>
						</div>
						<TableView viewType={viewType} onChange={setViewType} />
					</div>
				</div>
			</div>
			{type === FilterModal.quickFilterModal && (
				<DataFilterModal
					show={isOpen}
					dataFilters={dataFilters}
					onClose={closeModal}
					onChangeFilter={(dataFilters) => onChangeDataFilter(dataFilters)}
				/>
			)}
		</>
	);
};

export enum FILTERS {
	SPORTS = 'sports',
	SEARCH = 'name',
	ATHLETE = 'athlete_type',
	KPI = 'kpis',
	SHORT_BY = 'sort_by',
	ORDER_BY = 'order_by',
	CURRENT_PAGE = 'current_page',
	RECORD_PER_PAGE = 'record_per_page',
	IS_PINNED = 'is_pinned',
	TOURNAMENT = 'tournament'
}

export const initFilters = {
	[FILTERS.SPORTS]: 'Badminton',
	[FILTERS.KPI]: '',
	[FILTERS.SEARCH]: '',
	[FILTERS.ATHLETE]: ATHLETES_OPTIONS[0]?.value ?? '',
	[FILTERS.SHORT_BY]: '',
	[FILTERS.ORDER_BY]: '',
	[FILTERS.TOURNAMENT]: '',
	[FILTERS.CURRENT_PAGE]: 1,
	[FILTERS.RECORD_PER_PAGE]: 10,
	[FILTERS.IS_PINNED]: false
};

export default Filters;
