import { useState, useEffect } from 'react';

const useCardsPerRow = () => {
	const [cardsPerRow, setCardsPerRow] = useState(4);

	const calculateCardsPerRow = () => {
		const width = window.innerWidth;
		let cards = 1;
		if (width >= 1536) {
			cards = 4; // xl
		} else if (width >= 1280) {
			cards = 3; // lg
		} else if (width >= 766) {
			cards = 2; // md
		}
		setCardsPerRow(cards);
	};

	useEffect(() => {
		calculateCardsPerRow();
		window.addEventListener('resize', calculateCardsPerRow);
		return () => window.removeEventListener('resize', calculateCardsPerRow);
	}, []);

	return cardsPerRow;
};

export default useCardsPerRow;
