import React, { useContext, useState } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { NoteModal } from 'features/playerProfile/constant';
import useModal from 'shared/hooks/useModal';
import AddNoteModal from 'shared/components/modal/addNoteModal';
import { DeleteIcon, EditIcon } from 'shared/icon';
import { AuthContext } from 'store/userDetailStore';
import { IPlayerNote } from '../interface';

interface IProps {
	tabName: string;
	notesData: IPlayerNote[];
	handleDelete?: (data: { note: string; tournament: string }) => void;
	handleUpdateNote?: (data: { note: string; tournament: string }, note: IPlayerNote) => void;
	readOnly?: boolean;
}

const PlayerNote: React.FC<IProps> = (props) => {
	const { tabName, notesData, handleDelete, handleUpdateNote, readOnly = false } = props;

	const { userData } = useContext(AuthContext);
	const [note, setNote] = useState<IPlayerNote>({} as IPlayerNote);

	const { isOpen, type, closeModal, openModal } = useModal<NoteModal>();

	return (
		<>
			{!isEmpty(notesData) &&
				notesData.map((data: IPlayerNote, index: number) => {
					return (
						<div key={index} className='flex flex-col border-b border-borderGray pb-4'>
							<div className='flex items-center justify-between mt-3'>
								<p className='text-[#344054] text-sm font-medium'>{`${userData.name} ${userData.last_name}`}</p>
								<p className='text-[#344054] text-sm font-medium'>{moment().format('ll')}</p>
							</div>
							<div className='border flex border-themeGray rounded-md p-3 mt-3 shadow-sm items-center justify-between'>
								<p className='mr-3 text-textBlack'>{data?.note ?? '-'}</p>
								{!readOnly && (
									<div className='flex justify-center'>
										<EditIcon
											className='stroke-primary mr-2 cursor-pointer'
											onClick={() => {
												openModal(NoteModal.editNote);
												setNote(data);
											}}
										/>
										<DeleteIcon
											className='stroke-textGray cursor-pointer'
											onClick={() => handleDelete && handleDelete(data)}
										/>
									</div>
								)}
							</div>
						</div>
					);
				})}

			{isEmpty(notesData) && (
				<div className='border border-themeGray rounded-md p-3 mt-3 shadow-sm flex items-center justify-center'>
					<p className='mr-3 text-themeGray-text'>{'No notes found'}</p>
				</div>
			)}
			{isOpen && type === NoteModal.editNote && (
				<AddNoteModal
					headerTile={tabName}
					show={isOpen}
					onClose={() => {
						closeModal();
						setNote({} as any);
					}}
					isQuickNote={false}
					formSubmit={(data) => handleUpdateNote && handleUpdateNote(data, note)}
					initialData={note}
					type={type}
				/>
			)}
		</>
	);
};
export default PlayerNote;
