import { FC } from 'react';

const ProgressBar: FC<{ score: number }> = ({ score }) => {
	const fiftyPercentOfI = Math.round((Math.max(score - 5, 0) / 15) * 100);
	return (
		<div className='relative size-20 h-[65px] 2xl:h-20'>
			<svg className='size-full rotate-180' viewBox='0 0 36 36'>
				{/* <!-- Background Circle (Gauge) --> */}
				<circle
					cx='18'
					cy='18'
					r='16'
					fill='none'
					className='stroke-current text-gray-200 dark:text-neutral-700'
					strokeWidth='2.5'
					strokeDasharray='50 100'
					strokeLinecap='round'
				/>

				{/* <!-- Gauge Progress --> */}
				<circle
					cx='18'
					cy='18'
					r='16'
					fill='none'
					className='stroke-current text-green-500 dark:text-green-500'
					strokeWidth='3'
					strokeDasharray={`${fiftyPercentOfI / 2} 100`}
					strokeLinecap='round'
				/>
			</svg>

			{/* <!-- Value Text --> */}
			<div className='absolute start-1/2 top-5 -translate-x-1/2 text-center'>
				<span className='text-base-responsive font-bold '>{score}</span>
				{/* <span className='block text-sm text-blue-600 dark:text-blue-500'>Score</span> */}
			</div>
		</div>
	);
};

export default ProgressBar;
