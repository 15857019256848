import { ViewType } from 'features/athleteDatabase/interface';
import { FC } from 'react';
import { GridViewIcon, TableViewIcon } from 'shared/icon';

interface IProps {
	viewType: string;
	className?: string;
	onChange: (value: ViewType) => void;
}

const TableView: FC<IProps> = ({ viewType, className, onChange }) => {
	return (
		<div className={`flex items-center justify-center cursor-pointer ${className || ''}`}>
			{viewType === ViewType.Table_View ? (
				<GridViewIcon onClick={() => onChange(ViewType.Card_View)} />
			) : (
				<TableViewIcon onClick={() => onChange(ViewType.Table_View)} />
			)}
		</div>
	);
};

export default TableView;
