import { FC } from 'react';
import { UseFormRegister } from 'react-hook-form';

interface IProp {
	name: string;
	title: string;
	errorMsg?: string;
	checked?: any;
	register: UseFormRegister<any>;
	onChange?: () => void;
	readOnly?: boolean;
}

export const CheckBox: FC<IProp> = ({ name, title, errorMsg, checked, readOnly, register, onChange }) => {
	return (
		<div className='flex items-start'>
			<input
				{...register(name)}
				type='checkbox'
				id={name}
				className='ring-none size-4 cursor-pointer rounded border border-themeGray'
				onChange={onChange}
				checked={checked}
				readOnly={readOnly}
			/>
			<label
				htmlFor={name}
				className='ms-2 cursor-pointer select-none text-sm font-medium text-[#344054] dark:text-gray-300'
			>
				{title}
			</label>
			{errorMsg && <p className='text-left text-xs text-red-600'>{errorMsg}</p>}
		</div>
	);
};
