import * as Yup from 'yup';

export const schema = Yup.object({
	player_information: Yup.object({
		first_name: Yup.string().trim().required('First Name is required'),
		last_name: Yup.string().trim().required('Last Name is required'),
		racquet_brand: Yup.string().required('Racquet Brand is required'),
		gender: Yup.string().required('Gender is required'),
		status: Yup.string().required('Status is required'),
		circuit_level: Yup.string().required('Circuit Level is required'),
		category1: Yup.string().required('Category 1 is required'),
		sports: Yup.string().required('Sport is required'),
		country: Yup.string().required('Country is required')
	}),
	performance_profile: Yup.object({
		team_yonex_future_star_level: Yup.string().when('performance_toggle', {
			is: false, // When toggle is false, the field is required
			then: (schema) => schema.required('Future Star Level is required'),
			otherwise: (schema) => schema.notRequired()
		}),
		potential_grade: Yup.string().when('performance_toggle', {
			is: false, // When toggle is false, the field is required
			then: (schema) => schema.required('Potential Grade is required'),
			otherwise: (schema) => schema.notRequired()
		})
	})
});

export const enum PerformanceGradingModal {
	Performance_Grading_Guide = 'Performance Grading Guide'
}
export const STATUS_OPTIONS = [
	{ label: 'Identification', value: 'Identification' },
	{ label: 'Reviewed', value: 'Reviewed' },
	{ label: 'Not of Interest', value: 'Not of Interest' },
	{ label: 'Monitoring', value: 'Monitoring' },
	{ label: 'Priority - Look to Secure', value: 'Priority - Look to Secure' },
	{ label: 'Priority - with Competitor', value: 'Priority - with competitor' },
	{ label: 'Secured Individual', value: 'Secured Individual' },
	{ label: 'Secured Team / Association', value: 'Secured Team or Association' },
	{ label: 'Retired', value: 'Retired' },
	{ label: 'Competitor Sponsored', value: 'Competitor Sponsored' }
];

export const CIRCUIT_LEVEL_OPTIONS = [
	{ label: 'U11', value: 'U11' },
	{ label: 'U13', value: 'U13' },
	{ label: 'U15', value: 'U15' },
	{ label: 'U17', value: 'U17' },
	{ label: 'U19', value: 'U19' },
	{ label: 'Professional', value: 'Professional' },
	{ label: 'Junior', value: 'Junior' }
];

export const GENDER_OPTIONS = [
	{ label: 'Female', value: 'Female' },
	{ label: 'Male', value: 'Male' }
];

export const BRAND_OPTIONS = [
	{ label: 'Yonex', value: 'Yonex' },
	{ label: 'Li-Ning', value: 'Li-Ning' },
	{ label: 'Victor', value: 'Victor' },
	{ label: 'Babolat', value: 'Babolat' },
	{ label: 'APACS', value: 'APACS' },
	{ label: 'FZ Forza', value: 'FZ Forza' },
	{ label: 'Carlton', value: 'Carlton' },
	{ label: 'Talbot', value: 'Talbot' },
	{ label: 'Ashaway', value: 'Ashaway' },
	{ label: 'Felet', value: 'Felet' },
	{ label: 'Karakal', value: 'Karakal' },
	{ label: 'Protech', value: 'Protech' },
	{ label: 'None', value: 'None' }
];

export const DISCIPLINE_OPTIONS = [
	{ label: 'MS', value: 'MS' },
	{ label: 'WS', value: 'WS' },
	{ label: 'MD', value: 'MD' },
	{ label: 'WD', value: 'WD' },
	{ label: 'XD', value: 'XD' }
];

export const FUTURE_LEVEL_OPTIONS = [
	{ label: 'Domestic Success', value: 'Domestic Success' },
	{ label: 'Continental Success', value: 'Continental Success' },
	{ label: 'Global Success', value: 'Global Success' }
];

export const POTENTIAL_GRADE_OPTIONS = [
	{ label: 'OC / WC', value: 'OC / WC' },
	{ label: 'Top 5', value: 'Top 5' },
	{ label: 'Top 10', value: 'Top 10' },
	{ label: 'Top 20', value: 'Top 20' },
	{ label: 'Top 30', value: 'Top 30' },
	{ label: 'Top 50', value: 'Top 50' },
	{ label: 'Top 100', value: 'Top 100' }
];
export const LEGENDS_OPTIONS = [
	{ label: 'Founding Legend', value: 'Founding Legend' },
	{ label: 'Legend And Ambassador', value: 'Legend And Ambassador' }
];

export const SENIOR_STAR_OPTIONS = [
	{ label: 'Local Star', value: 'Local Star' },
	{ label: 'Badminton Star', value: 'Badminton Star' },
	{ label: 'Rockstar', value: 'Rockstar' }
];

export const SPORTS_OPTIONS = [
	{ label: 'Badminton', value: 'Badminton' }
	// { label: 'Tennis', value: 'Tennis' }
];

export const SPORTS = [
	// { label: 'All Sports', value: '' },
	{ label: 'Badminton', value: 'Badminton', toolTip: 'Badminton' },
	{ label: 'Tennis', value: 'Tennis', toolTip: 'Tennis is coming soon!' }
];

export const PLAYING_HAND = [
	{ label: 'Right', value: 'Right' },
	{ label: 'Left', value: 'Left' }
];

export const selectAthleteModalTheme = {
	root: {
		show: {
			on: 'flex bg-[#0C111D] bg-opacity-50 backdrop-blur-sm dark:bg-opacity-80'
		},
		sizes: {
			'2xl': 'none'
		}
	},
	content: {
		base: 'relative h-full p-4 md:h-auto w-full max-w-[1020px]'
	},
	body: {
		base: 'flex-1 p-6 overflow-auto custom-scrollbar'
	}
};

export const buttonGroupTheme = { position: { middle: 'rounded-r-none ring-0 focus:ring-offset-0' } };

export const searchBarTheme = {
	field: {
		input: {
			colors: {
				gray: 'border-themeGray focus:border-primary-light focus:ring-primary-light pr-6'
			},
			sizes: {
				md: 'text-base-responsive',
				sm: 'text-sm-responsive max-w-[120px] 2xl:max-w-[242px]'
			}
		}
	}
};

export const filterButtonTheme = {
	color: {
		light: 'border-[none]'
	}
};

export const ATHLETE_TABLE_CELL = [
	{ title: 'Athlete Name', key: 'athlete_name' },
	{ title: 'Sport', key: 'sport' },
	{ title: 'Country', key: 'country', shortKey: 'Country', sortable: true },
	{ title: 'Gender', key: 'gender' },
	{ title: 'Circuit Lvl.', key: 'circuit_level' },
	{ title: 'Category', key: 'category1' },
	{ title: 'OPS.', key: 'perform', shortKey: 'Performance', sortable: true }
];

export enum PLAYER_KPI {
	FOOTWORK = 'Footwork',
	TECHNICAL = 'Technical',
	TACTICAL = 'Tactical',
	PSYCHOLOGICAL = 'Psychological',
	PHYSICAL = 'Physical',
	DAILY_PRACTICE_OR_LIFESTYLE = 'Daily Practice Or Lifestyle',
	PERFORMANCE_ENVIRONMENT = 'Performance Environment'
}

export const KpiGroup = [
	PLAYER_KPI.FOOTWORK,
	PLAYER_KPI.TECHNICAL,
	PLAYER_KPI.TACTICAL,
	PLAYER_KPI.PSYCHOLOGICAL,
	PLAYER_KPI.PHYSICAL,
	PLAYER_KPI.DAILY_PRACTICE_OR_LIFESTYLE,
	PLAYER_KPI.PERFORMANCE_ENVIRONMENT
];

export const ATHLETES_OPTIONS = [
	{ label: 'Yonex Athletes', value: 'Yonex Athlete' },
	{ label: 'Non-Yonex Athletes', value: 'Non-Yonex Athlete' },
	{ label: 'All Athletes', value: 'All Athlete' }
];

export const NON_YONEX_ATHLETE = [
	'Identification',
	'Reviewed',
	'Not of Interest',
	'Monitoring',
	'Priority - Look to Secure',
	'Priority - With competitor',
	'Competitor Sponsored'
];

export const YONEX_ATHLETE = ['Secured Individual', 'Secured Team or Association', 'Retired'];
