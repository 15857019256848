import React from 'react';

import InformationModal from 'shared/components/modal/informationModal';
import TableComponent from 'shared/components/table/table';
import TableBody from 'shared/components/table/tableBody';
import TableHeader from 'shared/components/table/tableHeader';
import { IAccessType } from './inviteForm';
import TableRow from 'shared/components/table/tableRow';
import TableCell from 'shared/components/table/tableCell';
import { CloseIcon, RightIcon } from 'shared/icon';

interface IProps {
	show: boolean;
	onClose: () => void;
}

const AccessTypeViewModal: React.FC<IProps> = (props) => {
	const { show, onClose } = props;

	return (
		<>
			<InformationModal
				headerTile={'Access type'}
				subHederTitle={'Info the access type to the platform'}
				show={show}
				onClose={onClose}
				size={'4xl'}
			>
				<TableComponent className='rounded-xl border'>
					<TableHeader
						headerTitleClassName='!text-wrap text-xs'
						tableCellList={ACCESS_TYPE_TABLE_CELL}
						className='sticky top-0 z-[1] table w-full table-fixed bg-white first:text-left'
					/>
					<TableBody className='custom-scrollbar block overflow-y-auto border-none'>
						{ACCESS_TYPE_INFO.map((data: IAccessType) => {
							return (
								<TableRow key={data.id} className='table w-full table-fixed'>
									{ACCESS_TYPE_TABLE_CELL.map((cell) => (
										<TableCell key={cell.title} className='border-b text-center '>
											<p className='flex items-center justify-center text-sm font-medium text-[#344054]'>
												{cell.key === 'userLevelAccess' ? (
													<p className='flex items-center justify-center text-sm font-medium text-[#344054]'>
														{data['userLevelAccess']}
													</p>
												) : data[`${cell.key}` as keyof IAccessType] ? (
													<RightIcon height='20px' width='20px' stroke='#1D9533' />
												) : (
													<CloseIcon height='12px' width='12px' stroke={'red'} />
												)}
											</p>
										</TableCell>
									))}
								</TableRow>
							);
						})}
					</TableBody>
				</TableComponent>
			</InformationModal>
		</>
	);
};

export default AccessTypeViewModal;

const ACCESS_TYPE_TABLE_CELL = [
	{ title: 'User level access', key: 'userLevelAccess', style: { width: '130px' } },
	{ title: 'Level One (Super Admin)', key: 'levelOne', style: { width: '70px' } },
	{ title: 'Level Two (Manager)', key: 'levelTwo', style: { width: '90px' } },
	{ title: 'Level Three (Team/Collaborator)', key: 'levelThree', style: { width: '100px' } },
	{ title: 'Level Four (View-Only Access)', key: 'levelFour', style: { width: '90px' } }
];

const ACCESS_TYPE_INFO = [
	{
		id: 1,
		userLevelAccess: 'View',
		levelOne: true,
		levelTwo: true,
		levelThree: true,
		levelFour: true,
		levelFive: true
	},
	{
		id: 2,
		userLevelAccess: 'Add',
		levelOne: true,
		levelTwo: true,
		levelThree: false,
		levelFour: false,
		levelFive: false
	},
	{
		id: 3,
		userLevelAccess: 'Edit',
		levelOne: true,
		levelTwo: true,
		levelThree: true,
		levelFour: false,
		levelFive: false
	},
	{
		id: 4,
		userLevelAccess: 'Delete',
		levelOne: true,
		levelTwo: true,
		levelThree: true,
		levelFour: false,
		levelFive: false
	}
];
