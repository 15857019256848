import { FC, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import useFlowbite from 'shared/hooks/useFlowbite';
import { CloseIcon, DownArrowIcon, StarIcon } from 'shared/icon';
import { IDropdownOptions } from 'shared/interface';

interface IProps {
	name: string;
	title: string;
	options: IDropdownOptions[];
	className?: string;
	errorMsg?: string;
	control: any;
	placeHolder?: string;
	menuPosition?: 'top' | 'bottom';
	disabled?: boolean;
	required?: boolean;
	isLabel?: boolean;
}

const Select: FC<IProps> = ({
	name,
	title,
	options,
	className,
	control,
	errorMsg,
	placeHolder,
	menuPosition,
	disabled,
	required,
	isLabel = true
}) => {
	useFlowbite();
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const dropdownRef = useRef<HTMLDivElement | null>(null);

	// Handle outside click
	const handleClickOutside = (event: any) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setDropdownVisible(false);
		}
	};

	useEffect(() => {
		// Add event listener to handle outside clicks
		document.addEventListener('click', handleClickOutside);

		// Cleanup event listener on component unmount
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	const getLabel = (selectedValue: string) => {
		const mainOption = options.find((option) => option.value === selectedValue);

		if (mainOption) {
			return mainOption.label;
		}
		return null;
	};

	const closeDropdown = () => {
		setDropdownVisible(false);
	};

	return (
		<div ref={dropdownRef} className={`${className || ''} relative w-full`}>
			{isLabel && (
				<label htmlFor={name} className='input-label'>
					{title}
					{required && (
						<span className='text-xs'>
							<StarIcon height='7px' width='7px' />
						</span>
					)}
				</label>
			)}
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, value } }) => (
					<>
						<button
							disabled={disabled}
							id='dropdownButton'
							className={`${disabled && 'cursor-not-allowed'} input-select ring-none ${
								!value && 'text-themeGray-text'
							}`}
							type='button'
							color='white'
							onClick={() => {
								setDropdownVisible(!dropdownVisible);
							}}
						>
							{getLabel(value) || placeHolder || 'Select'}
							{value ? (
								<button
									type='button'
									className='h-3 w-4 flex justify-end'
									onClick={(e) => {
										onChange(null);
										e.stopPropagation();
									}}
								>
									<CloseIcon className='size-2.5 stroke-[#344054] cursor-pointer' />
								</button>
							) : (
								<DownArrowIcon className='size-2.5 stroke-[#344054]' />
							)}
						</button>

						<div
							id='dropdown'
							className={`absolute cursor-pointer z-10 w-full divide-y divide-gray-100 rounded-lg bg-white shadow  dark:bg-gray-700 ${
								dropdownVisible ? 'block' : 'hidden'
							}`}
							style={
								menuPosition === 'top'
									? {
											inset: 'auto auto 0px 0px',
											margin: '0px',
											transform: 'translate(0px,-54px)'
									  }
									: {}
							}
						>
							<ul
								className={`text-sm-responsive text-gray-700 dark:text-gray-200 max-h-[300px] overflow-auto custom-scrollbar `}
								aria-labelledby='dropdownButton'
							>
								{options.length > 0 ? (
									options.map((option) => (
										<li
											key={option.value}
											onClick={() => {
												onChange(option.value);
												closeDropdown();
											}}
										>
											<div
												className={`block px-4 py-2 hover:bg-lightBlue1 dark:hover:bg-gray-600 dark:hover:text-white ${
													value === option.value ? 'bg-lightBlue1' : ''
												}`}
											>
												{option.label}
											</div>
										</li>
									))
								) : (
									<li className='px-4 py-2 text-gray-500'>No options found</li>
								)}
							</ul>
						</div>
					</>
				)}
			/>
			{errorMsg && <p className='text-left text-xs text-red-600 first-letter:uppercase'>{errorMsg}</p>}
		</div>
	);
};

export default Select;
