import { FC, useEffect, useState } from 'react';
import { Dropdown } from 'flowbite-react';
import { IDropdownOptions } from 'shared/interface';

interface IProps {
	options: IDropdownOptions[];
	className?: string;
	defaultValue: string;
	onChange: (selected: string) => void;
	contentClassName?: string;
}

const Dropdowns: FC<IProps> = (props) => {
	const { options, defaultValue, className, onChange, contentClassName } = props;
	const [selectedValue, setSelectedValue] = useState(defaultValue);

	useEffect(() => {
		if (defaultValue) {
			setSelectedValue(defaultValue);
		}
	}, [defaultValue]);

	const getLabel = (selectedValue: string) => {
		// Find the main option that matches the selected value
		const mainOption = options.find((option) => option.value === selectedValue);

		if (mainOption) {
			return mainOption.label;
		}
		return null;
	};

	return (
		<Dropdown
			style={{ boxShadow: 'none' }}
			label={<p className='text-sm-responsive'>{getLabel(selectedValue)}</p>}
			color='light'
			theme={{
				content: 'py-1 focus:outline-none',
				floating: {
					base: `z-20 w-fit divide-y divide-gray-100 rounded shadow focus:outline-none !border !border-primary ${
						contentClassName ? contentClassName : ''
					}`,
					item: {
						base: `flex w-full cursor-pointer items-center justify-start rounded-md px-4 py-2 text-sm-responsive text-textBlack hover:bg-lightBlue1`,
						container: 'flex w-full justify-between'
					},
					target: `${className} ${selectedValue === defaultValue && 'text-textBlack'} `
				}
			}}
		>
			{options.map(({ label, value }) => {
				return (
					<Dropdown.Item
						className={`${selectedValue === value && 'rounded-md bg-lightBlue1'} text-sm-responsive`}
						onClick={() => {
							onChange(value);
							setSelectedValue(value);
						}}
						key={value}
					>
						{label}
					</Dropdown.Item>
				);
			})}
		</Dropdown>
	);
};

export default Dropdowns;
