import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'flowbite-react';

import useModal from 'shared/hooks/useModal';
import ConfirmModal from 'shared/components/confirmModal';
import { FilterModal } from 'features/athleteDatabase/container';

import QuickAddForm from './form';

interface IProps {
	isOpenModal: boolean;
	handleClose: () => void;
	handleClickClose: () => void;
}

const QuickAdd: React.FC<IProps> = ({ isOpenModal, handleClose, handleClickClose }) => {
	const { isOpen, type, closeModal, openModal } = useModal<FilterModal>();
	const [isFormDirty, setFormDirty] = useState(false);

	const handleCloseWithCheck = () => {
		if (isFormDirty) {
			openModal(FilterModal.CONFIRM_POPUP); // Show confirmation modal if form is dirty
		} else {
			handleClickClose(); // Directly close if form is not dirty
		}
	};
	return (
		<div>
			<Modal show={isOpenModal} onClose={handleCloseWithCheck} theme={Theme} size='5xl'>
				<ModalHeader>
					<p className='text-lg font-semibold text-textBlack'>Quick Add Athlete</p>
				</ModalHeader>
				<ModalBody>
					<QuickAddForm handleClose={handleClose} onDirtyChange={setFormDirty} />
				</ModalBody>
			</Modal>

			{isOpen && type === FilterModal.CONFIRM_POPUP && (
				<ConfirmModal
					btnPrimaryTxt='Yes'
					btnSecondaryTxt='No'
					show={isOpen}
					onSubmit={handleClickClose}
					onClose={() => {
						closeModal();
					}}
					primaryText={'Unsaved changes !'}
					secondaryText={'You have unsaved changes. Are you sure you want to discard them ?'}
				/>
			)}
		</div>
	);
};

export default QuickAdd;

const Theme = {
	root: {
		show: {
			on: 'flex bg-[#0C111D] bg-opacity-50 backdrop-blur-sm dark:bg-opacity-80'
		}
	},
	body: {
		base: 'flex-1 p-4'
	},
	header: {
		base: 'flex items-start justify-between rounded-t border-b p-5 dark:border-gray-600',
		title: 'w-full'
	},
	footer: {
		base: 'border-none'
	},
	content: {
		base: 'relative h-full w-full p-4 md:h-auto',
		inner: 'relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700 overflow-y-scroll'
	}
};
