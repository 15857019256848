import { FC, MouseEvent, PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren {
	className?: string;
	onClick?: (event: MouseEvent<HTMLTableRowElement>) => void;
	disabled?: boolean;
}

const TableRow: FC<IProps> = (props) => {
	return (
		<tr
			className={`${props.className ?? ''} ${
				props.disabled ? 'pointer-events-none opacity-40' : 'bg-white dark:border-gray-700'
			} border-b border-themeGray hover:!bg-lightBlue`}
			onClick={props.onClick}
		>
			{props.children}
		</tr>
	);
};

export default TableRow;
