import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'store/userDetailStore';
import AccessManagement from 'features/admin/accessManagement/accessManagement';
import { AccessMangeIcon, LogoutIcon, SettingIcon } from 'shared/icon';
import { useNavigate } from 'react-router-dom';

interface IProps {
	isMenuOpen: boolean;
	closeMenu: () => void;
}

const Menu: FC<IProps> = ({ isMenuOpen, closeMenu }) => {
	const { t } = useTranslation('header');
	const { logout, userData } = useContext(AuthContext);
	const [openModal, setOpenModal] = useState(false);

	const navigate = useNavigate();

	const handleClick = (key: string) => {
		closeMenu();
		if (key === 'accessManagement') {
			setOpenModal(true);
		} else if (key === 'accountSetting') {
			navigate('/account-setting');
		} else if (key === 'logout') {
			logout();
		}
	};

	const MENU_LIST = [
		{
			icon: <SettingIcon className='mr-4' />,
			title: 'accountSetting',
			desc: 'accountSettingText',
			show: true
		},
		{
			icon: <AccessMangeIcon className='mr-4' />,
			title: 'accessManagement',
			desc: 'accessManagementText',
			show: userData.access_type === 'Level 1'
		},

		{
			icon: <LogoutIcon className='mr-4' />,
			title: 'logout',
			show: true
		}
	];

	return (
		<>
			<div
				className='z-50 my-4 w-[350px] list-none divide-y divide-gray-100 rounded-lg bg-white text-base shadow dark:bg-gray-700'
				id='user-dropdown-menu'
			>
				{isMenuOpen && (
					<ul className='py-2 font-medium' role='none'>
						{MENU_LIST.filter((data) => data.show).map((data, index) => (
							<li
								key={index}
								onClick={() => handleClick(data.title)}
								className='mx-3 block cursor-pointer px-4 py-2 hover:rounded-md hover:bg-lightBlue1 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white'
							>
								<div className='inline-flex items-center justify-center'>
									{data.icon}
									<div className='flex flex-col'>
										<p className='text-lg font-semibold text-textBlack'>{t(data.title)}</p>
										{data.desc && (
											<p className='text-sm font-normal text-textGray'>{t(data.desc)}</p>
										)}
									</div>
								</div>
							</li>
						))}
					</ul>
				)}
			</div>
			{openModal && <AccessManagement show={openModal} onClose={() => setOpenModal(false)} />}
		</>
	);
};

export default Menu;
