import React, { useCallback, useState } from 'react';
import { Control, FieldErrors, UseFormGetValues, UseFormSetValue, useWatch } from 'react-hook-form';
import { get } from 'lodash';

import { AthleteFormValues, playerModal } from 'features/addPlayer/interface';
import { NoteModal } from 'features/playerProfile/constant';

import Select from 'shared/form/select/select';
import { PlusIcon } from 'shared/icon';
import useModal from 'shared/hooks/useModal';
import AddNoteModal from 'shared/components/modal/addNoteModal';
import { TOURNAMENT_OPTIONS } from 'shared/constants';

interface IProps {
	control: Control<any>;
	errors: FieldErrors<AthleteFormValues>;
	setValue?: UseFormSetValue<any>;
	getValues: UseFormGetValues<any>;
}

export const enum Modal {
	ADDED_PLAYER = 'Added player',
	EDITOR_COMMENT = 'Editor Comment'
}

const Tournament: React.FC<IProps> = (props) => {
	const { control, errors, setValue, getValues } = props;

	const { isOpen, type, closeModal, openModal } = useModal<playerModal>();
	const [editorComment, setEditorComment] = useState('');

	const getErrorMsg = useCallback(
		(key: string) => {
			return get(errors, `${key}.message`, '') as string;
		},
		[errors]
	);
	const tournament = useWatch({
		control,
		name: 'player_information.tournament'
	});

	const handleAddCommentClick = () => {
		const currentComment = get(getValues(), 'player_information.tournament_note', '');
		setEditorComment(currentComment);
		openModal(playerModal.EDITOR_COMMENT);
	};

	return (
		<>
			<div className='flex items-center justify-between'>
				<p className='title-text'>Tournament</p>
			</div>
			<div className='mb-5 flex gap-3 items-start'>
				<Select
					placeHolder='Select Tournament'
					control={control}
					name='player_information.tournament'
					title='Tournament'
					className='max-w-[390px]'
					options={TOURNAMENT_OPTIONS}
					errorMsg={getErrorMsg('player_information.tournament')}
					required
				/>
				{tournament && (
					<div className='flex items-center cursor-pointer title-text' onClick={handleAddCommentClick}>
						<PlusIcon className='stroke-primary size-3 mr-2' />
						<p className='text-sm font-semibold text-primary'>Add Comment for Tournament</p>
					</div>
				)}
			</div>

			{isOpen && type === playerModal.EDITOR_COMMENT && (
				<AddNoteModal
					headerTile='Tournament note'
					subHeaderTitle={'Leave a comment for specific Tournament'}
					show={isOpen}
					onClose={() => {
						closeModal();
					}}
					isQuickNote
					initialData={{ note: editorComment }}
					formSubmit={(data: { note: string }) => {
						setEditorComment(data.note); // Update local state
						setValue && setValue('player_information.tournament_note', data.note);
						closeModal();
					}}
					type={NoteModal.addNote}
				/>
			)}
		</>
	);
};

export default Tournament;
