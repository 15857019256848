import { Spinner } from 'flowbite-react';
import moment from 'moment';
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import ProfilePic from 'shared/components/profilePic';
import { API_CONFIG } from 'shared/constants/api';
import { CloseIcon, NotificationDot } from 'shared/icon';
import HttpService from 'shared/services/http.service';
import { INotification } from '../interface/interface';
import { formatPostDate } from 'shared/util/utility';
import useModal from 'shared/hooks/useModal';
import InformationModal from 'shared/components/modal/informationModal';
import { NOTIFICATION_TYPE, TYPE } from '../constant';
import ConfirmRequestModal from './confirmRequestModal';

interface IProps {
	loading: boolean;
	notification: INotification[];
	handleCloseModal: () => void;
	getNotification: () => void;
}

const Notification: React.FC<IProps> = ({ handleCloseModal, getNotification, loading, notification }) => {
	const { isOpen, type, closeModal, openModal } = useModal<NOTIFICATION_TYPE>();
	const [showMoreContent, setShowMoreContent] = useState('');
	const [notificationData, setNotificationData] = useState<INotification>({} as INotification);
	const getAllReadNotification = async () => {
		try {
			const res = await HttpService.patch(`${API_CONFIG.notificationAllRead}`);
			if (res) {
				getNotification();
			}
		} catch (err) {
			console.error(err);
		}
	};

	//const getReadNotification = async (notification_id: string) => {
	//	try {
	//		const res = await HttpService.patch(`${API_CONFIG.notificationRead}/${notification_id}`);
	//		if (res) {
	//			getNotification();
	//		}
	//	} catch (err) {
	//		console.error(err);
	//	}
	//};

	const getClassName = (type: string, content: string, is_read: boolean) => {
		if (type === TYPE.STATUS_NOTIFICATION) {
			return content === 'Success!' ? 'text-[#1D9533]' : 'text-[#EC221F]';
		} else {
			return !is_read ? 'text-textBlack' : 'text-textGray';
		}
	};

	return (
		<div className='w-[375px] p-3 border rounded-xl bg-white absolute right-[250px] top-16 z-20 shadow-lg'>
			<div className='flex justify-between items-center'>
				<p className='text-lg font-semibold text-textBlack'>Notifications</p>
				<div className='flex items-center gap-1 cursor-pointer'>
					<p className='text-sm-responsive border-b-2 border-primary' onClick={getAllReadNotification}>
						Mark All As Read
					</p>
					<div onClick={handleCloseModal} className='h-[30px] w-[30px] flex justify-center items-center'>
						<CloseIcon className='cursor-pointer' />
					</div>
				</div>
			</div>

			<div className='mt-2 pt-2 scrollbar-none max-h-[calc(100vh-500px)] min-h-[150px] overflow-y-auto border-t'>
				{!loading &&
					notification &&
					notification.map((data: INotification) => {
						const isAccessRequest = data.type === TYPE.ACCESS_NOTIFICATION;
						const timeUTC = formatPostDate(moment(data.notification_sent_at).valueOf());
						const is_read = data.is_read;

						return (
							<div
								className={`relative flex items-start justify-between gap-[14px] mb-2 cursor-pointer ${
									is_read ? 'white' : 'bg-blue-50'
								} rounded-md p-2`}
								key={data.notification_id}
							>
								<ProfilePic
									profileUrl={''}
									className={`${!is_read ? 'bg-white' : ''} min-w-[54px] min-h-[54px]`}
								/>
								<div className='w-[265px]'>
									<p
										className={`${getClassName(
											data.type,
											data.content,
											is_read
										)} text-[13px] font-medium`}
									>
										{data.title || '-'}{' '}
										{isAccessRequest && (
											<>
												{data.is_accepted !== null ? (
													<b
														className={`${
															data.is_accepted ? 'text-[#1D9533]' : 'text-[#EC221F]'
														}`}
													>
														({data.is_accepted ? 'Accepted' : 'Rejected'})
													</b>
												) : (
													<b className='text-primary'>(Pending)</b>
												)}
											</>
										)}
									</p>
									<div className='flex justify-between'>
										<span className='text-[11px] font-medium text-themeGray-text'>{`${timeUTC}`}</span>
										{data.content && data.type !== TYPE.ACCESS_NOTIFICATION && !isAccessRequest && (
											<button
												className='text-[11px] font-medium text-primary'
												onClick={() => {
													openModal(NOTIFICATION_TYPE.VIEW_NOTIFICATION);
													setShowMoreContent(data.content);
												}}
											>
												Show More
											</button>
										)}
										{data.type === TYPE.ACCESS_NOTIFICATION && (
											<button
												className='text-[11px] font-medium text-primary'
												onClick={() => {
													openModal(NOTIFICATION_TYPE.CHANGE_REQUEST);
													setNotificationData(data);
												}}
											>
												View
											</button>
										)}
									</div>
								</div>
								{!is_read && <NotificationDot className='absolute right-1 top-1' fill='#0B50A1' />}
							</div>
						);
					})}

				{isEmpty(notification) && !loading && (
					<div className='flex justify-center items-center mt-10'>
						<p className='text-sm text-textGray font-medium'>No Notification Found</p>
					</div>
				)}

				{loading && (
					<div className='flex justify-center items-center h-full mt-10'>
						<Spinner aria-label='Center-aligned spinner' size={'lg'} />
					</div>
				)}

				{isOpen && type === NOTIFICATION_TYPE.VIEW_NOTIFICATION && (
					<InformationModal
						headerTile={'Notification Content'}
						size={'md'}
						show={isOpen}
						onClose={closeModal}
					>
						<p>{showMoreContent ?? 'No content available'}</p>
					</InformationModal>
				)}
				{isOpen && type === NOTIFICATION_TYPE.CHANGE_REQUEST && (
					<ConfirmRequestModal
						show={isOpen}
						onClose={closeModal}
						data={notificationData}
						getNotification={getNotification}
					/>
				)}
			</div>
		</div>
	);
};

export default Notification;
