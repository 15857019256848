import { Avatar } from 'flowbite-react';
import React from 'react';
import { ProfileIcon } from 'shared/icon';
import { avatarTheme } from '../header/constant';

interface IProps {
	profileUrl: string;
	playerName?: string;
	className?: string;
}

const ProfilePic: React.FC<IProps> = ({ profileUrl, playerName, className }) => {
	return (
		<div className='flex items-center justify-center'>
			{profileUrl ? (
				<Avatar
					className={`${className} size-10 min-w-[40px] min-h-[40px] rounded-full object-cover custom-profile`}
					img={profileUrl}
					theme={avatarTheme}
					alt='Rounded avatar'
					rounded
				/>
			) : (
				<div
					className={`${className} flex size-10 min-w-[40px] min-h-[40px] items-center justify-center object-cover rounded-full bg-lightBlue1`}
				>
					<ProfileIcon />
				</div>
			)}
			{playerName && (
				<p className='ml-2 text-sm-responsive font-medium text-textBlack capitalize w-ful w-[145px] text-left'>
					{playerName}
				</p>
			)}
		</div>
	);
};

export default ProfilePic;
