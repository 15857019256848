import { ChartOptions } from 'chart.js';

export const QUICk_NOTE_TABS = [
	{
		title: 'My Notes',
		key: 'users_quick_note_information'
	},
	{
		title: 'Team Yonex Notes',
		key: 'team_yonex_quick_note_information'
	}
];

export enum TAB_KEY {
	MY_NOTES = 'users_quick_note_information',
	TEAM_YONEX_NOTES = 'team_yonex_quick_note_information'
}
export const notesData = {
	myNotes: [
		{
			id: '1',
			createdBy: 'Matrin Cawde',
			note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum in vitae diam laoreet. Sed metus, tincidunt eget dui, ac pellentesque sit. Enim hendrerit commodo arcu sit eget amet sagittis porttitor. Erat quisque enim, gravida nulla dui nunc porta semper.',
			createdDate: '2024-08-22T00:00:00.000Z'
		},
		{
			id: '2',
			createdBy: 'Matrin Cawde',
			note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum in vitae diam laoreet. Sed metus, tincidunt eget dui, ac pellentesque sit. Enim hendrerit commodo arcu sit eget amet sagittis porttitor. Erat quisque enim, gravida nulla dui nunc porta semper.',
			createdDate: '2024-08-22T00:00:00.000Z'
		}
	],
	yonextNotes: [
		{
			id: '1',
			createdBy: 'Matrin Cawde',
			note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Bibendum in vitae diam laoreet. Sed metus, tincidunt eget dui, ac pellentesque sit. Enim hendrerit commodo arcu sit eget amet sagittis porttitor. Erat quisque enim, gravida nulla dui nunc porta semper.',
			createdDate: '2024-08-22T00:00:00.000Z'
		}
	]
};

export const enum NoteModal {
	addNote = 'added',
	editNote = 'updated',
	viewNote = 'View note',
	deleteNote = 'deleted',
	viewSocialMedia = 'Social Media'
}

export enum KpiGroup {
	Footwork = 'Footwork',
	Technical = 'Technical',
	Tactical = 'Tactical',
	Psychological = 'Psychological',
	Physical = 'Physical',
	'Daily Practice Or Lifestyle' = 'Daily / Lifecycle',
	'Performance Environment' = 'P. En.'
}

// Define your color arrays
export const colors = [
	'#0057B7',
	'#1D9533',
	'#ED3833',
	'#F3B743',
	'#655091',
	'#A44062',
	'#EE79C3',
	'#337E7E',
	'#7F7F7F',
	'#3A4B90'
];

export const options = {
	scales: {
		x: {
			ticks: {
				color: '#475767', // Set the color of the x-axis labels
				weight: '500'
			},
			font: {
				size: 12 // Adjust font size if necessary
			},
			title: {
				color: '#475767', // Set the title color
				font: {
					size: 16, // Set the font size
					weight: 'bold' as const // Set the font weight
				}
			}
		},
		y: {
			beginAtZero: true,
			min: 5, // Set the minimum value to 5
			max: 20, // Set the maximum value to 20
			title: {
				display: true, // Show the y-axis title
				color: '#475767',
				font: {
					size: 16, // Set the font size
					weight: 'bold' as const // Set the font weight
				}
			},
			ticks: {
				stepSize: 1, // Increment by 1
				color: '#475467' // Set the color of the y-axis labels
			}
		}
	},
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			display: false,
			position: 'top' as const,
			labels: {
				filter: (item: { text: string }) => item.text !== 'undefined' // Hide undefined labels in legend
			}
		}
	}
} as unknown as ChartOptions<'line'>;
