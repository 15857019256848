import React from 'react';

import EmptyDataImg from 'assets/images/empty-data.png';

/**
 * EmptyTable - empty table data text container component
 */
const EmptyData: React.FC<{ text?: string; className?: string }> = (props) => (
	<div className={`flex flex-col items-center justify-center text-center text-themeGray-text ${props.className}`}>
		<img src={EmptyDataImg} alt='no-data' />
		<div className='text-center text-textGray'>
			<p className='text-lg font-semibold'>Ooops, Nothing Here Yet!</p>
		</div>
	</div>
);

export default EmptyData;
