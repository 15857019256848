import React from 'react';

import { FemaleIcon, MaleIcon } from 'shared/icon';

interface IProps {
	data: string;
	className?: string;
}

const Gender: React.FC<IProps> = ({ data, className }) => {
	return (
		<>
			{data === 'Male' ? (
				<div className={className ?? ''}>
					<MaleIcon />
				</div>
			) : (
				<div className={className ?? ''}>
					<FemaleIcon />
				</div>
			)}
		</>
	);
};

export default Gender;
