import { Suspense, useContext } from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';

import {
	AthleteDatabase,
	PersonalInfo,
	AddPlayer,
	Login,
	ForgotPassword,
	ResetPassword,
	Verification,
	Shortlist,
	ShortListDetails,
	Dashboard,
	DashboardDetails,
	ProfileSetting,
	ContractManagement,
	Tournament,
	Team,
	Individual,
	Home,
	TournamentViewDetails,
	TeamViewDetails,
	IndividualViewDetails,
	AllAthletes
} from 'hoc/asyncComponents';
import Layout from 'hoc/layout';

import { AuthContext } from 'store/userDetailStore';
import Loader from 'shared/components/spinner/spinner';
import AthleteStore from 'store/athleteStore';
import ProtectedRoute from 'shared/components/permissions/route';
import { ROLES } from 'shared/components/permissions/constants';
import AuthHoc from 'hoc/authHoc';

const App = () => {
	const { isLogin } = useContext(AuthContext);

	if (isLogin) {
		return (
			<AthleteStore>
				<Layout>
					<Suspense fallback={<Loader />}>
						<Routes>
							<Route path='' element={<AthleteWrapper />}>
								<Route path='/athlete' element={<AthleteDatabase />} />
								<Route path='/add-player' element={<AddPlayer />} />
								<Route path='/add-player/:player_Id' element={<AddPlayer />} />
								<Route path='/' element={<Navigate to='/athlete' />} />
							</Route>

							<Route path='' element={<ScoutingAndRecruitingWrapper />}>
								<Route path='/all-athletes' element={<AllAthletes />} />
								<Route path='/shortlist' element={<Shortlist />} />
								<Route path='/shortlist/:shortlistId' element={<ShortListDetails />} />
								<Route path='/recruiting-dashboard' element={<Dashboard />} />
								<Route path='/recruiting-dashboard/:boardId' element={<DashboardDetails />} />
								<Route path='/' element={<Navigate to='/all-athletes' />} />
							</Route>

							<Route path='/player-profile/:playerID' element={<PersonalInfo />} />

							<Route path='/dashboard' element={<Home />} />

							<Route path='/contract-management' element={<ContractManagement />} />

							<Route path='/contract/tournament' element={<Tournament />} />
							<Route path='/contract/tournament/:contract_id' element={<Tournament />} />

							<Route path='/contract/team' element={<Team />} />
							<Route path='/contract/team/:team_contract_id' element={<Team />} />

							<Route path='/contract/individual' element={<Individual />} />
							<Route path='/contract/individual/:individual_contract_id' element={<Individual />} />

							<Route
								path='/tournament/contract-details/:contract_id'
								element={<TournamentViewDetails />}
							/>
							<Route path='/team/contract-details/:team_contract_id' element={<TeamViewDetails />} />
							<Route
								path='/individual/contract-details/:individual_contract_id'
								element={<IndividualViewDetails />}
							/>

							<Route path='/account-setting' element={<ProfileSetting />} />

							<Route path='/' element={<Navigate to='/athlete' />} />
							<Route path='*' element={<Navigate to='/athlete' />} />
						</Routes>
					</Suspense>
				</Layout>
			</AthleteStore>
		);
	} else {
		return (
			<Suspense fallback={<Loader />}>
				<Routes>
					<Route element={<AuthHoc />}>
						<Route path='/login' element={<Login />} />
						<Route path='/forgot-password' element={<ForgotPassword />} />
						<Route path='/reset-password' element={<ResetPassword />} />

						<Route path='/verification' element={<Verification />} />
						<Route path='/2FA' element={<Verification />} />
						<Route path='/create-account' element={<Verification />} />
						<Route path='/back-to-login' element={<Verification />} />
					</Route>

					<Route path='*' element={<Navigate replace to='/login' />} />
				</Routes>
			</Suspense>
		);
	}
};

const AthleteWrapper = () => {
	return (
		<ProtectedRoute accessRoles={[ROLES.ATHLETE_MANAGEMENT_DATABASE]} redirectPath='/dashboard'>
			<Outlet />
		</ProtectedRoute>
	);
};

const ScoutingAndRecruitingWrapper = () => {
	return (
		<ProtectedRoute accessRoles={[ROLES.SCOUTING_RECRUITING]} redirectPath='/all-athletes'>
			<Outlet />
		</ProtectedRoute>
	);
};

export default App;
