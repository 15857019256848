import { ATHLETES_OPTIONS } from 'features/athleteDatabase/constant';
import { SHORTLISTfILTER } from '../constant';
import { QUICK_FILTER } from 'features/allAthlates/component/filter/quickFilter';

export enum PLAYER_FILTERS {
	SPORTS = 'sports',
	ATHLETE = 'athlete_type',
	IS_PINNED = 'is_pinned',
	CURRENT_PAGE = 'current_page',
	RECORD_PER_PAGE = 'record_per_page',
	ORDER_BY = 'order_by',
	SORT_BY = 'sort_by',
	SEARCH = 'name'
}

export enum SHORTLIST_FILTERS {
	CURRENT_PAGE = 'current_page',
	RECORD_PER_PAGE = 'record_per_page',
	IS_PINNED = 'is_pinned',
	SHORTLIST_TYPE = 'shortlist_type',
	SHORTLIST_NAME = 'shortlist_name',
	ORDER_BY = 'order_by',
	SORT_BY = 'sort_by'
}

export enum SHORTLIST_PLAYER_FILTERS {
	CURRENT_PAGE = 'current_page',
	RECORD_PER_PAGE = 'record_per_page',
	SPORTS = 'sports',
	ATHLETE_TYPE = 'athlete_type',
	ORDER_BY = 'order_by',
	SORT_BY = 'sort_by',
	KPI = 'kpis'
}

export const initScoutingPlayerFilters = {
	[SHORTLIST_PLAYER_FILTERS.SPORTS]: 'Badminton',
	[SHORTLIST_PLAYER_FILTERS.ATHLETE_TYPE]: ATHLETES_OPTIONS[2]?.value || '',
	[SHORTLIST_PLAYER_FILTERS.SORT_BY]: '',
	[SHORTLIST_PLAYER_FILTERS.ORDER_BY]: '',
	[SHORTLIST_PLAYER_FILTERS.CURRENT_PAGE]: 1,
	[SHORTLIST_PLAYER_FILTERS.RECORD_PER_PAGE]: 10,
	[SHORTLIST_PLAYER_FILTERS.KPI]: ''
};

export type IScoutingPlayerFilters = {
	[key in SHORTLIST_PLAYER_FILTERS | QUICK_FILTER]: string | number | any[] | boolean;
};

export const initPLAYER_FILTERS = {
	[PLAYER_FILTERS.SPORTS]: 'Badminton',
	[PLAYER_FILTERS.IS_PINNED]: false,
	[PLAYER_FILTERS.ATHLETE]: ATHLETES_OPTIONS[2]?.value || '',
	[PLAYER_FILTERS.SORT_BY]: '',
	[PLAYER_FILTERS.ORDER_BY]: '',
	[PLAYER_FILTERS.CURRENT_PAGE]: 1,
	[PLAYER_FILTERS.RECORD_PER_PAGE]: 10
};

export const initShortListFilter = {
	[SHORTLIST_FILTERS.IS_PINNED]: false,
	[SHORTLIST_FILTERS.SHORTLIST_TYPE]: SHORTLISTfILTER[0]?.value || '',
	[SHORTLIST_FILTERS.SHORTLIST_NAME]: '',
	[SHORTLIST_FILTERS.SORT_BY]: '',
	[SHORTLIST_FILTERS.ORDER_BY]: '',
	[SHORTLIST_FILTERS.CURRENT_PAGE]: 1,
	[SHORTLIST_FILTERS.RECORD_PER_PAGE]: 12
};

export type IScoutingFilters = {
	[key in SHORTLIST_FILTERS]: string | number | boolean;
};

export interface IShortlists {
	id: string;
	name: string;
	created_at: string;
	updated_at: string;
	deleted_at: string;
	department: string;
	department_group: string;
	sports: string;
	theme: string;
	color_code: string;
	is_pinned: boolean;
	is_active: boolean;
	shortlist_creator: IShortlistCreator;
	shortlist_editors: IShortlistEditor[];
	total_player_count: number;
	ops: number;
	label: string;
}

export interface IShortlistCreator {
	id: string;
	name: string;
	last_name: string;
}

export interface IShortlistEditor {
	last_name: any;
	id: string;
	name: string;
	surname: string;
	profile_picture: string;
}

export interface ISelectPlayer {
	id: string;
	profile_picture: any;
	first_name: string;
	last_name: string;
	gender: string;
	status: string;
	circuit_level: string;
	potential_grade?: string;
	country: ICountry;
	creator: ICreator;
	performance: IPerformance;
	sports: string;
	athlete_id: number;
	is_pinned: boolean;
	editors: any[];
	is_shortlisted?: number;
}

export interface ICountry {
	id: number;
	name: string;
	emoji: string;
	iso3: string;
}

export interface ICreator {
	id: string;
	name: string;
	last_name: string;
}

export interface IPerformance {
	current: string;
	previous: string;
	difference: string;
}
