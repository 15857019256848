import React, { useEffect, useMemo, useState } from 'react';
import { Modal, ModalFooter, Spinner } from 'flowbite-react';
import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import ButtonPrimary from 'shared/components/buttons/primary';
import ButtonSecondary from 'shared/components/buttons/secondary';
import DateInput from 'shared/form/datepicker';
import Select from 'shared/form/select/select';
import { Textarea } from 'shared/form/textarea';
import { Mp4Icon } from 'shared/icon';
import { getVideoName, VideoSize } from 'shared/util/utility';
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import { IUserScout } from 'features/addPlayer/interface';
import { VideoModal } from '../../constant';

interface IProps {
	isOpen: boolean;
	closeModal: () => void;
	control: Control<any>;
	register: UseFormRegister<any>;
	setValue: UseFormSetValue<any>;
	handleSubmit: () => void;
	fields: any;
	uploadProgress: number | any;
	isUpload: boolean;
	currentVideoIndex?: number | undefined;
	handleCancelClick?: (Id: string) => void | undefined;
	type?: string;
}

const VideoUploadModal: React.FC<IProps> = ({
	isOpen,
	closeModal,
	control,
	register,
	setValue,
	handleSubmit,
	fields,
	uploadProgress,
	isUpload,
	currentVideoIndex,
	handleCancelClick,
	type
}) => {
	const currentIndex = useMemo(() => currentVideoIndex ?? fields.length - 1, [fields, currentVideoIndex]);
	const fileName = getVideoName(fields[0]?.video_url);
	const [scoutOptions, setScoutOptions] = useState<{ label: string; value: string }[]>([]);

	const getScoutDetails = async () => {
		try {
			const res = await HttpService.get(`${API_CONFIG.userList}?internal_department=Scout`);

			const labelValuePairs: { label: string; value: string }[] = [];

			res.data.forEach((item: IUserScout) => {
				labelValuePairs.push({ label: `${item.name} ${item.last_name}`, value: item.id });
			});

			setScoutOptions(labelValuePairs);
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		getScoutDetails();
	}, []);

	return (
		<Modal show={isOpen} onClose={closeModal} theme={Theme} size='md'>
			<Modal.Header className='rounded-t-lg border-b-2 bg-lightBlue w-full'>
				<div className='flex'>
					<div>
						<Mp4Icon />
					</div>
					<div className='ml-2.5 w-full'>
						<p
							title={`${fields[currentIndex]?.file?.name}` || fileName}
							className='truncate w-full max-w-80 text-sm font-medium text-[#344054]'
						>
							{fields[currentIndex]?.file?.name || fileName}
						</p>
						<p className='text-sm font-medium text-textGray'>
							{VideoSize(fields[currentIndex]?.file?.size || fields[currentIndex]?.video_size)}
						</p>

						<div className='flex items-center mt-2.5'>
							<div className='w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-600 mr-2 '>
								<div
									className='bg-[#19B454] h-2.5 rounded-full w-2/4'
									style={{ width: `${uploadProgress}%` }}
								></div>
							</div>
							<p className='text-sm font-medium text-[#344054]'>{`${uploadProgress}%`}</p>
						</div>
					</div>
				</div>
			</Modal.Header>
			<Modal.Body>
				{isUpload && (
					<>
						<p className='font-semibold text-base text-textBlack'>Tag video</p>
						<div className='mt-6 flex flex-col gap-2.5'>
							<Select
								placeHolder='Type tournament name'
								control={control}
								name={`player_videos.${currentIndex}.tournament_name`}
								title='Tournament'
								options={TOURNAMENT_OPTIONS}
								errorMsg={''}
							/>
							<DateInput
								setValue={setValue}
								name={`player_videos.${currentIndex}.creation_date`}
								title={'Creation date'}
								control={control}
							/>
							<Select
								placeHolder='Select from user list'
								control={control}
								name={`player_videos.${fields.length - 1}.scout_id`}
								title='Name of scout'
								options={scoutOptions}
								errorMsg={''}
							/>
							<Textarea
								name={`player_videos.${currentIndex}.comment`}
								title={'Comment'}
								errorMsg={''}
								placeholder={'Add a comment'}
								register={register}
								cols={30}
								rows={3}
							/>
						</div>
					</>
				)}

				{!isUpload && (
					<>
						<div className='flex flex-wrap items-center gap-2 justify-center'>
							<Spinner aria-label='Extra small spinner example' size='lg' />
						</div>
					</>
				)}
			</Modal.Body>
			<ModalFooter>
				<div className='flex justify-center w-full mb-4'>
					<ButtonSecondary
						btnTxt={'Cancel'}
						onClick={() => {
							closeModal();
							handleCancelClick && handleCancelClick(fields[currentIndex].id as string);
						}}
						className={'w-[43%]'}
					/>
					<ButtonPrimary
						btnTxt={type === VideoModal.editVideo ? 'Edit' : 'Add'}
						className='ml-3 w-[43%]'
						onClick={handleSubmit}
						disable={!isUpload}
					/>
				</div>
			</ModalFooter>
		</Modal>
	);
};

export default VideoUploadModal;

const Theme = {
	root: {
		show: {
			on: 'flex bg-[#0C111D] bg-opacity-50 backdrop-blur-sm dark:bg-opacity-80'
		}
	},
	body: {
		base: 'flex-1 p-6'
	},
	header: {
		title: 'w-full',
		close: {
			base: 'none',
			icon: 'h-0 w-0'
		}
	},
	footer: {
		base: 'border-none'
	}
};

const TOURNAMENT_OPTIONS = [
	{
		label: 'Tournament 1',
		value: 'Tournament 1'
	},
	{
		label: 'Tournament 2',
		value: 'Tournament 2'
	},
	{
		label: 'Tournament 3',
		value: 'Tournament 3'
	}
];
