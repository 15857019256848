import React from 'react';

import TableRow from './tableRow';
import TableCell from './tableCell';
import Loader from '../spinner/spinner';

interface IProps {
	colSpan: number;
}
const TableSpinner: React.FC<IProps> = ({ colSpan }) => (
	<TableRow>
		<TableCell colSpan={colSpan}>
			<Loader height={350} />
		</TableCell>
	</TableRow>
);

export default TableSpinner;
