import { QUICK_FILTER } from '../component/filter/quickFilter';
import { FILTERS } from '../component/filter/filter';

export const PINED_FILTER = [
	{ label: 'View All', value: 'view_all' },
	{ label: 'Only Pinned', value: 'only_pinned' }
];

export const SHORTLISTfILTER = [
	{ label: 'My Shortlists', value: 'My Shortlists' },
	{ label: 'Yonex Shortlist', value: 'Yonex Shortlist' }
];

export type IAllAthleteFilters = {
	[key in FILTERS | QUICK_FILTER]: string | number | any[] | boolean;
};

export type IQuickFilters = {
	[key in QUICK_FILTER]: string | number | any[] | boolean;
};

export enum AllAthleteModal {
	ADD_TO_SHORTLIST = 'Add To Shortlist',
	CONFIRM_ADD_TO_SHORTLIST = 'Confirm Add To Shortlist',
	CREATE_TO_SHORTLIST = 'Create Shortlist',
	ADD_QUICK_NOTE = 'added',
	ADD_TO_DASHBOARD = 'Add To Dashboard',
	CONFIRM_ADD_TO_BOARD = 'Confirm Add To Board',
	CREATE_TO_BOARD = 'Create Board'
}

export enum ScreenType {
	SELECTION_ATHLETE = 'Athlete Selection',
	ALL_ATHLETE_LIST = 'Athlete List'
}
