import { lazy } from 'react';

export const AthleteDatabase = lazy(() => import('features/athleteDatabase/container'));
export const PersonalInfo = lazy(() => import('features/playerProfile/container'));
export const AddPlayer = lazy(() => import('features/addPlayer/container'));

export const Login = lazy(() => import('auth/container/login'));
export const ForgotPassword = lazy(() => import('auth/container/forgotPassword'));
export const ResetPassword = lazy(() => import('auth/container/resetPassword'));
export const Verification = lazy(() => import('auth/container/verification'));

export const CreateAccount = lazy(() => import('auth/container/createAccount'));
export const Shortlist = lazy(() => import('features/shortlist/container'));
export const ShortListDetails = lazy(() => import('features/shortlistDetails/container'));
export const Dashboard = lazy(() => import('features/recruitingDashboard/container'));
export const DashboardDetails = lazy(() => import('features/dashboardDetails/container'));
export const ProfileSetting = lazy(() => import('features/setting/container'));
export const AllAthletes = lazy(() => import('features/allAthlates/container'));

export const ContractManagement = lazy(() => import('features/contractManagement/container/index'));
export const Tournament = lazy(() => import('features/contractManagement/component/tournaments/tournament'));
export const Team = lazy(() => import('features/contractManagement/component/team/team'));
export const Individual = lazy(() => import('features/contractManagement/component/individual/individual'));
export const TournamentViewDetails = lazy(
	() => import('features/contractManagement/component/contractDetails/tournamentViewDetails')
);
export const TeamViewDetails = lazy(
	() => import('features/contractManagement/component/contractDetails/teamViewDetails')
);
export const IndividualViewDetails = lazy(
	() => import('features/contractManagement/component/contractDetails/individualViewDetails')
);

export const Home = lazy(() => import('features/home/home'));
