export enum LEVELS {
	Level1 = 'Level 1',
	Level2 = 'Level 2',
	Level3 = 'Level 3',
	Level4 = 'Level 4'
}

export const PERMISSIONS = {
	[LEVELS.Level1]: {
		view: true,
		add: true,
		edit: true,
		delete: true,
		viewHistory: true,
		changeTeam: true,
		disableProfile: true,
		share: true,
		commenting: true
	},
	[LEVELS.Level2]: {
		view: true,
		add: true,
		edit: true,
		delete: true,
		viewHistory: true,
		changeTeam: true,
		disableProfile: true,
		share: true,
		commenting: true
	},
	[LEVELS.Level3]: {
		view: true,
		add: false,
		edit: true,
		delete: true,
		viewHistory: true,
		changeTeam: false,
		disableProfile: false,
		share: true,
		commenting: true
	},
	[LEVELS.Level4]: {
		view: true,
		add: false,
		edit: false,
		delete: false,
		viewHistory: false,
		changeTeam: false,
		disableProfile: false,
		share: false,
		commenting: false
	}
};

export enum ROLES {
	ATHLETE_MANAGEMENT_DATABASE = 'Athlete Management & Database',
	SCOUTING_RECRUITING = 'Scouting & Recruiting',
	CONTRACT_MANAGEMENT = 'Contract Management'
}

export const MODULE_ACCESS = {
	ATHLETE_MANAGEMENT_DATABASE: ROLES.ATHLETE_MANAGEMENT_DATABASE,
	SCOUTING_RECRUITING: ROLES.SCOUTING_RECRUITING,
	CONTRACT_MANAGEMENT: ROLES.CONTRACT_MANAGEMENT
};
