const authEN = {
	welcomeText: 'Welcome back! Please enter your details.',
	rememberText: 'Remember for 30 days',
	forgotPassword: 'Forgot Password',
	signIn: 'Sign In',
	signInGoogle: 'Sign in with Google',
	forgotPasswordTitle: 'Forgot Password?',
	emailSendText: 'No worries, we’ll send you reset instructions.',
	sendEmail: 'Send Email',
	backToLogin: 'Back in to log in',
	emailPlaceholder: 'Enter your Email',
	resetPassword: 'Reset Password',
	newPasswordPlaceholder: 'Enter new password',
	confirmNewPasswordPlaceholder: 'Enter confirm new password',
	verificationText: 'For the security reason we using a two factor authentication.',
	emailVerificationText: 'We will send you authentication code for complete your account setup',
	codeVerificationText: 'Provide security code below.',
	verificationEmailPlaceholder: 'Enter email address',
	sendCode: 'Send Code',
	confirm: 'Confirm',
	createAccountText: 'This is a last step! Please create your password',
	passwordPlaceholder: 'Type your password',
	repeatPassword: 'Repeat Password',
	createAccount: 'Create Account',
	youAreIn: "You're In!",
	accountCreationSuccessMessage: 'Account Created Successfully',
	emailSendSuccessMessage: 'Email sent successfully',
	resetPasswordSuccessMessage: 'Password reset successfully'
};

export default authEN;
