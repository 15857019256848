import React, { useState } from 'react';
import { Control, useFieldArray, UseFormRegister, UseFormSetValue, useWatch } from 'react-hook-form';
import { FileInput, Label } from 'flowbite-react';
import axios from 'axios';
import moment from 'moment';
import { isEmpty } from 'lodash';
import VideoUploadModal from 'features/addPlayer/component/form/videoUploadModal';
import { API_CONFIG } from 'shared/constants/api';
import useModal from 'shared/hooks/useModal';
import { PlusIcon } from 'shared/icon';
import HttpService from 'shared/services/http.service';

import { VideoModal } from 'features/addPlayer/constant';
import { IPlayerVideos, IQuickAddValues } from '../../interface';
import QuickAddVideoCard from './videoCard';

interface IProps {
	control: Control<IQuickAddValues>;
	register: UseFormRegister<IQuickAddValues>;
	setValue: UseFormSetValue<IQuickAddValues>;
}

const QuickAddVideoUpload: React.FC<IProps> = ({ control, register, setValue }) => {
	const { isOpen, type, closeModal, openModal } = useModal();
	const [isUpload, setIsUpload] = useState(false);
	const [videoIndex, setVideoIndex] = useState<number | undefined>(undefined);
	const [videoInfo, setVideoInfo] = useState<IPlayerVideos | undefined>();

	const [uploadProgress, setUploadProgress] = useState<number>(0);

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'player_videos'
	});

	const playerVideos = useWatch({ control, name: 'player_videos' });
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			uploadVideoToS3(file);
		}
		event.target.value = '';
	};

	const getPresignedUrl = async (file: any) => {
		const params = {
			key: `player-videos/${Date.now()}-${file.name}`
		};
		try {
			const response = await HttpService.post(API_CONFIG.presignedUrl, params);
			append({
				file: file,
				tournament_name: '',
				comment: '',
				video_url: response.data.split('?')[0], // Update with actual video URL from server response
				creation_date: moment().format('YYYY-MM-DD'),
				scout_name: ''
			});
			openModal(VideoModal.addVideo);
			return response.data;
		} catch (error) {
			console.error('Error fetching presigned URL', error);
			throw error;
		}
	};

	const uploadVideoToS3 = async (file: File): Promise<void> => {
		const presignedUrl: string = await getPresignedUrl(file);
		try {
			const formData = new FormData();
			formData.append('file', file);

			// Upload the video using axios with a PUT request
			const response = await axios.put(presignedUrl, file, {
				headers: {
					'Content-Type': file.type // Ensure correct MIME type
				},
				onUploadProgress: (progressEvent: any) => {
					const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
					setUploadProgress(progress);
				}
			});

			if (response.status === 200) {
				setIsUpload(true);
				setUploadProgress(100);
			} else {
				console.error('Failed to upload video');
			}
		} catch (error) {
			console.error('Error uploading video:', error);
		}
	};

	const handleCancelClick = (id: string) => {
		const updatedVideos: any = fields.map((video) => (video.id === id ? videoInfo : video));
		setValue('player_videos', updatedVideos);
	};
	return (
		<>
			<div className='flex justify-between mt-5'>
				<p className='title-text'>Athlete videos</p>
				<div className='flex items-center justify-center cursor-pointer  rounded-lg '>
					<PlusIcon className='size-2.5 stroke-primary mr-2' />
					<Label
						htmlFor='file-upload'
						value='Import video'
						className='text-primary text-sm font-semibold cursor-pointer'
					></Label>
					<FileInput
						id='file-upload'
						className='hidden'
						accept='video/*'
						onChange={handleFileChange}
						name='video_information.video_url'
					/>
				</div>
			</div>
			<div className='flex w-full items-center justify-center'>
				{!isEmpty(fields) && (
					<div className='flex flex-wrap overflow-auto h-64 w-full cursor-pointer gap-4 rounded-lg '>
						<QuickAddVideoCard
							playerVideos={playerVideos}
							uploadProgress={100}
							remove={remove}
							setVideoIndex={setVideoIndex}
							setVideoInfo={setVideoInfo}
							openModal={openModal}
						/>
					</div>
				)}
			</div>
			{isOpen && type === VideoModal.addVideo && (
				<VideoUploadModal
					isOpen={isOpen}
					closeModal={() => {
						remove(fields.length - 1);
						setIsUpload(false);
						closeModal();
					}}
					control={control}
					register={register}
					setValue={setValue}
					fields={fields}
					handleSubmit={() => {
						setIsUpload(false);
						setUploadProgress(0);
						closeModal();
					}}
					uploadProgress={uploadProgress}
					isUpload={isUpload}
				/>
			)}

			{isOpen && type === VideoModal.editVideo && (
				<VideoUploadModal
					isOpen={isOpen}
					type={VideoModal.editVideo}
					closeModal={() => {
						setIsUpload(false);
						closeModal();
						setVideoIndex(undefined);
					}}
					handleCancelClick={handleCancelClick}
					currentVideoIndex={videoIndex}
					fields={fields}
					control={control}
					register={register}
					setValue={setValue}
					handleSubmit={() => {
						setIsUpload(false);
						setUploadProgress(0);
						closeModal();
						setVideoIndex(undefined);
					}}
					uploadProgress={100}
					isUpload={true}
				/>
			)}
		</>
	);
};

export default QuickAddVideoUpload;
