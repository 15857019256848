import React, { PropsWithChildren, ReactNode } from 'react';
import { Modal } from 'flowbite-react';

interface IProps extends PropsWithChildren {
	className?: string;
	headerTile: string;
	show: boolean;
	onClose: () => void;
	size?: any;
	subHederTitle?: string;
	headerTitleClassName?: string;
	icon?: ReactNode;
}

const InformationModal: React.FC<IProps> = ({
	show,
	className,
	onClose,
	headerTile,
	children,
	size,
	subHederTitle,
	headerTitleClassName,
	icon
}) => {
	return (
		<Modal className={`${className || ''}`} show={show} onClose={onClose} theme={Theme} size={size}>
			<div onClick={(e) => e.stopPropagation()}>
				<Modal.Header className='rounded-t-lg border-b-2 border-primary bg-lightBlue'>
					{icon && (
						<div className='w-12 h-12 rounded-lg border-borderGray border-2 flex justify-center items-center mb-4 shadow-[0_1px_2px_0px_rgba(16,24,40,0.05)]'>
							{icon}
						</div>
					)}
					<p className={`header-txt ${headerTitleClassName ?? ''}`}>{headerTile}</p>
					<p className='font-normal text-sm text-textGray'>{subHederTitle}</p>
				</Modal.Header>
				<Modal.Body>{children}</Modal.Body>
			</div>
		</Modal>
	);
};

const Theme = {
	root: {
		show: {
			on: 'flex bg-[#0C111D] bg-opacity-50 backdrop-blur-sm dark:bg-opacity-80'
		}
	},
	body: {
		base: 'flex-1 p-4 overflow-auto custom-scrollbar'
	}
};

export default InformationModal;
