import React from 'react';

interface IProps {
	tabs: any;
	setActiveTab: (value: string) => void;
	activeTab: string;
}

const AccessManageTab: React.FC<IProps> = ({ tabs, setActiveTab, activeTab }) => {
	return (
		<ul className='flex h-11 w-full items-center rounded-lg border border-themeGray bg-lightBlue1 text-center text-sm font-medium text-gray-500'>
			{tabs.map((tab: any) => {
				return (
					<li
						key={tab.key}
						className={`m-1 flex w-1/2 cursor-pointer items-center justify-center rounded-lg p-2 text-sm font-medium text-[#667085] first:ml-1 last:mr-1 hover:bg-white hover:text-black ${
							activeTab === tab.key && 'bg-white text-black'
						}`}
						onClick={() => {
							setActiveTab(tab.key);
						}}
					>
						{tab.title}
					</li>
				);
			})}
		</ul>
	);
};

export default AccessManageTab;
