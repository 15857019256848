import React, { useCallback, useEffect, useState } from 'react';
import { Control, FieldErrors, UseFormGetValues, UseFormSetValue, useWatch } from 'react-hook-form';
import { get } from 'lodash';

import { AthleteFormValues, IEditorResponse, playerModal } from 'features/addPlayer/interface';
import { NoteModal } from 'features/playerProfile/constant';

import { API_CONFIG } from 'shared/constants/api';
import DropdownSelect from 'shared/form/select/dropdownSelect';
import NestedSelect from 'shared/form/select/nestedSelect';
import Select from 'shared/form/select/select';
import HttpService from 'shared/services/http.service';
import { PlusIcon } from 'shared/icon';
import useModal from 'shared/hooks/useModal';
import AddNoteModal from 'shared/components/modal/addNoteModal';
import { dropdownOptions, SPORTS_OPTIONS } from 'shared/constants';

interface IProps {
	control: Control<any>;
	errors: FieldErrors<AthleteFormValues>;
	isEdit?: boolean;
	setValue?: UseFormSetValue<any>;
	getValues: UseFormGetValues<AthleteFormValues>;
}

export const enum Modal {
	ADDED_PLAYER = 'Added player',
	EDITOR_COMMENT = 'Editor Comment'
}

const ProfileEditor: React.FC<IProps> = (props) => {
	const { control, errors, isEdit, setValue, getValues } = props;

	const [editorDropdown, setEditorDropdown] = useState([]);
	const { isOpen, type, closeModal, openModal } = useModal<playerModal>();
	const [editorComment, setEditorComment] = useState('');

	const getErrorMsg = useCallback(
		(key: string) => {
			return get(errors, `${key}.message`, '') as string;
		},
		[errors]
	);
	const sports = useWatch({
		control,
		name: 'player_information.sports' // Watch this field
	});
	const department = useWatch({
		control,
		name: 'department' // Watch this field always
	});

	const Editor = useWatch({
		control,
		name: 'notify_editor_info.user_ids'
	});

	const [selectedMenu, selectedSubMenu] = !isEdit && department ? department.split('~') : [null, null];
	const fetchEditorList = async () => {
		try {
			const params = {
				sports: sports,
				department_group: selectedMenu,
				department: selectedSubMenu ?? null
			};
			const res = await HttpService.get(`${API_CONFIG.editorList}`, params);
			const editorOptions = res?.data.map((details: IEditorResponse) => ({
				label: `${details.name} ${details.last_name}`,
				value: details.id
			}));
			setEditorDropdown(editorOptions);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		sports && department && fetchEditorList();
	}, [sports, department]);

	const handleAddCommentClick = () => {
		const currentComment = get(getValues(), 'notify_editor_info.content', ''); // Get current comment value
		setEditorComment(currentComment); // Set the comment in the state
		openModal(playerModal.EDITOR_COMMENT); // Open modal
	};

	return (
		<>
			<div className='flex items-center justify-between'>
				<p className='title-text'>Profile Editor</p>
				{!isEdit && Editor && (
					<div className='flex items-center cursor-pointer title-text' onClick={handleAddCommentClick}>
						<PlusIcon className='stroke-primary size-3 mr-2' />
						<p className='text-sm font-semibold text-primary'>Add Comment for Editor</p>
					</div>
				)}
			</div>
			<div className='mb-5 flex gap-3 items-start'>
				<Select
					placeHolder='Select Sport Type'
					control={control}
					className='max-w-[390px]'
					name='player_information.sports'
					title='Sport'
					options={SPORTS_OPTIONS}
					errorMsg={getErrorMsg('player_information.sports')}
					required
				/>
				{!isEdit && (
					<>
						<NestedSelect
							menuPosition='bottom'
							placeHolder='Select Department'
							className='max-w-[390px]'
							control={control}
							name='department'
							title='Department'
							options={dropdownOptions}
							errorMsg={''}
							disabled={!sports}
							translate={'280px'}
						/>
						<DropdownSelect
							menuPosition='bottom'
							name='notify_editor_info.user_ids'
							title='Editor'
							options={editorDropdown}
							errorMsg={errors?.editor?.message ?? ''}
							control={control}
							placeHolder='Select Editor'
							className='max-w-[390px]'
							disabled={!department}
						/>
					</>
				)}
			</div>

			{isOpen && type === playerModal.EDITOR_COMMENT && (
				<AddNoteModal
					headerTile='Editor note'
					show={isOpen}
					onClose={() => {
						closeModal();
					}}
					isQuickNote
					initialData={{ note: editorComment }}
					formSubmit={(data: { note: string }) => {
						setEditorComment(data.note); // Update local state
						setValue && setValue('notify_editor_info.content', data.note);
						closeModal();
					}}
					type={NoteModal.addNote}
				/>
			)}
		</>
	);
};

export default ProfileEditor;
