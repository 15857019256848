import { FC, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';

import { ThemesOptions } from 'features/shortlist/constant';
import useFlowbite from 'shared/hooks/useFlowbite';
import { CheckIcon, CloseIcon, DownArrowIcon, StarIcon } from 'shared/icon';

interface IProps {
	name: string;
	title: string;
	className?: string;
	control: any;
	placeHolder?: string;
	disabled?: boolean;
	required?: boolean;
	isLabel?: boolean;
	errorMsg?: string;
}

const SelectTheme: FC<IProps> = ({
	name,
	title,
	className,
	control,
	placeHolder,
	disabled,
	required,
	isLabel = true,
	errorMsg
}) => {
	useFlowbite();
	const [dropdownVisible, setDropdownVisible] = useState(false);

	const dropdownRef = useRef<HTMLDivElement | null>(null);

	// Handle outside click
	const handleClickOutside = (event: any) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setDropdownVisible(false);
		}
	};

	useEffect(() => {
		// Add event listener to handle outside clicks
		document.addEventListener('click', handleClickOutside);

		// Cleanup event listener on component unmount
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	const closeDropdown = () => {
		setDropdownVisible(false);
	};

	return (
		<div ref={dropdownRef} className={`${className || ''} relative w-full`}>
			{isLabel && (
				<label htmlFor={name} className='input-label'>
					{title}
					{required && (
						<span className='text-xs'>
							<StarIcon height='7px' width='7px' />
						</span>
					)}
				</label>
			)}
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, value } }) => (
					<>
						<button
							disabled={disabled}
							id='dropdownButton'
							className={`input-select ring-none focus:outline-none focus:ring-4 focus:ring-blue-300 ${
								!value && 'text-themeGray-text'
							}`}
							type='button'
							color='white'
							onClick={() => {
								setDropdownVisible(!dropdownVisible);
							}}
						>
							{value || placeHolder || 'Select'}
							{value ? (
								<button
									type='button'
									className='h-3 w-4 flex justify-end'
									onClick={(e) => {
										onChange(null);
										e.stopPropagation();
									}}
								>
									<CloseIcon className='size-2.5 stroke-[#344054] cursor-pointer' />
								</button>
							) : (
								<DownArrowIcon className='size-2.5 stroke-[#344054]' />
							)}
						</button>

						<div
							id='dropdown'
							className={`absolute cursor-pointer z-10 w-[350px] divide-y divide-gray-100 rounded-lg bg-white shadow dark:bg-gray-700 ${
								dropdownVisible ? 'block' : 'hidden'
							}`}
						>
							<div className='flex p-2 items-center justify-between gap-3'>
								{ThemesOptions.map((theme) => (
									<div
										key={theme.themeName}
										className={`h-[83px] w-40 cursor-pointer border rounded-lg relative ${
											theme.className
										} ${value !== theme.themeName ? 'opacity-20' : ''}`}
										onClick={() => {
											onChange(theme.themeName);
											closeDropdown();
										}}
									>
										<div className='h-[30px] bg-primary rounded-lg' />
										{value === theme.themeName && (
											<CheckIcon
												className={`size-6 absolute left-[40%] ${theme.iconClassName}`}
											/>
										)}
									</div>
								))}
							</div>
						</div>
					</>
				)}
			/>
			{errorMsg && <p className='text-left text-xs text-red-600 first-letter:uppercase'>{errorMsg}</p>}
		</div>
	);
};

export default SelectTheme;
