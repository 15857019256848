import { FC, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { array, boolean, object, string } from 'yup';

import { useYupValidationResolver } from 'shared/hooks/useYupValidationResolver';

import YModal from 'shared/components/modal';
import { TAB_KEY, TABS } from 'shared/components/header/constant';
import AccessManageTab from 'shared/components/header/component/tabs';
import UserList from 'shared/components/header/component/userList';

import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/api';
import { IUser } from 'shared/components/header/interface/interface';
import ConfirmModal from 'shared/components/confirmModal';
import InviteForm from './inviteForm';

export type UserFormValues = {
	user_id?: string;
	name: string;
	last_name: string;
	email: string;
	phone_number: string | null;
	coworker_title: string | null;
	sports: boolean[];
	department: string;
	internal_department: string | null;
	access_type: string;
	modules: string[];
};

interface IProps {
	show: boolean;
	onClose: () => void;
}

const AccessManagement: FC<IProps> = ({ show, onClose }) => {
	const [activeTab, setActiveTab] = useState<string>(TAB_KEY.INVITE_TO_PLATFORM);
	const [loading, setLoading] = useState(false);
	const [userDetails, setUserDetails] = useState({} as UserFormValues);
	const [confirmPopup, setConfirmPopup] = useState(false);

	const isEdit = useMemo(() => !isEmpty(userDetails), [userDetails, activeTab]);

	const resolver = useYupValidationResolver(schema);

	const {
		register,
		handleSubmit,
		control,
		watch,
		reset,
		formState: { errors }
	} = useForm<UserFormValues>({
		resolver,
		defaultValues: initFormValue,
		mode: 'onChange'
	});

	useEffect(() => {
		reset(isEdit ? userDetails : initFormValue);
	}, [isEdit]);

	const getSports = (sports: boolean[]) => {
		if (sports[0] && sports[1]) {
			return 'Both';
		} else if (sports[0]) {
			return 'Tennis';
		} else if (sports[1]) {
			return 'Badminton';
		} else {
			return '';
		}
	};
	const getSportsFormData = (sports: string) => {
		if (sports === 'Tennis') {
			return [true, false];
		} else if (sports === 'Badminton') {
			return [false, true];
		} else {
			return [true, true];
		}
	};
	const onSubmit: SubmitHandler<UserFormValues> = async (data) => {
		setConfirmPopup(false);
		setLoading(true);
		const [selectedMenu, selectedSubMenu] = data.department.split('~');
		const params: any = {
			...data,
			department_group: selectedMenu,
			department: selectedSubMenu ?? null,
			modules: data.modules,
			sports: getSports(data.sports)
		};

		if (data.phone_number === '+81' || data.phone_number === '') {
			delete params.phone_number;
		}

		if (!params.coworker_title) {
			delete params.coworker_title;
		}

		try {
			if (isEdit) {
				delete params.user_id;
				await HttpService.put(`${API_CONFIG.userList}/${data.user_id}`, params);
				setLoading(false);
				closeEdit();
			} else {
				delete params.user_id;
				await HttpService.post(API_CONFIG.inviteUser, params);
				setLoading(false);
				onClose();
			}
		} catch (err) {
			setLoading(false);
			console.error(err);
		}
	};

	const closeEdit = () => {
		setUserDetails({} as UserFormValues);
	};

	const primaryBtnTxt = useMemo(() => {
		if (activeTab === 'inviteToThePlatform') {
			return 'Invite to the Platform';
		} else if (isEdit) {
			return 'Save Changes';
		} else {
			return '';
		}
	}, [activeTab, isEdit]);

	return (
		<>
			<YModal
				loading={loading}
				show={show}
				onClose={isEdit ? closeEdit : onClose}
				onSubmit={isEdit ? handleSubmit(() => setConfirmPopup(true)) : handleSubmit(onSubmit)}
				primaryBtnTxt={primaryBtnTxt}
				secondaryBtnTxt='Cancel'
				activeTab={activeTab}
			>
				<AccessManageTab
					tabs={TABS}
					setActiveTab={(tab) => {
						if (tab === TAB_KEY.INVITE_TO_PLATFORM) {
							closeEdit();
						}
						setActiveTab(tab);
					}}
					activeTab={activeTab}
				/>
				<div className='my-3 border' />
				{activeTab === 'inviteToThePlatform' || isEdit ? (
					<InviteForm isEdit={isEdit} register={register} control={control} watch={watch} errors={errors} />
				) : (
					<UserList
						setActiveTab={setActiveTab}
						onEditUser={(userData: IUser) => {
							const data: any = {
								...userData,
								department: userData.department
									? `${userData.department_group}~${userData.department}`
									: userData.department_group,
								sports: getSportsFormData(userData.sports),
								modules: userData.user_link_modules?.map((data: any) => data.module) || [],
								phone_number: userData.phone_number ?? '',
								user_id: userData.id,
								coworker_title: userData.coworker_title ?? ''
							};
							delete data.id;
							delete data.department_group;
							delete data.user_link_modules;
							setUserDetails(data);
						}}
					/>
				)}
			</YModal>
			<ConfirmModal
				btnPrimaryTxt='Yes'
				btnSecondaryTxt='No'
				primaryText='Save your changes'
				secondaryText={
					<>
						Are you sure you want to update
						<span className='font-semibold sub-header-txt ml-[2px]'>{userDetails.name}</span> profile?
					</>
				}
				show={confirmPopup}
				onClose={() => {
					setConfirmPopup(false);
				}}
				onSubmit={handleSubmit(onSubmit)}
			/>
		</>
	);
};

const schema = object({
	name: string().required().trim().max(20),
	email: string().email().required().trim().max(70),
	last_name: string().required().trim().max(20),
	sports: array()
		.of(boolean())
		.test('oneOfRequired', 'At least one sports is required', (value: any) => value.some((v: any) => !!v)),
	department: string().required(),
	internal_department: string().required('Internal department is a required'),
	access_type: string().required('Access type is a required'),
	modules: array().of(string()).min(1, 'At least one module is a required ').required('Module is a required field')
});

const initFormValue = {
	name: '',
	last_name: '',
	email: '',
	phone_number: '',
	coworker_title: null,
	sports: [false, false],
	department: '',
	internal_department: '',
	access_type: '',
	user_id: '',
	modules: ['Athlete Management & Database']
};

export default AccessManagement;
