import { Button } from 'flowbite-react';
import { FC, PropsWithChildren } from 'react';
import { BackIcon } from 'shared/icon';

interface IProps extends PropsWithChildren {
	className?: string;
	pagination: {
		current_page: number;
		total_pages?: number;
		record_per_page?: number;
	};
	loading: boolean;
	onPageChange: (page: number) => void;
}

const Pagination: FC<IProps> = ({ className, pagination, loading, onPageChange }) => {
	const { current_page, total_pages } = pagination;

	return (
		<div
			className={`flex min-h-10 w-full items-center justify-end rounded-b-lg border-x border-b px-4 py-1 ${
				className ?? ''
			}`}
		>
			<div className='flex items-center '>
				<Button
					onClick={() => onPageChange(current_page - 1)}
					size={'xs'}
					disabled={loading || current_page <= 1}
					className='ring-none mr-2'
					color={'blue'}
					theme={ButtonTheme}
				>
					<BackIcon width='15px' />
				</Button>
				<p className='text-[13px]'>
					Page {current_page} {total_pages ? `of ${total_pages}` : ''}
				</p>
				<Button
					onClick={() => onPageChange(current_page + 1)}
					size={'xs'}
					disabled={loading || current_page === total_pages || total_pages === 0}
					className='ring-none ml-2'
					color={'blue'}
					theme={ButtonTheme}
				>
					<BackIcon width='15px' className='rotate-180' />
				</Button>
			</div>
		</div>
	);
};

export default Pagination;

const ButtonTheme = {
	color: {
		blue: 'border border-primary bg-lightBlue1 text-white'
	}
};
